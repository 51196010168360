import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover } from "@material-ui/core";

import { Text } from "@paro.io/base-ui";
// import { Button as BaseUiButton } from "@paro.io/base-ui";
import { IconInfoCircle } from "@paro.io/base-icons";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },

  infoPopover: {
    maxWidth: "600px",
  },
}));

export default function InfoButton({ information }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <div
        onClick={handleClick}
        className="mx-1 px-1 cursor-pointer text-primary flex items-center"
      >
        <IconInfoCircle size="md" />
      </div>
      <Popover
        id="infoPopover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.infoPopover}>
          <Text className={classes.typography} size="sm">
            {information}
          </Text>
        </div>
      </Popover>
    </>
  );
}
