import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@material-ui/core";
import { formatDateAsMY } from "../utils/dateService";
import { ExpandMore } from "@material-ui/icons";
import AccordionHeader from "./AccordionHeader";
import { convertEnumToDisplayFormat } from "./legacy/shared/displayConversions";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    padding: theme.spacing(1),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(3),
    margin: theme.spacing(0, 0, 3, 0),
  },
}));

export default function EducationCard({
  education = [],
  widget,
  handleChange,
  dragIndicator,
  checkbox,
}) {
  const classes = useStyles();
  return (
    <Accordion className={`mt-3 mb-2 ${classes.accordionRoot}`} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />} aria-label="Expand">
        <AccordionHeader
          title="EDUCATION"
          widget={widget}
          handleChange={handleChange}
          dragIndicator={dragIndicator}
          checkbox={checkbox}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {education.map((w) => (
            <Paper className={classes.paper} p={3} key={w.id}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <h6>{w.schoolName}</h6>
                  <b className="mb-4">
                    {convertEnumToDisplayFormat(w.degree, true) ||
                      "Missing degree"}
                    , {w.major || "Missing major"}
                  </b>
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                  {`${formatDateAsMY(w.startDate)} - ${formatDateAsMY(
                    w.endDate
                  )}`}
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
