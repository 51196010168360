import React from "react";
import { Box, Typography } from "@material-ui/core";

import MissingItem from "../legacy/_global/MissingItem";
import ThreeColumnLayout from "../legacy/_global/ThreeColumnLayout";
import { areAllItemsNull } from "../legacy/shared/utils";
import { SectionBody } from "../legacy/styles/SectionBody";
// import { SectionTitle } from "../legacy/styles/SectionTitle";
import TopTags from "./TopTags";

/* type SectionContents = {
  firstName: string
  personalSummary: Partial<PersonalSummary>
}
 */
const Overview = (
  { firstName, personalSummary } /* : SectionContents */
) /* : JSX.Element */ => {
  const {
    aboutYourself,
    whyFreelanceWork,
    topIndustries,
    topFinancialSkills,
    topSoftwareSkills,
  } = personalSummary || {};

  const allItemsAreNull = areAllItemsNull([aboutYourself, whyFreelanceWork]);

  return (
    <>
      {allItemsAreNull ? (
        <MissingItem title={"overview"} />
      ) : (
        <SectionBody>
          <Box pb={2}>
            <Typography data-testid="overview" variant="h5">
              About {firstName}
            </Typography>
          </Box>
          <Box pb={3} data-testid="overview">
            <Typography
              data-testid="descriptionHtml"
              dangerouslySetInnerHTML={{
                __html:
                  aboutYourself || "Missing information. Please fill this in.",
              }}
            ></Typography>
          </Box>
          <Box pb={2}>
            <Typography data-testid="overview" variant="h5">
              Why I Freelance
            </Typography>
          </Box>
          <Typography
            data-testid="descriptionHtml"
            dangerouslySetInnerHTML={{
              __html:
                whyFreelanceWork || "Missing information. Please fill this in.",
            }}
          ></Typography>
          <Box pt={3} pb={2}>
            <Typography data-testid="overview" variant="h5">
              Top Tags
            </Typography>
          </Box>
          <ThreeColumnLayout>
            <TopTags tags={topIndustries || []} tagTitle="Top industries" />
            <TopTags tags={topFinancialSkills || []} tagTitle="Top skills" />
            <TopTags tags={topSoftwareSkills || []} tagTitle="Top software" />
          </ThreeColumnLayout>
        </SectionBody>
      )}
    </>
  );
};

export default Overview;
