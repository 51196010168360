import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import TopSection from "../components/TopSection";
import CustomSpinner from "../components/CustomSpinner";

import ProposalFreelancersSection from "../components/ProposalFreelancersSection";
import MatchForm from "../components/MatchForm";
import { CartContext } from "../components/CartContext";
import {
  getFLFullProfileViews,
  getProposal,
  getProposalFreelancers,
  getProposalMatchCount,
  updateProposalMatchCount,
  addToCartFn,
  getCartDetails,
} from "../services/apiService";
import { Box } from "@material-ui/core";
import DataNotFound from "../components/DataNotFound";
import { useAuth0 } from "../contexts/Auth0Context";
import { getFreelancerDetailsFn } from "../utils/platformService";
import FreelancerCards from "../components/FreelancerCards";
import {
  MAX_REMATCHES,
  OPPORTUNITY_TYPES,
  ORIGINAL_OPPORTUNITY_TYPES,
} from "../data/appConstants";
import { getFlsFeedback } from "../utils/mmService";
import FreelancerScorePage from "../components/FreelancerScorePage";
import FreelancerComparePage from "./FreelancerCompare";

const StageOptionsMap = new Map([
  ["-", "0"],
  ["Proposed", "0"],
  ["Interested", "1"],
  ["ClientProposal", "2"],
  ["IntroEmail", "3"],
  ["SowSubmitted", "4"],
  ["SowAccepted", "5"],
  ["ClosedWon", "91"],
  ["ClientRejected", "92"],
  ["FlRejected", "93"],
]);

export default function ProposalViewer() {
  const {
    cartContext,
    proposalContext,
    matchContext,
    freelancerContext,
    flViewContext,
    iterationsContext,
    globalMatchCartContext,
    compareFlsContext,
  } = useContext(CartContext);

  const [cart, setCart] = cartContext;
  const [proposalData, setProposalData] = proposalContext;
  const [matchId, setMatchId] = matchContext;
  const [freelancers, setFreelancers] = freelancerContext;
  const [, setIterations] = iterationsContext;
  const [, setMatchIds] = globalMatchCartContext;
  const [, setCompareFls] = compareFlsContext;

  const { proposalId } = useParams();
  const { user } = useAuth0();

  const [, setTemplates] = flViewContext;

  const [initialLoad, setInitialLoad] = useState(true);
  const [loadingData0, setLoadingData0] = useState(true);
  const [loadingData1, setLoadingData1] = useState(true);
  const [matchFormData, setMatchFormData] = useState(null);
  const [platformError, setPlatformError] = useState(false);
  const [currentProposalObj, setCurrentProposalObj] = useState(
    proposalData.filter((p) => p.id === proposalId)
  );
  const [showCart, setShowCart] = useState(false);
  const [showFullProfile, setShowFullProfile] = useState(null);
  const [showScorecard, setShowScorecard] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [showCompare, setShowCompare] = useState(false);

  const history = useHistory();

  const handleFullProfile = (f, i) => {
    setTabIndex(i);
    setShowFullProfile(f);
  };
  const refetchProposal = useCallback(
    async (proposalId) => {
      const res = await getProposal(parseInt(proposalId));
      let data = res.data.data.getProposal;
      if (
        data.opportunityTypeMM &&
        ![OPPORTUNITY_TYPES.NewBusiness, OPPORTUNITY_TYPES.CrossSell, OPPORTUNITY_TYPES.WL].includes(
          data.opportunityTypeMM
        )
      ) {
        return history.push("/");
      }
      setCurrentProposalObj(data);
      const proposalFreelancers = await getProposalFreelancers(data.id);

      const curPf = proposalFreelancers.data.data.getProposalFreelancers;

      const updatedProposal = {
        ...data,
        proposalFreelancers: curPf.map((f) => ({
          freelancerId: f.freelancerId,
          stageUpdatedAt: f.proposedProjectStageUpdatedAt,
          stage: parseInt(StageOptionsMap.get(f.proposedProjectStage)),
        })),
      };

      const resMatchCount = await getProposalMatchCount(
        parseInt(proposalId),
        user.auth0UserId
      );

      setIterations(
        Math.min(
          resMatchCount.data.data.getProposalMatchCount?.matchCount || 0,
          MAX_REMATCHES + 1
        )
      );
      setProposalData((proposalData) => {
        if (!proposalData.length) {
          proposalData.push(updatedProposal);
          return proposalData;
        }
        return proposalData.map((p) => {
          if (p.id === updatedProposal.id) {
            return updatedProposal;
          }
          return p;
        });
      });
      setMatchFormData(() => {
        let matchFormData = updatedProposal;
        matchFormData = {
          ...matchFormData,
          opportunityTypeMM: !!matchFormData.opportunityTypeMM
            ? matchFormData.opportunityTypeMM
            : Object.values(ORIGINAL_OPPORTUNITY_TYPES).includes(
                matchFormData.opportunityType
              )
            ? matchFormData.opportunityType
            : OPPORTUNITY_TYPES.NewBusiness,
          clientRate: matchFormData.matchmakingOptions.rateRange.max,
          industries: matchFormData.matchmakingOptions.tags.filter(
            (tag) => tag.field === "industries"
          ),
          softwares: matchFormData.matchmakingOptions.tags.filter(
            (tag) => tag.field === "softwares"
          ),
          skills: matchFormData.matchmakingOptions.tags.filter(
            (tag) => tag.field === "skills"
          ),
        };
        return matchFormData;
      });

      setLoadingData0(false);
    },
    [history, setIterations, setProposalData, user.auth0UserId]
  );

  const refreshProposals = useCallback(async () => {
    if (freelancers.length === 0) {
      refetchProposal(proposalId);
    }
    // setLoadingData0(false);
  }, [freelancers.length, proposalId, refetchProposal]);

  useEffect(() => refreshProposals(), [refreshProposals]);

  const getTemplates = useCallback(async () => {
    const templates = await getFLFullProfileViews(user.auth0UserId);
    let templatesData = templates.data.data.getFLProfileSettings;
    templatesData.map((t) => (t.fields = JSON.parse(t.fields)));
    setTemplates(templatesData);
  }, [user.auth0UserId, setTemplates]);

  const getCartFreelancers = useCallback(async () => {
    try {
      if (!cart.length) {
        const res = await getCartDetails(
          parseInt(proposalId),
          user.auth0UserId
        );
        const cartDetails = res.data.data.getCartDetails;
        if (cartDetails) {
          const mIds = cartDetails.map((c) => c.matchId);
          setMatchIds(mIds);
          const propFlData = await Promise.all(
            cartDetails.map((d) => getFreelancerDetailsFn(d))
          );
          setCart(await getFlsFeedback(propFlData, mIds));
          setShowCart(true);
        }
      } else {
        setShowCart(true);
      }
    } catch (err) {
      setPlatformError(true);
    }
  }, [proposalId, user.auth0UserId, cart.length, setCart, setMatchIds]);

  useEffect(() => {
    getCartFreelancers();
    getTemplates();
  }, [getTemplates, getCartFreelancers]);

  const setFreelancerDetailsFn = async (data) => {
    try {
      setMatchId(data.matchId);
      setMatchIds((currentState) => [...currentState, data.matchId]);
      const propFlData = await getFreelancerDetailsFn(data);
      setFreelancers(propFlData);
      setCart((currentState) => [...currentState, ...propFlData]);
      setShowCart(true);

      const cartData = {
        proposalId: parseInt(proposalId),
        userId: user.auth0UserId,
        matchId: data.matchId,
        recommendedFreeLancers: propFlData.map((rf) => ({
          flID: rf.flID,
          matchPercent: rf.matchPercent,
          category: rf.category,
        })),
      };
      await addToCartFn(cartData); //persist to db
      if (propFlData.length > 0) {
        await updateProposalMatchCount(
          parseInt(proposalId),
          user.auth0UserId,
          "increment"
        );
        setIterations((iterations) => ++iterations);
      }
      setLoadingData1(false);
    } catch (err) {
      setPlatformError(true);
    }
  };

  const refreshCart = () => {
    //    setCart([]);
    setMatchId(null);
    setFreelancers([]);
    setCompareFls([]);
    setLoadingData1(true);
    setPlatformError(false);
  };

  useEffect(() => {
    refetchProposal(proposalId);
  }, [proposalId, refetchProposal]);

  if (showCompare) {
    return (
      <>
        <TopSection
          proposalId={proposalId}
          showFullProfile={true}
          goBack={() => {
            setShowCompare(false);
          }}
        />
        <FreelancerComparePage matchFormData={matchFormData} />
      </>
    );
  }

  return (
    <>
      <TopSection
        proposalId={proposalId}
        showFullProfile={showFullProfile || showScorecard}
        goBack={() => {
          setShowFullProfile(null);
          setShowScorecard(null);
        }}
        showCart={showCart}
      />
      <Box>
        {loadingData0 && (
          <CustomSpinner>Fetching Proposal Details</CustomSpinner>
        )}
        {/* {loadingData0 && !loadingData1 && (
          <CustomSpinner>Updating Proposal Details</CustomSpinner>
        )} */}
        {!loadingData0 &&
          !!matchFormData &&
          !showFullProfile &&
          !showScorecard && (
            <MatchForm
              // currentProposalObj={currentProposalObj}
              // setCurrentProposalObj={setCurrentProposalObj}
              defaultValues={matchFormData}
              loadMatchData={setFreelancerDetailsFn}
              setInitialLoad={setInitialLoad}
              setLoadingData0={setLoadingData0}
              loadingMatches={loadingData1 && !initialLoad}
              refreshCart={refreshCart}
              refetchProposal={refetchProposal}
            />
          )}
        {!initialLoad && !loadingData1 && !matchId && (
          <DataNotFound text="Matching App ran into issues" />
        )}
        {!initialLoad &&
          !loadingData1 &&
          matchId &&
          freelancers &&
          freelancers.length === 0 &&
          !platformError && <DataNotFound text="Oops... No Matches Found!!!" />}

        {!initialLoad && !loadingData1 && matchId && platformError && (
          <DataNotFound text="Platform ran into issues" />
        )}

        {freelancers &&
          !!freelancers.length &&
          matchFormData &&
          !showFullProfile &&
          !showScorecard && (
            <ProposalFreelancersSection
              proposal={matchFormData}
              freelancers={freelancers}
              showFullProfile={handleFullProfile}
              setShowScorecard={setShowScorecard}
              setShowCompare={setShowCompare}
            />
          )}
        {showFullProfile && (
          <FreelancerCards
            data={showFullProfile}
            proposalNotes={currentProposalObj.notes}
            proposalId={parseInt(proposalId)}
            tabIndex={tabIndex}
          />
        )}
        {showScorecard && (
          <FreelancerScorePage
            freelancer={showScorecard}
            proposal={currentProposalObj}
          />
        )}

        {!initialLoad && loadingData1 && (
          <CustomSpinner>Fetching Freelancer Matches</CustomSpinner>
        )}
      </Box>
    </>
  );
}
