export const REMATCH_REASONS = [
  "Freelancers were not a good fit for the proposal",
  "Very few freelancers were a good fit for the proposal",
  "Looking for a freelancer I know",
  "Want to see more candidates",
];

export const REMOVE_REASONS = [
  "Not a good fit, due to Industry fit",
  "Not a good fit, due to Experience fit",
  "Not a good fit, due to Software fit",
  "Not a good fit, due to Service line",
];

export const CHECKOUT_REASONS = [
  "Not a great fit",
  "Candidates too similiar",
  "Freelancer rate too high",
  "Not in the service line required",
];
export const REPORT_REASONS = [
  "Unresponsiveness",
  "Insufficient communication skills",
  "Lack of Professionalism",
];

export const MAX_REMATCHES = 2;

/* To add a new widget:
1. add key value here,
2. use value in small case and map to a Component
3. update the component in:
  i. FreelancerFullProfileView
  ii. FreelancerProfileViewCreate
  iii. FreelancerProfileViewUpdate */
export const NEW_WIDGETS = ["EDUCATION", "PROJECTS", "WORK AND PREFERENCES"];

export const IS_PROD_ENVIRONMENT = !["", "development"].includes(
  process.env.REACT_APP_ENVIRONMENT_STAGE
);

export const FL_STATUSES = {
  Available: "AVAILABLE",
  "All Approved": "ALL_APPROVED",
  // Candidates: "CANDIDATES",
};

export const FAF_GROUPS = {
  Industries: "industries",
  Softwares: "softwares",
  Skills: "skills",
  "Service Lines": "serviceLine",
  "Financial Tasks": "financialTasks",
  States: "state",
};

export const OPPORTUNITY_TYPES = {
  NewBusiness: "New Business",
  Upsell: "Upsell",
  CrossSell: "Cross Sell",
  Transition: "Transition",
  Urgent: "Urgent",
  Paro4CPA: "Paro4CPA",
  BuyOut: "Buy-out",
  WL: "WL",
};

export const ORIGINAL_OPPORTUNITY_TYPES = {
  NewBusiness: "New Business",
  Upsell: "Upsell",
  CrossSell: "Cross Sell",
  Transition: "Transition",
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const BUYOUT_FLID = () =>
  process.env.REACT_APP_ENVIRONMENT_STAGE === "dev" ? 36786 : 51898;

export const REACTIVATION_OPTIONS = [
  {
    groupName: "Expert/Freelancer Related",
    options: [
      "No Response From Freelancer",
      "Expert Rejected Proposal",
      "Need Additional Freelancers",
    ],
  },
  {
    groupName: "Client Related",
    options: ["No Response From Client", "Client Rejected Proposal"],
  },
  {
    groupName: "Proposal Enhancements",
    options: [
      "Add Additional Information to Proposal Card",
      "Update Industry Tags",
      "Update Spftware Tags",
      "Update Skills Tags",
      "Post Proposal to the Find Opportunity Board",
    ],
  },
  {
    groupName: "Others",
    options: [],
  },
];
export const SECONDARY_SERVICE_LINES = {
  CFO: ["FP&A", "CPA/Accounting Advisory"],
  "Full Charge Bookkeeping": ["Tax Preparation", "CPA/Accounting Advisory"],
  "FP&A": ["CFO", "CPA/Accounting Advisory"],
  "CPA/Accounting Advisory": ["Tax Preparation", "CFO"],
  "Tax Preparation": ["Full Charge Bookkeeping", "CPA/Accounting Advisory"],
};

export const MAX_COMPARE = 5;

export const COMPANY_SIZE_LIST = [
  "No Company Size",
  "1-10",
  "11-50",
  "51-250",
  "251-1K",
  "1K-5K",
  "5K-10K",
  "10K-50K",
  "50K-100K",
  "100K+",
];

export const INDUSTRY_LIST = [
  "",
  "Automotive",
  "Consumer Discretionary",
  "Consumer Goods",
  "Household Durables",
  "Leisure Products",
  "Textiles, Apparel & Luxury Goods",
  "Consumer Services",
  "Diversified Consumer Services",
  "Hotels, Restaurants & Leisure",
  "Education Services",
  "Family Services",
  "Specialized Consumer Services",
  "Media",
  "Distributors",
  "Retailing",
  "Specialty Retail",
  "Consumer Staples",
  "Food & Staples Retailing",
  "Beverages",
  "Food Products",
  "Tobacco",
  "Personal Products",
  "Gas Utilities",
  "Banks",
  "Diversified Financial Services",
  "Capital Markets",
  "Insurance",
  "Real Estate",
  "Health Care Equipment & Supplies",
  "Biotechnology",
  "Life Sciences Tools & Services",
  "Pharmaceuticals",
  "Aerospace & Defense",
  "Capital Goods",
  "Construction & Engineering",
  "Electrical Equipment",
  "Industrial Conglomerates",
  "Machinery",
  "Trading Companies & Distributors",
  "Commercial Services & Supplies",
  "Professional Services",
  "Industrials",
  "Air Freight & Logistics",
  "Airlines",
  "Marine",
  "Road & Rail",
  "Transportation",
  "Semiconductors & Semiconductor Equipment",
  "Internet Software & Services",
  "IT Services",
  "Software",
  "Communications Equipment",
  "Electronic Equipment, Instruments & Components",
  "Technology Hardware, Storage & Peripherals",
  "Building Materials",
  "Chemicals",
  "Containers & Packaging",
  "Metals & Mining",
  "Paper & Forest Products",
  "Diversified Telecommunication Services",
  "Wireless Telecommunication Services",
  "Renewable Electricity",
  "Electric Utilities",
  "Utilities",
];
