import { getFreelancersFeedback } from "../services/apiService";

export const getFlsFeedback = async (propFlData, mIds) => {
  const flsFeedbackRes = await getFreelancersFeedback(mIds);
  const flsFeedback = flsFeedbackRes.data.data.getFreelancersFeedback;
  let cartFls = [];
  for (let flsData of propFlData) {
    flsData.map((fld) => {
      fld.feedback = flsFeedback.find(
        (fl) => fl.freelancerId === fld.flID
      )?.feedback;
      cartFls.push(fld);
      return fld;
    });
  }
  return cartFls;
};
