import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    minWidth: "20vw",
  },
}));

export default function MultiSelectField({
  onChange,
  value = [],
  label,
  options = [],
  creatable,
  ...field
}) {
  const classes = useStyles();
  const filter = createFilterOptions(
    options.map((o) => ({ inputValue: o, title: o, required: true }))
  );
  const [selectedValues, setSelectedValues] = useState(
    value.map((v) => ({
      inputValue: v.value,
      title: v.value,
      required: v.required,
    }))
  );

  return (
    <Autocomplete
      {...field}
      value={selectedValues}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <div className="mx-1" key={index}>
            <Chip
              variant="outlined"
              label={option.title}
              {...getTagProps({ index })}
              className={option.required ? `font-bold` : `font-light`}
              onClick={() => {
                const temp = [...selectedValues];
                const index = temp.findIndex(
                  (obj) => obj.inputValue === option.inputValue
                );
                temp[index].required = !option.required;
                setSelectedValues(temp);
                onChange(temp);
              }}
            />
          </div>
        ))
      }
      multiple
      options={options
        .map((o) => ({
          inputValue: o,
          title: o,
          required: true,
        }))
        .filter(
          (s) =>
            !selectedValues.map((sv) => sv.inputValue).includes(s.inputValue)
        )}
      onChange={(_, newValue) => {
        setSelectedValues(newValue);
        onChange(
          newValue.map((v) => ({ value: v.inputValue, required: v.required }))
        );
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "" && creatable) {
          filtered.push({
            inputValue: params.inputValue,
            title: `${params.inputValue} (new)`,
            required: true,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={label}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option) => option.title}
      freeSolo
      filterSelectedOptions
      className={classes.container}
      renderInput={(params) => (
        <TextField {...params} {...field} label={label} variant="outlined">
          {selectedValues.map((option, index) => (
            <div className="mx-1" key={index}>
              <Chip
                variant="outlined"
                label={option.title}
                className={option.required ? `font-bold` : `font-light`}
              />
            </div>
          ))}
        </TextField>
      )}
    />
  );
}
