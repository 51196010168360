import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2),
  },
}));

export default function NotesCard({
  amName,
  time,
  notes,
  stageId,
  noteWithStage,
}) {
  const classes = useStyles();

  //TODO getProfileReviewByFreelancerId not working
  let stageName = "Legacy";
  if (!stageId) {
    stageName = "Unavailable";
  }
  /* const {
    getProfileReviewByFreelancerId
  } = data || {}
  if (getProfileReviewByFreelancerId) {
    const stageQueue = getProfileReviewByFreelancerId?.stageQueue.filter((stage) => {
      return stage.id + '' === stageId + ''
    }) || []
    stageName = stageQueue.length > 0 ? stageQueue[0].name : stageName
  }
  stageName = profileReviewError && !stageId
    ? 'Unavailable'
    : stageName */

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          {stageName && noteWithStage.length > 0 && (
            <Typography variant="caption" display="block" gutterBottom>
              Stage: {stageName}
            </Typography>
          )}{" "}
          <Typography variant="caption" display="block" gutterBottom>
            {amName}
          </Typography>{" "}
          <Typography variant="caption" display="block" gutterBottom>
            {time}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography dangerouslySetInnerHTML={{ __html: notes }} />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
}
