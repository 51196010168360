import React from "react";

export const ProjectLogo = () => {
  return (
    <img
      src="/favicon.ico"
      alt="Paro logo"
      width={25}
      height={25}
      className="inline-block"
    />
  );
};
