import React from "react";
import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";

import omit from "lodash/omit";
import styled from "styled-components";

import colors from "./shared/constants/colors";
import {
  getSingleIndustryTag,
  /*  StandardizedParoProject, */
} from "./shared/displayConversions";
import Name from "./styles/Name";
import { ProfileInformation } from "./styles/ProfileInformation";
import { StyledIcon } from "./styles/SpanIcon";
import { TagsSectionTruncate } from "../TagsSectionTruncate";
import { ProjectLogo } from "../ProjectLogo";
import { IconXCircle } from "@paro.io/base-icons";

const SeeMoreButton = styled((props) => {
  return <Button {...omit(props, SeeMoreButton.OmitProps)} />;
})`
  border: 1px solid ${colors.lightGray} !important;
  border-radius: 2px !important;
  padding: 11px 62px !important;
  min-width: 90% !important;
  span {
    color: ${colors.buttonText};
  }
  margin-left: 0px !important;
  ${({ isMoreInfoOpen }) => {
    return (
      isMoreInfoOpen &&
      `
    min-width: 411px !important;
        background-color: ${colors.paroSlategray} !important
         span {
          color: ${colors.altButtonText};
    }
    `
    );
  }}
`;
const StyledDivider = styled((props) => {
  return <Divider variant="middle" {...omit(props, StyledDivider.OmitProps)} />;
})`
  max-width: 90%;
  ${({ isMoreInfoOpen }) => {
    return (
      isMoreInfoOpen &&
      `
     max-width: inherit;
    `
    );
  }}
`;

SeeMoreButton.OmitProps = ["isMoreInfoOpen"];
StyledDivider.OmitProps = ["isMoreInfoOpen"];

const MinimalProjectInformationGrid /* : typeof Grid  */ = styled(Grid)`
  min-width 90%;
`;

/* 
type SectionContents = {
  project: Partial<ExternalProject> | Partial<StandardizedParoProject>
  handleOpenOrClose: (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => void
  isMoreInfoOpen: boolean
} */

export const PROJECT_FREQUENCY_ONETIME = "one-time";
export const PROJECT_FREQUENCY_RECURRING = "recurring";

export const projectSizeTitleMap = {
  ["UNDER_10_HOURS-" + PROJECT_FREQUENCY_ONETIME]: "Small one-time",
  ["UNDER_10_HOURS-" + PROJECT_FREQUENCY_RECURRING]: "Small hours/month",
  ["10_TO_35_HOURS-" + PROJECT_FREQUENCY_ONETIME]: "Medium one-time",
  ["10_TO_35_HOURS-" + PROJECT_FREQUENCY_RECURRING]: "Medium hours/month",
  ["10_TO_40_HOURS-" + PROJECT_FREQUENCY_ONETIME]: "Medium hours/month",
  ["10_TO_40_HOURS-" + PROJECT_FREQUENCY_RECURRING]: "Medium hours/month",
  ["OVER_35_HOURS-" + PROJECT_FREQUENCY_ONETIME]: "Large one-time",
  ["OVER_35_HOURS-" + PROJECT_FREQUENCY_RECURRING]: "Large hours/month",
  ["OVER_40_HOURS-" + PROJECT_FREQUENCY_ONETIME]: "Large one-time",
  ["OVER_40_HOURS-" + PROJECT_FREQUENCY_RECURRING]: "Large hours/month",
};

export const projectSizeSummaryMap = {
  ["UNDER_10_HOURS-" + PROJECT_FREQUENCY_ONETIME]: "(less then 10 hours)",
  ["UNDER_10_HOURS-" + PROJECT_FREQUENCY_RECURRING]:
    "(less then 10 hours/month)",
  ["10_TO_35_HOURS-" + PROJECT_FREQUENCY_ONETIME]: "(about 10 - 35 hours)",
  ["10_TO_35_HOURS-" + PROJECT_FREQUENCY_RECURRING]:
    "(about 10 - 35 hours/month)",
  ["10_TO_40_HOURS-" + PROJECT_FREQUENCY_ONETIME]: "(about 10-40 hours)",
  ["10_TO_40_HOURS-" + PROJECT_FREQUENCY_RECURRING]:
    "(about 10-40 hours/month)",
  ["OVER_35_HOURS-" + PROJECT_FREQUENCY_ONETIME]: "(over 35 hours)",
  ["OVER_35_HOURS-" + PROJECT_FREQUENCY_RECURRING]: "(over 35 hours/month)",
  ["OVER_40_HOURS-" + PROJECT_FREQUENCY_ONETIME]: "(over 40 hours)",
  ["OVER_40_HOURS-" + PROJECT_FREQUENCY_RECURRING]: "(over 40 hours/month)",
};

export const isParoProject = (
  projectType /* : string | undefined */
) /* : boolean */ => {
  return projectType !== "ExternalProject";
};
const truncateTags = (tags /* : string[] */) => {
  if (!tags) {
    return tags;
  }
  return tags.map((tag) => {
    if (tag.length > 39) {
      return tag.substring(0, 39) + " ...";
    }
    return tag;
  });
};

const truncateTitle = (title /* ?: string */) => {
  if (!title || title.length <= 36) {
    return title;
  }
  return title.slice(0, 38) + "...";
};

export default function MinimalProjectInformation(
  {
    project: {
      financialTags = [],
      hoursMonthly,
      hoursTotal,
      industryTags,
      __typename,
      title,
    },
    isMoreInfoOpen,
    handleOpenOrClose,
  } /* : SectionContents */
) /* : JSX.Element */ {
  const thisIsAParoProject = isParoProject(__typename);
  const singleIndustryTag = getSingleIndustryTag(
    industryTags,
    thisIsAParoProject
  );
  const displayInfoCommand = isMoreInfoOpen ? "less" : "more";
  const projectHours = hoursMonthly || hoursTotal;
  const oneTimeOrRecurring = hoursTotal
    ? PROJECT_FREQUENCY_ONETIME
    : PROJECT_FREQUENCY_RECURRING;
  const projectSizeKey = `${projectHours}-${oneTimeOrRecurring}`;
  const projectSizeTitle =
    projectHours && projectSizeTitleMap[projectSizeKey]
      ? projectSizeTitleMap[projectSizeKey]
      : null;
  const projectSizeSummary =
    projectHours && projectSizeSummaryMap[projectSizeKey]
      ? projectSizeSummaryMap[projectSizeKey]
      : null;
  const tasksAccomplished = isMoreInfoOpen
    ? financialTags || []
    : truncateTags((financialTags || []).slice(0, 3));
  const lessThanThreeTagsSpacing =
    tasksAccomplished.length === 1 ? 4 : tasksAccomplished.length === 2 ? 2 : 5;

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      alignContent="stretch"
      spacing={2}
    >
      <Grid item>
        {thisIsAParoProject ? (
          <span className="w-10 h-10 ">
            <ProjectLogo /> This is a Paro project
          </span>
        ) : (
          <span className="w-10 h-10">
            <IconXCircle className="inline-block" /> This is not a Paro project
          </span>
        )}
      </Grid>
      <Grid item sm={12}>
        {thisIsAParoProject ? (
          <Name display="block" noWrap>
            {isMoreInfoOpen ? title : truncateTitle(title)}
          </Name>
        ) : (
          <Name display="block" noWrap>
            {isMoreInfoOpen
              ? title || "Missing title"
              : truncateTitle(title) || "Missing title"}
          </Name>
        )}
      </Grid>
      <StyledDivider isMoreInfoOpen={isMoreInfoOpen} />
      {!thisIsAParoProject ? (
        <Grid item>
          <Typography color="primary" variant="body2">
            Project size
          </Typography>
          {
            <ExternalProjectSizeInfo
              projectSizeTitle={projectSizeTitle}
              projectSizeSummary={projectSizeSummary}
            />
          }
        </Grid>
      ) : (
        <Box pb={11} />
      )}
      {!projectSizeTitle && !thisIsAParoProject && <Box py={1.6} />}
      <MinimalProjectInformationGrid item>
        <Typography color="primary" variant="body2">
          Tasks accomplished
        </Typography>
        <Box py={1} />

        <TagsSectionTruncate tags={tasksAccomplished} tagsExpected={true} />
        {tasksAccomplished.length < 3 && <Box py={lessThanThreeTagsSpacing} />}
      </MinimalProjectInformationGrid>
      <StyledDivider isMoreInfoOpen={isMoreInfoOpen} />
      <Grid item>
        <Typography color="primary" variant="body2">
          Industry
        </Typography>
        <StyledIcon>
          <BusinessOutlinedIcon fontSize={"large"} />
          <Box pl={1}>
            <Typography data-testid={"industryTag"} style={{ fontSize: 16 }}>
              {singleIndustryTag}
            </Typography>
          </Box>
        </StyledIcon>
      </Grid>
      <MinimalProjectInformationGrid item>
        <SeeMoreButton
          data-testid="seeMore"
          isMoreInfoOpen={isMoreInfoOpen}
          onClick={handleOpenOrClose}
        >
          See {displayInfoCommand} information
        </SeeMoreButton>
      </MinimalProjectInformationGrid>
    </Grid>
  );
}

const ExternalProjectSizeInfo = (
  {
    projectSizeTitle,
    projectSizeSummary,
  } /* : { projectSizeTitle?: string | null, projectSizeSummary?: string | null } */
) => {
  return !projectSizeTitle || !projectSizeSummary ? (
    <ProfileInformation>Missing project size</ProfileInformation>
  ) : (
    <>
      <Typography display="block" noWrap>
        {projectSizeTitle}
      </Typography>
      <Typography display="block" noWrap>
        {projectSizeSummary}
      </Typography>
    </>
  );
};
