import React, { useState, useEffect } from "react";
function TruncateText({
  text = "",
  className = "",
  limit = 200,
  ...otherProps
}) {
  const truncatingRequired = text?.length >= limit;
  const shortText = truncatingRequired ? text.slice(0, limit) + "..." : text;
  const [showMore, setShowMore] = useState(false);
  const [value, setValue] = useState(shortText);

  const onButtonClick = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    if (showMore) {
      setValue(text);
    } else {
      setValue(shortText);
    }
    return () => {
      setValue(shortText);
    };
  }, [shortText, showMore, text]);

  return (
    <div {...otherProps} className={`truncate-text ${className}`}>
      <div dangerouslySetInnerHTML={{ __html: value }}></div>
      {truncatingRequired && (
        <div
          className="mt-2 cursor-pointer text-sm text-gray-400"
          onClick={(e) => {
            e.stopPropagation();
            onButtonClick();
          }}
        >{`Show ${showMore ? `Less` : `More`}`}</div>
      )}
    </div>
  );
}

export default TruncateText;
