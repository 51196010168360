import React, { useState } from "react";
import Select from "react-select";
import FeedbackFormComponent from "./FeedbackFormComponent";
import { Box, Grid, Typography } from "@material-ui/core";

export default function FeedbackFormWithSelect({ options, onSubmitFn }) {
  const [group, setGroup] = useState("");

  const handleChange = (option) => {
    setGroup(option.value);
  };

  const reactivateGroup = options.map((item) => {
    return { label: item.groupName, value: item.groupName };
  });

  return (
    <Box bgcolor="white" p={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">
            {"What are the reasons to reactivate the proposal?"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            options={reactivateGroup}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FeedbackFormComponent
            onSubmitFn={(formData) => {
              onSubmitFn({
                ...formData,
                predefinedReasons: formData.predefinedReasons.map(
                  (reason) => `${group}/${reason}`
                ),
              });
            }}
            options={options.find((item) => item.groupName === group)?.options}
            newReasonPlaceholder="i.e. Lack of experience, etc."
            submitButtonText="REACTIVATE"
            commentsHeading="Why do you want to re-activate the current proposal ?"
            submittingText="Re-Activating"
            hideOtherChip
            openOther={group === "Others"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
