const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDateAsMY = (value) => {
  if (value) {
    const date = new Date(value);
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  } else {
    return "Missing Date";
  }
};

export const formatDateAsMDY = (value) => {
  if (value) {
    const date = new Date(value);
    return `${
      monthNames[date.getMonth()]
    } ${date.getDate()},${date.getFullYear()}`;
  } else {
    return "Missing Date";
  }
};
