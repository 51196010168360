import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import TruncateText from "./TruncateText";
import UpdateProposalForm from "./UpdateProposalForm";

import InfoButton from "./InfoButton";
import { makeStyles } from "@material-ui/core/styles";
import { Heading, Text, Tag, Button as BaseUiButton } from "@paro.io/base-ui";
import {
  IconPencil,
  IconDocumentText,
  IconDocumentClock,
  IconMail,
} from "@paro.io/base-icons";
import ReactivateFeedbackForm from "./ReactivateFeedbackForm";
import { Grid } from "@material-ui/core";

/* import { Dialog } from "@headlessui/react"; */
import { Modal } from "react-bootstrap";
import {
  OPPORTUNITY_TYPES,
  ORIGINAL_OPPORTUNITY_TYPES,
} from "../data/appConstants";
import { useSnackbar } from "notistack";
import ProposalJobBoard from "./ProposalJobBoard";
import ConfirmationModal from "./ConfirmationModal";

const information =
  'Proposal Cards: Shows active and inactive (greyed-out) proposal cards. If a proposal card is inactive, click on "Re-activate" to match proposal to experts. Red pen indicates that the proposal form is incomplete.';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "absolute",
    backgroundColor: "#ffffffbd",
  },
  modal: {
    "& .modal-dialog": {
      minWidth: "65vw",
    },
  },
}));
export default function ProposalCards({ data, refreshProposals, isDealDeskUser }) {
  const params = new URLSearchParams(window.location.search);
  const edit = params.get("edit");

  return (
    <div className="px-8 md:px-16 lg:px-24 py-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {data.map((p) => (
          <ProposalCardItem
            key={p.id}
            proposal={p}
            refreshProposals={refreshProposals}
            openEdit={"" + p.id === "" + edit}
            isDealDeskUser={isDealDeskUser}
          />
        ))}
      </div>
    </div>
  );
}

function ProposalCardItem({ proposal, refreshProposals, openEdit, isDealDeskUser }) {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(openEdit);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [proposalDisabled, setProposalDisabled] = useState(
    proposal.proposalDisabled || (isDealDeskUser && proposal.matchesExhausted)
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  /*   const [isOpen, setIsOpen] = useState(true);

  function openModal(e) {
    setIsOpen(true);
    e.stopPropagation();
    setShowDetails(true);
  }
 */
  const currentProposal = {
    ...proposal,
    opportunityTypeMM: !!proposal.opportunityTypeMM
      ? proposal.opportunityTypeMM
      : Object.values(ORIGINAL_OPPORTUNITY_TYPES).includes(
        proposal.opportunityType
      )
        ? proposal.opportunityType
        : OPPORTUNITY_TYPES.NewBusiness,
    clientRate: proposal.matchmakingOptions.rateRange.max,
    industries: proposal.matchmakingOptions.tags.filter(
      (tag) => tag.field === "industries"
    ),
    softwares: proposal.matchmakingOptions.tags.filter(
      (tag) => tag.field === "softwares"
    ),
    skills: proposal.matchmakingOptions.tags.filter(
      (tag) => tag.field === "skills"
    ),
  };

  const [proposalReady, setProposalReady] = useState(
    !!currentProposal.name &&
    !!currentProposal.description &&
    !!currentProposal.notes &&
    !!currentProposal.projectSize &&
    !!currentProposal.serviceLine &&
    !!currentProposal.softwares.length > 0 &&
    !!currentProposal.industries.length > 0 &&
    !!currentProposal.billRateType &&
    !!currentProposal.frequency
  );

  const updateProposalFn = async () => {
    history.push("/");
    setProposalReady(true);
    await refreshProposals();
    setShowDetails(false);

    enqueueSnackbar(`Updated Proposal!`, {
      variant: "success",
    });
  };
  return (
    <>
      <div className="relative">
        {(proposalDisabled || !proposalReady) && (
          <div
            className={`${classes.backdrop} flex items-center	justify-center w-full h-full`}
          >
            {proposalDisabled && (
              <Grid item xs={12} align="center">
                <div className={`${classes.buttonSize}`}>
                  <ReactivateFeedbackForm
                    proposalId={proposal.id}
                    setProposalDisabled={setProposalDisabled}
                  />
                </div>
              </Grid>
            )}
          </div>
        )}

        <div className="w-full h-full rounded bg-white flex flex-col">
          <div className="flex justify-between">
            <Tag
              color={`${((!proposalReady ||
                ["Client Rejected", "Freelancer Rejects"].includes(
                  proposal.proposalStage
                )) &&
                `danger`) ||
                (["Project Won"].includes(proposal.proposalStage) &&
                  `success`) ||
                `info`
                }`}
              label={
                proposalReady ? proposal.proposalStage : "Proposal Incomplete"
              }
              borderRadius="r"
            />
            <div className="font-semibold text-sm text-gray-600 z-20 mt-2 flex">
              {!!currentProposal.opportunityTypeMM
                ? currentProposal.opportunityTypeMM
                : Object.values(ORIGINAL_OPPORTUNITY_TYPES).includes(
                  currentProposal.opportunityType
                )
                  ? currentProposal.opportunityType
                  : OPPORTUNITY_TYPES.NewBusiness}
              <InfoButton information={information} />
            </div>
          </div>

          <div className="px-8 flex flex-col lg:block lg:text-left">
            <div className="flex justify-between">
              <div className="flex items-center mt-2 font-medium text-secondary">
                <IconDocumentText />
                <div className="ml-1">Proposal: {currentProposal.id}</div>
              </div>
              {proposal.proposalFreelancers?.length > 0 && (
                <ProposalJobBoard
                  proposalId={currentProposal.id}
                  onJobBoard={currentProposal.onJobBoard}
                />
              )}
            </div>
            <div className="flex items-center mt-2 font-medium text-secondary">
              <IconDocumentClock />
              <div className="ml-1">Proposal: {currentProposal.name}</div>
            </div>
            <Heading size="h4">{proposal.client.name}</Heading>
            <Text size="sm">
              <TruncateText text={proposal.description} limit={75} />
            </Text>
            {proposal.notes && (
              <>
                <Heading size="h6" className="uppercase tracking-wide mt-2">
                  Additional Notes:
                </Heading>
                <Text size="sm">
                  {proposal.notes && (
                    <TruncateText text={proposal.notes} limit={200} />
                  )}
                </Text>
              </>
            )}
          </div>
          <div className="p-8 flex mt-auto">
            <div className="w-1/2">
              <span id="mm-proposal-select">
                <BaseUiButton
                  disabled={!proposalReady || proposalDisabled}
                  onClick={() => {
                    if (!proposalReady || proposalDisabled) {
                      return;
                    }
                    if (
                      !proposal.opportunityTypeMM ||
                      [
                        OPPORTUNITY_TYPES.NewBusiness,
                        OPPORTUNITY_TYPES.CrossSell,
                        OPPORTUNITY_TYPES.WL,
                      ].includes(proposal.opportunityTypeMM)
                    ) {
                      history.push(`/proposal/${proposal.id}`);
                    } else {
                      setShowConfirmationModal(true);
                    }
                  }}
                  type="button"
                  color="primary"
                  label="Select"
                />
              </span>
            </div>
            {/* // TODO COMM HUB CHANGES */}
            <div className="w-1/4 flex justify-end" id="mm-proposal-comm-hub">
              <BaseUiButton
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_INTERNAL_PORTAL}/communication-hub/${proposal.id}`,
                    "_blank"
                  )
                }
                type="button"
                className={`${!proposalReady ? "z-20" : ""} cursor-pointer`}
                color={`${proposalReady ? "light" : "danger"}`}
                icon={<IconMail />}
              />
            </div>
            {/* // TODO COMM HUB CHANGES */}
            <div className="w-1/4 flex justify-end" id="mm-proposal-edit">
              <BaseUiButton
                onClick={() => setShowDetails(true)}
                /*  onClick={openModal} */
                type="button"
                className={`${!proposalReady ? "z-20" : ""} cursor-pointer`}
                color={`${proposalReady ? "light" : "danger"}`}
                icon={<IconPencil />}
              />
            </div>
          </div>
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          title="Please confirm opportunity type"
          body={
            <>
              <p>
                This proposal was marked as <b>{proposal.opportunityTypeMM}</b>.
              </p>
              <p>
                About to submit an exception case, You will be directed to the
                Find Freelancer function. This action will be tracked &
                validated to ensure proper application.
              </p>
            </>
          }
          okText="Confirm and go to Find Freelancer"
          okButtonId="mm-faf-confirm"
          onOk={() => history.push(`/find-a-freelancer/${proposal.id}`)}
        />
      )}
      {!!showDetails && (
        <Modal
          show={!!showDetails}
          onHide={() => {
            setShowDetails(false);
            history.push("/");
          }}
          className={classes.modal}
          centered
        >
          <UpdateProposalForm
            defaultValues={currentProposal}
            updateProposalFn={updateProposalFn}
          />
        </Modal>
      )}
    </>
  );
}
