import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Chip,
  Grid,
  Button,
  TextField,
  Typography,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useForm, useWatch } from "react-hook-form";
import ChipArray from "./ChipArray";
import CustomSpinner from "./CustomSpinner";
import { CartContext } from "./CartContext";

const useStyles = makeStyles((theme) => ({
  textArea: {
    minWidth: "100%",
  },
  selected: {
    backgroundColor: "#000 !important",
    color: "#fff",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  btn: {
    minWidth: "50%",
    margin: theme.spacing(2),
  },
  checkbox: {
    display: "flex",
    justifyContent: "center",
    margin: "15px 10px",
  },
}));
const CommentSection = ({
  control,
  classes,
  register,
  formState,
  errors,
  other,
  commentsHeading,
  commentsPlaceholder,
  isFinalSubmitForm,
  dependsOnRating,
}) => {
  const [cart] = useContext(CartContext).cartContext;
  const cartEmpty = cart.length === 0;
  const predefinedReasons = useWatch({
    control,
    name: "predefinedReasons", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: [], // default value before the render
  });

  return (
    <>
      {(predefinedReasons.length > 0 || other || dependsOnRating) && (
        <>
          <Grid item xs={12}>
            <Typography variant="body1">
              {commentsHeading}
              {(other && !isFinalSubmitForm) || (cartEmpty && isFinalSubmitForm)
                ? `*`
                : `(Optional)`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="comments"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Comments"
                  multiline
                  rows={3}
                  className={classes.textArea}
                  variant="outlined"
                  placeholder={commentsPlaceholder}
                  {...register(
                    "comments",
                    isFinalSubmitForm
                      ? {
                          required: cartEmpty,
                          validate: (val) =>
                            val && val.length >= (cartEmpty ? 12 : 0),
                        }
                      : {
                          required: other,
                          validate: (val) =>
                            val && val.length >= (other ? 12 : 0),
                        }
                  )}
                  error={formState.isSubmitted && errors.comments}
                  helperText={
                    formState.isSubmitted && errors.comments
                      ? "Minimum 12 characters required"
                      : ""
                  }
                  {...field}
                />
              )}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default function FeedbackFormComponent({
  question = "",
  options = [],
  newReasonPlaceholder = "",
  submitButtonText = "Submit",
  moreButtonText = "",
  onSubmitFn,
  commentsHeading = "",
  commentsPlaceholder = "Comments",
  successfullySubmitted = false,
  note,
  submittingText,
  isFinalSubmitForm,
  dependsOnRating,
  hideOtherChip,
  openOther,
}) {
  const [cart] = useContext(CartContext).cartContext;
  const cartEmpty = cart.length === 0;
  const [other, setOther] = useState(false);
  const [addMore, setAddMore] = useState(cartEmpty ? true : false);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
    formState,
    register,
  } = useForm({ mode: "onChange" });
  const classes = useStyles();
  const onSubmit = (data) => {
    onSubmitFn(data, other);
  };

  useEffect(() => {
    setOther(openOther);
  }, [openOther]);

  return (
    <Box bgcolor="white" py={1}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Grid container justify="center" spacing={3}>
          {!dependsOnRating && (
            <>
              {!!question && (
                <Grid item xs={12}>
                  <Typography variant="body1">{question}</Typography>
                </Grid>
              )}
              {!other && (
                <Grid item xs={12} container>
                  <Grid item>
                    <Controller
                      name="predefinedReasons"
                      defaultValue={[]}
                      control={control}
                      render={({ field }) => (
                        <ChipArray
                          chips={options.map((o, i) => ({ key: i, label: o }))}
                          onSelectChips={(data) => {
                            setValue("predefinedReasons", data);
                            if (
                              formState.isSubmitted &&
                              !other &&
                              data.length > 0
                            ) {
                              clearErrors();
                            }
                          }}
                          {...register("predefinedReasons", {
                            required: !other,
                            validate: (val) => other || val.length > 0,
                          })}
                          {...field}
                        />
                      )}
                    />
                    {!hideOtherChip && (
                      <Chip
                        className={`${classes.chip} ${
                          other ? classes.selected : ""
                        }`}
                        label="Other"
                        onClick={() => {
                          setOther(!other);
                          if (formState.isSubmitted && other) {
                            clearErrors();
                          }
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} className="mx-3">
                    {formState.isSubmitted &&
                      !(
                        getValues("predefinedReasons")?.length > 0 || other
                      ) && (
                        <FormHelperText error>
                          {"Select a Reason"}
                        </FormHelperText>
                      )}
                  </Grid>
                </Grid>
              )}

              {other && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      In a few words please specify the new reason*
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="otherReason"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Reason"
                          variant="outlined"
                          placeholder={newReasonPlaceholder}
                          {...register("otherReason", {
                            required: other,
                            validate: (val) => val.length >= 12,
                          })}
                          error={formState.isSubmitted && errors.otherReason}
                          helperText={
                            formState.isSubmitted && errors.otherReason
                              ? "Minimum 12 characters required"
                              : ""
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          <CommentSection
            control={control}
            classes={classes}
            register={register}
            formState={formState}
            errors={errors}
            other={other}
            commentsHeading={commentsHeading}
            commentsPlaceholder={commentsPlaceholder}
            isFinalSubmitForm={isFinalSubmitForm}
            dependsOnRating={dependsOnRating}
          />

          <Grid item xs={12} container align="center" justify="center">
            {!!submittingText &&
              formState.isSubmitSuccessful &&
              !successfullySubmitted && (
                <CustomSpinner>{submittingText}</CustomSpinner>
              )}
            {!formState.isSubmitSuccessful && !successfullySubmitted && (
              <>
                <Grid container item xs={12} align="center" justify="center">
                  {!!moreButtonText && (
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox defaultChecked id="mm-cart-addMore" />
                        }
                        checked={addMore}
                        onClick={(e) => setAddMore(e.target.checked)}
                        label={moreButtonText}
                        className={classes.checkbox}
                      />
                    </Grid>
                  )}
                  {!!moreButtonText && addMore && (
                    <Grid
                      container
                      item
                      xs={12}
                      align="center"
                      className="my-4"
                    >
                      <Grid item xs={12} className="mb-1">
                        <Typography variant="body1" align="left">
                          <b>Exception Process:</b> to add additional matches
                          through Matching App Desk*
                          {/* {other && !isFinalSubmitForm ? `*` : `(Optional)`} */}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="addMore"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              /*  label="Add More" */
                              multiline
                              rows={3}
                              className={classes.textArea}
                              variant="outlined"
                              placeholder={
                                "Add additional information you want the Matching App Desk to use when matching (i.e., niche requirement, named freelancer)"
                              }
                              {...register("addMore", {
                                required: true,
                                validate: (val) => val && val.length >= 10,
                              })}
                              error={formState.isSubmitted && errors.addMore}
                              helperText={
                                formState.isSubmitted && errors.addMore
                                  ? "Minimum 10 characters required"
                                  : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    {isFinalSubmitForm && cartEmpty && !addMore ? (
                      <Button
                        id="mm-cart-submit"
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.btn}
                        disabled
                      >
                        {submitButtonText}
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        color="primary"
                        id="mm-cart-submit"
                        variant="contained"
                        className={classes.btn}
                        disabled={formState.isSubmitSuccessful}
                      >
                        {formState.isSubmitSuccessful
                          ? `Submitting`
                          : submitButtonText}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12} align="center" justify="center">
                  {note && (
                    <div className="mt-2">
                      <em>
                        Note:{" "}
                        {addMore
                          ? "On submit, the candidate(s) selected from App will be submitted and any additional candidates from Desk will be added in 8-16 hours"
                          : note}
                      </em>
                    </div>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
