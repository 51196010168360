import React, { useState } from "react";
import { toggleJobBoard } from "../services/apiService";
import { CircularProgress } from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSnackbar } from "notistack";

export default function ProposalJobBoard(props) {
  const { proposalId, onJobBoard } = props;

  const [isOnJobBoard, setIsOnJobBoard] = useState(onJobBoard);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const toggleJoboardFn = async (value) => {
    setLoading(true);
    try {
      await toggleJobBoard(proposalId, value);
      setIsOnJobBoard(value);
      enqueueSnackbar(
        value
          ? `Added Proposal ${proposalId} to Opportunity Board!`
          : `Removed Proposal ${proposalId} from Opportunity Board!`,
        {
          variant: value ? "success" : "warning",
        }
      );
    } catch (err) {
      enqueueSnackbar(
        value
          ? `Failed to add Proposal ${proposalId} to Opportunity Board!`
          : `Failed to remove Proposal ${proposalId} from Opportunity Board!`,
        {
          variant: "error",
        }
      );
    }
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <div className="d-flex p-2">
          <CircularProgress size={20} />
        </div>
      ) : (
        <div
          className={`p-2 cursor-pointer`}
          onClick={() => toggleJoboardFn(!isOnJobBoard)}
        >
          {isOnJobBoard ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </div>
      )}
    </>
  );
}
