import React, { useCallback, useContext, useState } from "react";
import ProposalCards from "../components/ProposalCards";
import TopSection from "../components/TopSection";
import { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { CartContext } from "../components/CartContext";
import {
  getProposalFreelancers,
  getProposals,
  getProposalsMatchCount,
  // getProposalsMatchCount,
} from "../services/apiService";
import { useAuth0 } from "../contexts/Auth0Context";
import CustomSpinner from "../components/CustomSpinner";
import { Heap } from "@paro.io/react-heap";
import Select from "react-select";

import { Heading } from "@paro.io/base-ui";
const StageOptionsMap = new Map([
  ["-", "0"],
  ["Proposed", "0"],
  ["Interested", "1"],
  ["ClientProposal", "2"],
  ["IntroEmail", "3"],
  ["SowSubmitted", "4"],
  ["SowAccepted", "5"],
  ["ClosedWon", "91"],
  ["ClientRejected", "92"],
  ["FlRejected", "93"],
]);

const StageTextsMap = new Map([
  ["-", "Ready to Match"],
  ["0", "Ready to Match"],
  ["Interested", "Freelancer(s) Interested"],
  ["ClientProposal", "Client Sent to Match"],
  ["IntroEmail", "Intro Email Sent"],
  ["SowSubmitted", "SOW Sent to Client"],
  ["SowAccepted", "Client Accepts SOW"],
  ["ClosedWon", "Project Won"],
  ["ClientRejected", "Client Rejected"],
  ["FlRejected", "Freelancer Rejects"],
]);

export default function ProposalsList() {
  const { cartContext, proposalContext, matchContext, freelancerContext } =
    useContext(CartContext);
  const [, setCart] = cartContext;
  const [proposalData, setProposalData] = proposalContext;
  const [, setMatchId] = matchContext;
  const [, setFreelancers] = freelancerContext;

  const { user } = useAuth0();
  const { userId, email, auth0UserId } = user;
  const { firstName, lastName, dealdeskUsers } =
    user[`${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`];
  const roles =
    user[`${process.env.REACT_APP_PARO_APP_URL}/roles`] || [];
  const isDealDeskUser = roles?.includes("dealdesk_teams") || false;
  const userName = firstName + " " + lastName;
  const [currentSalesRep, setCurrentSalesRep] = useState({
    label: userName,
    value: auth0UserId,
  });
  const [loading, setLoading] = useState(true);

  const refreshProposals = useCallback(async () => {
    setLoading(true);

    const res = await getProposals(currentSalesRep.value);
    let data = res.data.data.getProposals;

    const proposalFreelancers = await Promise.all(
      data.map((d) => getProposalFreelancers(d.id))
    );

    const proposalIds = data.map((d) => d.id);
    const resMatchCount = await getProposalsMatchCount(
      proposalIds,
      user.auth0UserId
    );

    // console.log(proposalIds);
    // console.log(resMatchCount);
    // TODO
    const proposalsMatchCount = resMatchCount.data.data.getProposalsMatchCount;

    data = data.map((cur, i) => {
      const curPf =
        proposalFreelancers[i].data.data.getProposalFreelancers || [];
      const maxFlStage = Math.max(
        ...curPf.map(
          (pf) => StageOptionsMap.get(pf?.proposedProjectStage) || "0"
        ),
        "0"
      );
      const proposalStage = [...StageOptionsMap.entries()].find(
        (v) => v[1] === "" + maxFlStage
      )[0];

      // TODO
      const matchesExhausted =
        proposalsMatchCount.find((p) => p.proposalId === cur.id)?.matchCount >= 3;

      return {
        ...cur,
        // proposalDisabled:
        //   curPf.length > 2 &&
        //   curPf.filter(
        //     (pf) =>
        //       !(
        //         StageOptionsMap.get(pf.proposedProjectStage) ===
        //           StageOptionsMap.get("ClientRejected") ||
        //         StageOptionsMap.get(pf.proposedProjectStage) ===
        //           StageOptionsMap.get("FlRejected")
        //       )
        //   )?.length <= 2 &&
        //   !matchesLeft,
        proposalDisabled: curPf.some(
          (pf) =>
            StageOptionsMap.get(pf.proposedProjectStage) >
            StageOptionsMap.get("Proposed")
        ),
        proposalStage: StageTextsMap.get(proposalStage),
        proposalFreelancers: curPf.map((f) => ({
          freelancerId: f.freelancerId,
          stageUpdatedAt: f.proposedProjectStageUpdatedAt,
          stage: parseInt(StageOptionsMap.get(f.proposedProjectStage)),
        })),
        matchesExhausted
      };
    }, []);

    setProposalData(data);
    setLoading(false);
  }, [currentSalesRep.value, setProposalData, user.auth0UserId]);

  useEffect(() => {
    refreshProposals();
    setCart([]);
    setMatchId(null);
    setFreelancers([]);
  }, [refreshProposals, setCart, setMatchId, setFreelancers]);

  return (
    <>
      <TopSection />
      <div className="px-8 md:px-16 lg:px-24">
        <Grid container>
          <Grid item xs={9}>
            <Heading size="h3">Select a Proposal to match Freelancers</Heading>
          </Grid>
          {isDealDeskUser && <Grid item xs={3} className="p-4">
            <Select
              label="Select Sales Rep"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={currentSalesRep}
              options={[{
                label: userName,
                value: auth0UserId,
              }, ...dealdeskUsers?.map((u) => ({
                label: u.firstName + " " + u.lastName,
                value: u.userId,
              }))]}
              onChange={setCurrentSalesRep}
            />
          </Grid>}
        </Grid>
      </div>
      {loading && <CustomSpinner>Fetching Proposals</CustomSpinner>}
      {!loading && proposalData?.length === 0 && (
        <div className="flex justify-center p-8 font-bold text-secondary">No Active Proposals</div>
      )}
      {!loading && !!proposalData?.length && (
        <ProposalCards
          data={proposalData}
          refreshProposals={refreshProposals}
          isDealDeskUser={isDealDeskUser}
        />
      )}
      <Heap
        appId={process.env.REACT_APP_HEAP_APP_ID}
        userId={`${userId}`}
        userData={{ email: email, userType: 2 }}
      />
    </>
  );
}
