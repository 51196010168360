import { Box } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Avatar, Button as BaseUiButton, Heading } from "@paro.io/base-ui";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import orderBy from "lodash/orderBy";

import Pagination from "../components/Pagination";
import { Auth0Context } from "../contexts/Auth0Context";
import {
  addFindFreelancersToCartFn,
  removeFindFlFromCartFn,
} from "../services/apiService";
import ConfirmationModal from "./ConfirmationModal";
import Header from "./Header";
import { CartContext } from "./CartContext";
import { Spinner } from "react-bootstrap";

const TableHeader = ({ sortFreelancers }) => {
  const [sortedField, setSortedField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  useEffect(() => {
    sortFreelancers(sortedField, sortOrder);
  }, [sortFreelancers, sortOrder, sortedField]);

  return (
    <thead className="bg-gray-50">
      <tr className="p-2 text-left text-xs font-medium text-gray-500 tracking-wider">
        <th scope="col" className="relative p-2">
          <span className="sr-only">Select</span>
        </th>
        <Header
          onClick={() => {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            return setSortedField("name");
          }}
          selected={sortedField === "name"}
          mode={sortOrder}
        >
          Name
        </Header>
        <Header
          onClick={() => {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            return setSortedField("serviceLine");
          }}
          selected={sortedField === "serviceLine"}
          mode={sortOrder}
        >
          Service Line
        </Header>
        <Header
          onClick={() => {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            return setSortedField("freelancerHours");
          }}
          selected={sortedField === "freelancerHours"}
          mode={sortOrder}
        >
          Goal Workload
        </Header>
        <Header
          onClick={() => {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            return setSortedField("totalProposalClients");
          }}
          selected={sortedField === "totalProposalClients"}
          mode={sortOrder}
        >
          Clients (L | C | P)
        </Header>
        <Header
          onClick={() => {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            return setSortedField("availableHours");
          }}
          selected={sortedField === "availableHours"}
          mode={sortOrder}
        >
          Avail. Hrs.
        </Header>
        <Header
          onClick={() => {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            return setSortedField("state");
          }}
          selected={sortedField === "state"}
          mode={sortOrder}
        >
          State Name
        </Header>
        <Header
          onClick={() => {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
            return setSortedField("clientBillRate");
          }}
          selected={sortedField === "clientBillRate"}
          mode={sortOrder}
        >
          Client Rate
        </Header>
        <th
          scope="col"
          className={`p-2 text-left text-xs font-medium tracking-wider`}
        >
          JSON
        </th>
      </tr>
    </thead>
  );
};

const getHours = (hours) => {
  if (hours === 0) return "0 hrs";
  if (hours) return `${Math.round(hours)} hrs`;
  else return " - ";
};

const formatCountTo2Digit = (count) => {
  if (count === null) return "00";
  if (count < 10) return `0${count}`;
  else return count.toString();
};

const getRate = (rate) => {
  if (rate === 0) return "$0";
  if (rate) return `$${Math.round(rate)}`;
  else return " - ";
};

const getUserState = (state) => {
  return state ? state : " - ";
};

const Freelancer = ({ freelancer, proposalId, user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [cart, setCart] = useContext(CartContext).cartContext;
  const [loader, setLoader] = useState(false);

  const onChangeHandler = async (e) => {
    e.stopPropagation();
    setLoader(true);
    if (!cart.some((c) => c.id === freelancer.id)) {
      await addFindFreelancersToCartFn({
        proposalId: +proposalId,
        userId: user.auth0UserId,
        findFreelancerIds: [...cart.map((c) => c.flID), freelancer.id],
      });
      setCart((cart) => [...cart, { ...freelancer, flID: freelancer.id }]);
      enqueueSnackbar(`Added ${freelancer.name} to Cart!`, {
        variant: "success",
      });
    } else {
      await removeFindFlFromCartFn({
        proposalId: +proposalId,
        userId: user.auth0UserId,
        freelancerId: freelancer.id,
      });
      setCart((currentState) =>
        currentState.filter((c) => c.flID !== freelancer.id)
      );
      enqueueSnackbar(`Removed ${freelancer.name} from Cart!`, {
        variant: "warning",
      });
    }
    setLoader(false);
  };

  return (
    <tr className={`${freelancer.anchored ? "bg-green-100" : ""}`}>
      <td className="p-2 whitespace-nowrap text-right text-sm font-medium">
        {loader ? (
          <Spinner animation="grow" size="sm" className="text-info" />
        ) : (
          <input
            id="mm-faf-selectFreelancer"
            type="checkbox"
            className="form-checkbox h-6 w-5 bg-gray-200"
            onChange={onChangeHandler}
            checked={cart.some((c) => c.id === freelancer.id)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_PARO_APP_URL}/internal/expert-profile/${freelancer.id}`,
              "_blank"
            );
          }}
        >
          <div className="flex flex-col items-center justify-center flex-shrink-0 rounded-full border h-10 w-10">
            <Avatar name={freelancer.name} src={"https://bit.ly/broken-link"} />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900">{freelancer.name}</div>
            {freelancer.freelancerStatus === "Not Available for Hire" && (
              <div className="font-medium text-red-info">
                {freelancer.freelancerStatus}
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
        <div className="text-gray-900">{freelancer.serviceLine}</div>
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
        <span className="px-2 inline-flex leading-5">
          {getHours(freelancer.freelancerHours)}
        </span>
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
        {formatCountTo2Digit(freelancer.previousMonthClientCount)} |{" "}
        {formatCountTo2Digit(freelancer.currentMonthClientCount)} |{" "}
        {formatCountTo2Digit(freelancer.totalProposalClients)}
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
        {getHours(freelancer.availableHours)}
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
        {getUserState(freelancer.state)}
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
        {getRate(freelancer.clientBillRate)}
      </td>
      <td className="p-2 whitespace-nowrap text-right font-medium">
        <div className="text-center">
          <div
            className="bg-gray-200 w-10 p-2 hover:text-indigo-900 cursor-pointer"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_PARO_APP_URL}/internal/am/findfreelancers/jsonview/${freelancer.id}?navigation=false`,
                "_blank"
              );
            }}
          >
            <OpenInNewIcon />
          </div>
        </div>
      </td>
    </tr>
  );
};

const FindFreelancerTable = ({
  proposalId,
  freelancers = [],
  anchoredFls = [],
  buyout,
  sortFreelancers,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageItems, setPerPageItems] = useState(10);
  const [currentFreelancers, setCurrentFreelancers] = useState([]);
  const [unavailableModal, setUnavailableModal] = useState(false);

  const history = useHistory();
  const { user } = useContext(Auth0Context);
  const [cart] = useContext(CartContext).cartContext;

  useEffect(() => {
    const allFreelancersWithAcnchored = [
      ...anchoredFls.map((freelancer) => ({ ...freelancer, anchored: true })),
      ...freelancers.map((freelancer) => ({
        ...freelancer,
        anchored: false,
      })),
    ];
    if (allFreelancersWithAcnchored.length > perPageItems * (currentPage - 1)) {
      const firstRec = (currentPage - 1) * perPageItems;
      const lastRec = (currentPage - 1) * perPageItems + perPageItems;
      setCurrentFreelancers(
        allFreelancersWithAcnchored.slice(firstRec, lastRec)
      );
    }
  }, [anchoredFls, currentPage, freelancers, perPageItems]);

  const onClickSubmit = () => {
    if (
      freelancers.some(
        (f) =>
          f.freelancerStatus === "Not Available for Hire" &&
          cart.some((c) => c.flID === f.id)
      )
    ) {
      setUnavailableModal(true);
    } else {
      history.push(`/proposal/${proposalId}/checkout`);
    }
  };

  const totalFreelancers = anchoredFls.length + freelancers.length;

  return (
    <>
      <Box className="container mx-auto rounded-lg px-6 py-2">
        <div className="flex flex-col">
          <div className="flex w-full">
            <div className="w-1/3 w-min-100">
              <Heading size="h4">
                {buyout
                  ? `Buy Out Freelancer`
                  : `Search Results (${totalFreelancers})`}
              </Heading>
            </div>
            <div className="w-full flex flex-col justify-center">
              <div className="flex flex-row justify-end w-full content-end">
                <div className="py-2 font-medium px-4 mx-2">
                  {`${cart.length} Freelancer${
                    cart.length !== 1 ? `s` : ``
                  } Selected`}
                </div>
                <div>
                  <span id="mm-faf-checkout">
                    {cart?.length < 1 ? (
                      <BaseUiButton
                        color="default"
                        label={"Select at least 1 freelancer"}
                        disabled={cart?.length < 1}
                      />
                    ) : (
                      <BaseUiButton
                        color="primary"
                        label={"Cart"}
                        onClick={onClickSubmit}
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                <table className="w-full divide-y divide-gray-200">
                  <TableHeader sortFreelancers={sortFreelancers} />
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentFreelancers.map((freelancer) => {
                      return (
                        <Freelancer
                          key={freelancer.id}
                          freelancer={freelancer}
                          user={user}
                          proposalId={proposalId}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {totalFreelancers > 10 && (
          <Pagination
            total={totalFreelancers}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPageItems={perPageItems}
            setPerPageItems={setPerPageItems}
          />
        )}
      </Box>
      {unavailableModal && (
        <ConfirmationModal
          show={unavailableModal}
          setShow={setUnavailableModal}
          title="Freelancer(s) not available for hire"
          body={
            <>
              <span>
                You are trying to submit one or more Freelancers that are
                currently marked as{" "}
              </span>
              <span className="text-red-info font-medium">
                Not available for hire.{" "}
              </span>
              <span>
                If you continue, this will over-ride that flag and Freelancer
                will receive{" "}
                <span className="text-green-info font-medium">
                  "Interest Email"{" "}
                </span>
                and will be able to submit pitches to this proposal.
              </span>

              <p>Do you want to continue?</p>
            </>
          }
          okText="Continue"
          onOk={() => {
            history.push(`/proposal/${proposalId}/checkout`);
            setUnavailableModal(false);
          }}
          okButtonId="mm-faf-confirmException"
        />
      )}
    </>
  );
};

const FindFreelancerResults = (props) => {
  const [freelancers, setFreelancers] = useState(props.freelancers);
  const sortFreelancers = useCallback((sortedField, sortOrder) => {
    setFreelancers((freelancers) => {
      return orderBy(freelancers, sortedField, sortOrder);
    });
  }, []);
  return (
    <FindFreelancerTable
      {...props}
      freelancers={freelancers}
      sortFreelancers={sortFreelancers}
    />
  );
};

export default FindFreelancerResults;
