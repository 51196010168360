import React from "react";
import {
  Box,
  DialogContent,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";

import {
  convertBooleanToString,
  convertEnumToDisplayFormat,
  // StandardizedParoProject,
} from "./shared/displayConversions";
import { LineBreakTypography } from "./styles/LineBreakTypography";
import MinimalProjectInformation, {
  isParoProject,
} from "./MinimalProjectInformation";
import { TagsSectionTruncate } from "../TagsSectionTruncate";

/* type SectionContents = {
  project: Partial<ExternalProject | StandardizedParoProject>
  open: boolean
  handleClose: ((event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => void)
} */

const onsiteEnumDisplayMap = {
  LOCAL: "Yes, locally",
  TRAVELED: "Yes, traveled",
  REMOTE: "No, remote",
};

// const convertDate = (date: string | undefined): string | null => {
const convertDate = (date) => {
  if (!date) {
    return null;
  }
  const dateObject = new Date(date);
  return [
    dateObject.getMonth() + 1,
    dateObject.getDate(),
    dateObject.getFullYear(),
  ]
    .map((datePart) => {
      return datePart < 10 ? `0${datePart}` : `${datePart}`;
    })
    .join("/");
};

const DisplayFundingSources = (
  { fundingSources } /* : {
  fundingSources: string[],
} */
) => {
  return fundingSources.length === 0 ? (
    <Box py={1.5} />
  ) : (
    <>
      {fundingSources.map((fundingSource, index) => {
        return (
          <div key={index}>
            <Typography>
              {convertEnumToDisplayFormat(fundingSource, true)}
            </Typography>
          </div>
        );
      })}
    </>
  );
};

const DisplayParoProjectValueOrFormattedEmptySpace = (
  { paroProjectValue } /* : {
  paroProjectValue?: string | null,
} */
) => {
  return !paroProjectValue ? (
    <Box py={1.5}></Box>
  ) : (
    <Typography>{paroProjectValue}</Typography>
  );
};

const ProjectDescription = (
  { description } /* : { description?: string } */
) => {
  return !description ? (
    <Typography>Missing description</Typography>
  ) : (
    <LineBreakTypography
      data-testid="descriptionHtml"
      dangerouslySetInnerHTML={{ __html: description }}
    ></LineBreakTypography>
  );
};

const ProjectInfoFundingSources = (
  { fundingSources } /* : {
  fundingSources?: string[],
} */
) => {
  const isNull = !fundingSources || fundingSources.length === 0;
  return isNull ? (
    <Typography>Missing funding sources</Typography>
  ) : (
    <DisplayFundingSources fundingSources={fundingSources || []} />
  );
};
// const truncateTitle = (title /* ?: string */) => {
//   if (!title || title.length <= 36) {
//     return title;
//   }
//   return title.slice(0, 38) + "...";
// };

export default function MoreProjectInformation({
  handleClose,
  open,
  project,
  project: {
    companyRevenueModel,
    description,
    financialTags,
    fundingSources,
    isInternational,
    softwareTags,
    startDate,
    endDate,
    annualCompanyRevenueRange,
    companySize,
    companyType,
    onsite,
    __typename,
  },
}) {
  // }: SectionContents): JSX.Element {
  const thisIsAParoProject = isParoProject(__typename);
  // const hideBreakpoints: ("xs" | "sm" | "md" | "lg" | "xl")[] = ["xs", "sm"];
  const hideBreakpoints = ["xs", "sm"];
  const isInternationalIsNull = !(
    typeof isInternational === "boolean" ||
    isInternational === "true" ||
    isInternational === "false"
  );
  const onsiteDisplayValue =
    onsite && onsiteEnumDisplayMap[onsite]
      ? onsiteEnumDisplayMap[onsite]
      : null;

  return (
    <Dialog maxWidth="lg" onClose={handleClose} open={open}>
      <DialogContent>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Hidden only={hideBreakpoints}>
            <Box pt={2} pl={4}>
              <Grid item>
                <MinimalProjectInformation
                  isMoreInfoOpen={open}
                  project={project}
                  handleOpenOrClose={handleClose}
                />
              </Grid>
            </Box>
            <Box pl={4} pr={3.5}>
              <Divider orientation="vertical" />
            </Box>
          </Hidden>
          <Grid item xs>
            <Grid
              container
              alignItems="stretch"
              direction="column"
              justify="space-between"
              spacing={2}
            >
              <Grid item>
                <Typography color="primary" variant="body2">
                  Annual revenue
                </Typography>
                {thisIsAParoProject ? (
                  <DisplayParoProjectValueOrFormattedEmptySpace
                    paroProjectValue={convertEnumToDisplayFormat(
                      annualCompanyRevenueRange
                    )}
                  />
                ) : (
                  <Typography>
                    {convertEnumToDisplayFormat(annualCompanyRevenueRange) ||
                      "Missing annual revenue"}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  Number of employees
                </Typography>
                {thisIsAParoProject ? (
                  <DisplayParoProjectValueOrFormattedEmptySpace
                    paroProjectValue={convertEnumToDisplayFormat(companySize)}
                  />
                ) : (
                  <Typography>
                    {convertEnumToDisplayFormat(companySize) ||
                      "Missing number of employees"}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  Entity type
                </Typography>
                {thisIsAParoProject ? (
                  <DisplayParoProjectValueOrFormattedEmptySpace
                    paroProjectValue={convertEnumToDisplayFormat(
                      companyType,
                      true
                    )}
                  />
                ) : (
                  <Typography>
                    {convertEnumToDisplayFormat(companyType, true) ||
                      "Missing entity type"}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  Funding sources
                </Typography>
                {thisIsAParoProject ? (
                  <DisplayFundingSources
                    fundingSources={fundingSources || []}
                  />
                ) : (
                  <ProjectInfoFundingSources fundingSources={fundingSources} />
                )}
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  Business/Revenue Model
                </Typography>
                {thisIsAParoProject ? (
                  <DisplayParoProjectValueOrFormattedEmptySpace
                    paroProjectValue={convertEnumToDisplayFormat(
                      companyRevenueModel
                    )}
                  />
                ) : (
                  <Typography>
                    {convertEnumToDisplayFormat(companyRevenueModel) ||
                      "Missing Business/Revenue Model"}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  International business
                </Typography>
                {thisIsAParoProject ? (
                  <DisplayParoProjectValueOrFormattedEmptySpace
                    paroProjectValue={
                      isInternationalIsNull
                        ? undefined
                        : convertBooleanToString(isInternational)
                    }
                  />
                ) : (
                  <Typography>
                    {!isInternationalIsNull
                      ? convertBooleanToString(isInternational)
                      : "Missing International business"}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  Start Date
                </Typography>
                {thisIsAParoProject ? (
                  <DisplayParoProjectValueOrFormattedEmptySpace
                    paroProjectValue={convertDate(startDate)}
                  />
                ) : (
                  <Typography>
                    {convertDate(startDate) || "Missing start date"}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  End Date
                </Typography>
                {thisIsAParoProject ? (
                  <DisplayParoProjectValueOrFormattedEmptySpace
                    paroProjectValue={convertDate(endDate) || "Current"}
                  />
                ) : (
                  <Typography>{convertDate(endDate) || "Current"}</Typography>
                )}
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  Do you work onsite?
                </Typography>
                {thisIsAParoProject ? (
                  <DisplayParoProjectValueOrFormattedEmptySpace
                    paroProjectValue={onsiteDisplayValue}
                  />
                ) : (
                  <Typography>
                    {onsiteDisplayValue || "Missing onsite"}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid
              container
              alignItems="stretch"
              direction="column"
              justify="space-between"
              spacing={2}
            >
              <Grid item>
                <Typography color="primary" variant="body2">
                  Brief description
                </Typography>
                {thisIsAParoProject ? (
                  <LineBreakTypography
                    data-testid="descriptionHtml"
                    dangerouslySetInnerHTML={{
                      __html: description || "",
                    }}
                  ></LineBreakTypography>
                ) : (
                  <ProjectDescription description={description} />
                )}
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  Financial tasks
                </Typography>
                <Box py={0.5} />

                <TagsSectionTruncate
                  tags={financialTags || []}
                  tagsExpected={true}
                />
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  Software
                </Typography>
                <Box py={0.5} />
                <TagsSectionTruncate
                  tags={softwareTags || []}
                  tagsExpected={!thisIsAParoProject}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
