import { useContext, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { ReactSortable } from "react-sortablejs";
import { NEW_WIDGETS } from "../data/appConstants";
import { saveFLProfileSettings } from "../services/apiService";
import { CartContext } from "./CartContext";

import AboutFreelancerCard from "./AboutFreelancerCard";
import CertificatesCard from "./CertificatesCard";
import EducationCard from "./EducationCard";
import EmploymentCard from "./EmploymentCard";
import ProjectsTemplateCard from "./ProjectsTemplateCard";
import WorkLoadPreferenceCard from "./WorkLoadPreferenceCard";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 250,
  },
  button: {
    width: 80,
    height: 40,
  },
  dragHandle: {
    cursor: "move",
    height: "100%",
    paddingTop: 9,
  },
  heading: {
    paddingTop: 9,
    fontWeight: 500,
  },
  boldFont: {
    fontWeight: 500,
  },
}));

export default function FreelancerProfileViewUpdate(props) {
  const classes = useStyles();
  const { template, flData, newWidgets } = props;
  const [templates, setTemplates] = useContext(CartContext).flViewContext;
  if (
    newWidgets &&
    !template.fields.find((w) => NEW_WIDGETS.includes(w.section))
  ) {
    template.fields.push(...newWidgets);
  }

  const [fields, setFields] = useState(template.fields);

  const { handleSubmit } = useForm({ mode: "onChange" });

  const handleChange = (event, f) => {
    const updatedFields = [...fields];
    const index = fields.findIndex((field) => field.section === f.section);
    fields[index].checked = event.target.checked;

    setFields(updatedFields);
  };
  const onSave = async (data) => {
    props.onClose("saving");

    let fieldsData = fields.map((f) => ({
      section: f.section,
      checked: f.checked,
    }));

    const updatedTemplate = {
      ...template,
      fields: fieldsData,
    };

    const saveTemplate = {
      ...updatedTemplate,
      fields: JSON.stringify(fieldsData),
    };

    await saveFLProfileSettings(saveTemplate);
    templates.map((t) =>
      t.templateName === updatedTemplate.templateName ? updatedTemplate : t
    );
    setTemplates(templates);
    props.setSelectedTemplate(updatedTemplate);
    props.onClose("");
  };

  const handleClose = () => {
    props.onClose("");
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSave)} noValidate autoComplete="off">
        <Grid container className="mb-4">
          <Grid item xs={6}>
            <FormControl disabled className={classes.formControl}>
              <InputLabel>{template.templateName}</InputLabel>
              <Select />
            </FormControl>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Button
              variant="contained"
              onClick={handleClose}
              className={`mt-1  ${classes.button}`}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={`ml-2 mt-1 ${classes.button}`}
              type="submit"
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </form>

      <div className="mb-5">
        <Typography className={`mb-2 ${classes.boldFont}`}>
          Select a section to be visible, drag a section to arrange them in your
          preffered order
        </Typography>
        <ReactSortable
          list={fields}
          setList={setFields}
          handle=".handle"
          animation={150}
        >
          {fields.map((f, index) => (
            <>
              {f.section.toLowerCase() === "about" && (
                <AboutFreelancerCard
                  freelancer={flData}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
              {/*   {f.section.toLowerCase() === "overview" && (
                <OverviewFreelancerCard
                  freelancer={flData}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )} */}
              {/* {f.section.toLowerCase() === "specialities" && (
                <SpecialitiesCard
                  skills={flData.personalSummary?.topFinancialSkills}
                  industries={flData.personalSummary?.topIndustries}
                  software={flData.personalSummary?.topSoftwareSkills}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )} */}
              {f.section.toLowerCase() === "work and preferences" && (
                <WorkLoadPreferenceCard
                  freelancer={flData}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
              {f.section.toLowerCase() === "work experience" && (
                <EmploymentCard
                  workExperiences={flData.workExperiences}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
              {f.section.toLowerCase() === "education" && (
                <EducationCard
                  education={flData.education}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
              {f.section.toLowerCase() === "accomplishments" && (
                <CertificatesCard
                  certificates={flData.certificates}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
              {f.section.toLowerCase() === "projects" && (
                <ProjectsTemplateCard
                  externalProjects={flData.externalProjects}
                  paroProjects={flData.paroProjects}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
            </>
          ))}
        </ReactSortable>
      </div>
    </Box>
  );
}
