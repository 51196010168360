import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
} from "@material-ui/core";
import { formatDateAsMY } from "../utils/dateService";
import { ExpandMore } from "@material-ui/icons";
import AccordionHeader from "./AccordionHeader";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(0, 0, 3, 0),
  },
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default function EmploymentCard({
  workExperiences,
  widget,
  handleChange,
  dragIndicator,
  checkbox,
}) {
  const classes = useStyles();
  return (
    <Accordion className={`mt-3 mb-2 ${classes.accordionRoot}`} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />} aria-label="Expand">
        <AccordionHeader
          title="WORK EXPERIENCE"
          widget={widget}
          handleChange={handleChange}
          dragIndicator={dragIndicator}
          checkbox={checkbox}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {workExperiences.map((w) => (
            <Paper className={classes.paper} p={3} key={w.id}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <h6>{w.title}</h6>
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                  {`${formatDateAsMY(w.startDate)} - ${formatDateAsMY(
                    w.endDate
                  )}`}
                </Grid>
                <Grid item xs={12}>
                  <em className="mb-4">{w.employer}</em>
                </Grid>
                <Grid item xs={12}>
                  <div dangerouslySetInnerHTML={{ __html: w.description }} />
                </Grid>

                <Grid item xs={4}>
                  <h6>Skill Tags</h6>
                  <Box className="pr-2">
                    {w.financialTags?.length > 0
                      ? w.financialTags.map((c, i) => (
                          <Chip
                            key={i}
                            size="small"
                            label={c}
                            className={classes.chip}
                          />
                        ))
                      : "Missing Data"}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <h6>Software Tags</h6>
                  <Box className="pr-2">
                    {w.softwareTags?.length > 0
                      ? w.softwareTags.map((c, i) => (
                          <Chip
                            key={i}
                            size="small"
                            label={c}
                            className={classes.chip}
                          />
                        ))
                      : "Missing Data"}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <h6>Industry Tags</h6>
                  <Box className="pr-2">
                    {w.industryTags?.length > 0
                      ? w.industryTags.map((c, i) => (
                          <Chip
                            key={i}
                            size="small"
                            label={c}
                            className={classes.chip}
                          />
                        ))
                      : "Missing Data"}
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
