import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import FieldAndValue from "./_global/FieldAndValue";
import MissingItem from "./_global/MissingItem";
import {
  convertBooleanToString,
  convertEnumToDisplayFormat,
  projectTypeEnumToDisplayMap,
} from "./shared/displayConversions";
import { areAllItemsNull } from "./shared/utils";
import { ProfileInformation } from "./styles/ProfileInformation";
import { SectionBody } from "./styles/SectionBody";

/* type SectionContents = {
  workPreferences: Partial<WorkPreference>
} */

const WorkPreferences = (
  {
    workPreferences: {
      billingCadence,
      contactStylePreference,
      contactTimePreference,
      isOpenToOnsite,
      projectType,
    },
  } /* : SectionContents */
) /* : JSX.Element */ => {
  const allItemsAreNull = areAllItemsNull([
    contactStylePreference,
    contactTimePreference,
    isOpenToOnsite,
    projectType,
  ]);

  return (
    <>
      {allItemsAreNull ? (
        <MissingItem title="work and preferences" />
      ) : (
        <SectionBody>
          <Box pb={2}>
            <Typography variant="h6">Work Preferences</Typography>
          </Box>
          <Grid container item justify="space-between" xs={12}>
            <FieldAndValue>
              <ProfileInformation variant="body2">
                Project type preference
              </ProfileInformation>
              <ProfileInformation rr>
                {projectType
                  ? projectTypeEnumToDisplayMap[projectType]
                  : "Missing data"}
              </ProfileInformation>
            </FieldAndValue>
            <FieldAndValue>
              <ProfileInformation variant="body2">
                Billing preference
              </ProfileInformation>
              <ProfileInformation rr>
                {billingCadence
                  ? convertEnumToDisplayFormat(billingCadence, true)
                  : "Missing data"}
              </ProfileInformation>
            </FieldAndValue>
            <FieldAndValue>
              <ProfileInformation variant="body2">
                Time available to talk
              </ProfileInformation>
              <ProfileInformation rr>
                {contactTimePreference
                  ? convertEnumToDisplayFormat(contactTimePreference, true)
                  : "Missing data"}
              </ProfileInformation>
            </FieldAndValue>
            <FieldAndValue>
              <ProfileInformation variant="body2">
                Willing to go onsite?
              </ProfileInformation>
              <ProfileInformation rr>
                {isOpenToOnsite != null
                  ? convertBooleanToString(isOpenToOnsite)
                  : "Missing data"}
              </ProfileInformation>
            </FieldAndValue>
            <FieldAndValue>
              <ProfileInformation variant="body2">
                Best method of communication
              </ProfileInformation>
              <ProfileInformation rr>
                {contactStylePreference
                  ? convertEnumToDisplayFormat(contactStylePreference, true)
                  : "Missing data"}
              </ProfileInformation>
            </FieldAndValue>
          </Grid>
        </SectionBody>
      )}
    </>
  );
};

export default WorkPreferences;
