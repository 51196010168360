import { useState } from "react";
import TagsSection from "./legacy/_global/TagsSection";

export const TagsSectionTruncate = (props) => {
  const limit = 3;
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <TagsSection
        {...props}
        tags={showMore ? props.tags : props.tags.slice(0, limit)}
      />
      <br />
      {props.tags.length > limit && (
        <div
          className="mt-2 cursor-pointer text-sm text-gray-400"
          onClick={(e) => {
            setShowMore(!showMore);
          }}
        >{`Show ${showMore ? `Less` : `More`}`}</div>
      )}
    </>
  );
};
