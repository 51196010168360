import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { CartContext } from "./CartContext";
import { useParams } from "react-router-dom";
import { submitAppFeedback } from "../services/apiService";
import { useSnackbar } from "notistack";
import { Auth0Context } from "../contexts/Auth0Context";

export default function ApplicationFeedbackForm({ setShowFeedback }) {
  const { enqueueSnackbar } = useSnackbar();

  const { cartContext, matchContext } = useContext(CartContext);
  const [cart] = cartContext;
  const [matchId] = matchContext;
  const { proposalId } = useParams();
  const { user } = useContext(Auth0Context);
  const { firstName, lastName } =
    user[`${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`];
  const userName = firstName + " " + lastName;
  const [radioValue, setRadioValue] = useState("");

  const { control, handleSubmit, setValue } = useForm({ mode: "onChange" });

  const onSubmit = async (data) => {
    await submitAppFeedback({
      ...data,
      userId: user.legacyFreelancerId,
      userName,
      url: window.location.href,
      context: JSON.stringify({ proposalId, matchId, cart }),
    });

    enqueueSnackbar(`Thank you for your feedback!`, {
      variant: "success",
    });
    setShowFeedback(false);
  };

  return (
    <Box bgcolor="white" mx={1} my={1} p={4}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight="fontWeightBold">
              Please provide feedback below
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              What is your feedback related to:
            </Typography>
            <Controller
              name="relatedTo"
              render={() => (
                <RadioGroup
                  aria-label="relatedTo"
                  value={radioValue}
                  onChange={(event) => {
                    setRadioValue(event.target.value);
                    setValue("relatedTo", event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Issues with the Application"
                    control={<Radio />}
                    label="Issues with the Application"
                    className="my-0"
                    id="mm-appFeedback-optionOne"
                  />
                  <FormControlLabel
                    value="Interface improvement"
                    control={<Radio />}
                    label="Interface improvement"
                    className="my-0"
                    id="mm-appFeedback-optionTwo"
                  />
                  <FormControlLabel
                    value="Feature suggestion"
                    control={<Radio />}
                    label="Feature suggestion"
                    className="my-0"
                    id="mm-appFeedback-optionThree"
                  />
                </RadioGroup>
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Enter Feedback</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="comments"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Comments"
                  fullWidth
                  multiline
                  rows={3}
                  className={"w-100"}
                  variant="outlined"
                  placeholder="Comments"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              id="mm-appFeedback-send"
              variant="contained"
              color="primary"
              type="submit"
            >
              SEND
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
