import { useContext, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import { Edit as EditIcon, Widgets } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";

import FreelancerProfileViewCreate from "./FreelancerProfileViewCreate";
import FreelancerProfileViewUpdate from "./FreelancerProfileViewUpdate";
import { CartContext } from "./CartContext";
import { updateTemplateSelection } from "../services/apiService";
import { useAuth0 } from "../contexts/Auth0Context";
import { NEW_WIDGETS } from "../data/appConstants";
import NewWidgetGrid from "./NewWidgetGrid";
import CustomSpinner from "./CustomSpinner";

import AboutFreelancerCard from "./AboutFreelancerCard";
import CertificatesCard from "./CertificatesCard";
import EducationCard from "./EducationCard";
import EmploymentCard from "./EmploymentCard";
import ProjectsTemplateCard from "./ProjectsTemplateCard";
import WorkLoadPreferenceCard from "./WorkLoadPreferenceCard";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 250,
  },
  disableIcon: {
    color: grey[400],
    margin: theme.spacing(3, 0, 0, 1),
  },
}));

export default function FreelancerFullProfileView(props) {
  const classes = useStyles();
  const { data } = props;
  const [templates, setTemplates] = useContext(CartContext).flViewContext;
  const { user } = useAuth0();

  const templateNames = templates.map((t) => t.templateName);
  const [view, setView] = useState("");

  const defaultTemplate = templates.find((t) => t.templateName === "Default");

  let sel = templates.find((t) => t.selected);

  const [disabled, setDisabled] = useState(sel ? false : true);
  const [selectedTemplate, setSelectedTemplate] = useState(
    sel ? sel : defaultTemplate
  );
  const newWidgets = NEW_WIDGETS.map((item) => ({
    section: item,
    checked: false,
  }));
  const hasNewWidget = selectedTemplate.fields.find((w) =>
    NEW_WIDGETS.includes(w.section)
  );
  const [showNewWidget, setShowNewWidget] = useState(
    hasNewWidget ? false : true
  );

  async function handleChange(event) {
    setView("saving");
    let val = event.target.value;
    selectedTemplate.selected = false; //sets selected to false for previously selected template
    await updateTemplateSelection(
      user.auth0UserId,
      selectedTemplate.templateName,
      selectedTemplate.selected
    );

    let currentSelected = templates.find((t) => t.templateName === val);
    currentSelected.selected = true;
    await updateTemplateSelection(
      user.auth0UserId,
      currentSelected.templateName,
      currentSelected.selected
    );
    setTemplates(templates);
    setTemplateState(currentSelected);
    setView("");
  }

  function setTemplateState(currentSelected) {
    currentSelected.templateName === "Default"
      ? setDisabled(true)
      : setDisabled(false);
    currentSelected.fields.find((w) => NEW_WIDGETS.includes(w.section))
      ? setShowNewWidget(false)
      : setShowNewWidget(true);

    setSelectedTemplate(currentSelected);
  }

  function setTemplateView(view) {
    setView(view);
  }

  return (
    <div>
      {view === "saving" ? (
        <CustomSpinner>Loading Template</CustomSpinner>
      ) : view === "createView" ? (
        <FreelancerProfileViewCreate
          onClose={setTemplateView}
          template={defaultTemplate}
          flData={data}
          setSelectedTemplate={setTemplateState}
        />
      ) : view === "updateView" ? (
        <FreelancerProfileViewUpdate
          template={selectedTemplate}
          newWidgets={newWidgets}
          onClose={setTemplateView}
          flData={data}
          setSelectedTemplate={setTemplateState}
        />
      ) : (
        <Box>
          <Grid container className="mb-2 mt-1">
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel></InputLabel>
                <Select
                  value={selectedTemplate.templateName}
                  onChange={handleChange}
                >
                  {templateNames.map((name, index) => {
                    return (
                      <MenuItem value={name} id={index}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {!disabled && (
                <Tooltip title="Edit profile template">
                  <Button
                    className="mt-4 ml-2"
                    onClick={() => {
                      setTemplateView("updateView");
                    }}
                  >
                    <EditIcon /> Edit Template
                  </Button>
                </Tooltip>
              )}
              {disabled && (
                <Tooltip title="Cannot edit Default template">
                  <Widgets
                    className={classes.disableIcon}
                    onClick={() => {
                      return;
                    }}
                  />
                </Tooltip>
              )}
            </Grid>
            <Grid item container xs={6} justify="flex-end">
              <Button
                className="mt-2"
                onClick={() => {
                  setTemplateView("createView");
                }}
              >
                + Create Template
              </Button>
            </Grid>
          </Grid>
          {showNewWidget && <NewWidgetGrid />}
          <Box p={1}>
            {selectedTemplate.fields.map((f) => {
              if (f.section.toLowerCase() === "about" && f.checked) {
                return <AboutFreelancerCard freelancer={data} />;
              } else if (
                f.section.toLowerCase() === "work and preferences" &&
                f.checked &&
                data.workPreference
              ) {
                return <WorkLoadPreferenceCard freelancer={data} />;
              } /* else if (
                f.section.toLowerCase() === "specialities" &&
                f.checked
              ) {
                return (
                  <SpecialitiesCard
                    skills={data.personalSummary?.topFinancialSkills}
                    industries={data.personalSummary?.topIndustries}
                    software={data.personalSummary?.topSoftwareSkills}
                  />
                );
              } */ else if (
                f.section.toLowerCase() === "projects" &&
                f.checked &&
                (data.externalProjects || data.paroProjects)
              ) {
                return (
                  <ProjectsTemplateCard
                    externalProjects={data.externalProjects}
                    paroProjects={data.paroProjects}
                  />
                );
              } else if (
                f.section.toLowerCase() === "work experience" &&
                f.checked &&
                data.workExperiences
              ) {
                return (
                  <EmploymentCard workExperiences={data.workExperiences} />
                );
              } else if (
                f.section.toLowerCase() === "education" &&
                f.checked &&
                data.education
              ) {
                return <EducationCard education={data.education} />;
              } else if (
                f.section.toLowerCase() === "accomplishments" &&
                f.checked &&
                data.certificates
              ) {
                return <CertificatesCard certificates={data.certificates} />;
              } else {
                return null;
              }
            })}
          </Box>
        </Box>
      )}
    </div>
  );
}
