import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const LineBreakTypography /* : typeof Typography */ = styled(Typography)`
  white-space: pre-line;
`;

export const LineBreakSpan = styled.span`
  white-space: pre-line;
`;
