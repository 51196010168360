import React from "react";
import MultiSelectField from "./MultiSelectField";
export default function SelectIndustry({ options, ...field }) {
  return (
    <MultiSelectField
      {...field}
      creatable
      options={[`All`, ...[...options].sort()]}
    />
  );
}
