const Page = ({ children, isSelected, onClick }) => {
  if (isSelected) {
    return (
      <div
        aria-current="page"
        className="z-10 bg-gray-900 border-gray-900 text-white relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
      >
        {children}
      </div>
    );
  } else {
    return (
      <div
        onClick={onClick}
        className="border-gray-300 bg-gray-100 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
      >
        {children}
      </div>
    );
  }
};

const PageDropdown = ({ total, perPageItems, onChange }) => {
  const onChangeHandle = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="text-sm">
      <span className="pr-4">Results Per Page</span>
      <select
        className="form-select p-2 bg-gray-100"
        onChange={onChangeHandle}
        value={perPageItems}
      >
        <option value={10}>10</option>
        {total > 10 && <option value={20}>20</option>}
        {total > 50 && <option value={50}>50</option>}
      </select>
    </div>
  );
};

const LeftArrow = () => {
  return (
    <svg
      className="h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const RightArrow = () => {
  return (
    <svg
      className="h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const LeftDoubleArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
      />
    </svg>
  );
};

const RightDoubleArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 5l7 7-7 7M5 5l7 7-7 7"
      />
    </svg>
  );
};

const Pagination = ({
  total,
  currentPage = 1,
  setCurrentPage,
  perPageItems = 10,
  setPerPageItems,
}) => {
  const onPageChange = (pageVal) => {
    setPerPageItems(parseInt(pageVal));
    setCurrentPage(1);
  };

  const numberOfPages = Math.ceil(total / perPageItems);

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <div
          aria-disabled={currentPage === 1}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </div>
        <div
          aria-disabled={currentPage === numberOfPages}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </div>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center">
        <PageDropdown
          total={total}
          perPageItems={perPageItems}
          onChange={onPageChange}
        />
        <div className="ml-4">
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <Page
              onClick={() => {
                currentPage > 1 && setCurrentPage(1);
              }}
              isSelected={false}
            >
              <LeftDoubleArrow />
            </Page>
            <Page
              onClick={() => {
                currentPage > 1 && setCurrentPage(currentPage - 1);
              }}
              isSelected={false}
            >
              <LeftArrow />
            </Page>
            {[...Array(numberOfPages).keys()].map((key) => {
              const pageId = key + 1;
              return (
                <Page
                  key={key}
                  isSelected={currentPage === pageId}
                  onClick={() => {
                    setCurrentPage(pageId);
                  }}
                >
                  {pageId}
                </Page>
              );
            })}
            <Page
              onClick={() => {
                currentPage < numberOfPages && setCurrentPage(currentPage + 1);
              }}
              isSelected={false}
            >
              <RightArrow />
            </Page>
            <Page
              onClick={() => {
                currentPage < numberOfPages && setCurrentPage(numberOfPages);
              }}
              isSelected={false}
            >
              <RightDoubleArrow />
            </Page>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
