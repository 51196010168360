import React from "react";

import { Avatar } from "@paro.io/base-ui";
import { IconCheck, IconDocumentText } from "@paro.io/base-icons";

import ProjectInfoScoreCard from "./ProjectInfoScoreCard";
import FreelancerScoreCard from "./FreelancerScoreCard";

import { paroProjectsStandardization } from "./legacy/shared/displayConversions";

export default function FreelancerScorePage({ freelancer, proposal }) {
  return (
    <>
      <div className="w-full px-5 py-5">
        <div className="flex ">
          <div className=" w-full px-5">
            <div className="bg-white flex flex-row">
              <div className=" w-1/3 px-5 py-5">
                <div className="flex items-center h-full justify-center">
                  <div className="mx-2">
                    <Avatar
                      src={freelancer.imageUrl}
                      alt=""
                      width="100%"
                      size="md"
                    />
                  </div>
                  <div className="ml-3">
                    <h4 className="text-gray-900 font-bold">
                      {freelancer.FL_Name}
                    </h4>
                    <p className="text-gray-900">{freelancer.FL_Service}</p>
                  </div>
                </div>
              </div>
              <div className="w-1/3 px-5 py-5">
                <div className="bg-white text-center items-center h-full justify-center">
                  <IconCheck className=" mx-auto score-number" />
                  <h3 className="text-3xl font-bold score-number">
                    {Math.round(freelancer.matchPercent)} / 100
                  </h3>
                  <h4 className="text-sm color-text">MATCH SCORE</h4>
                </div>
              </div>
              <div className="w-1/3 px-5 py-5">
                <div className="bg-white flex items-center h-full justify-center">
                  <div className="mx-2">
                    <span className="flex space-x-2 my-2 font-normal text-base rounded-full bg-gray-400 w-18 h-18 p-4">
                      <IconDocumentText className="m-auto w-8 h-8 text-gray-500" />
                    </span>
                  </div>
                  <div className="mx-2">
                    <span className="flex space-x-2 my-2 font-normal text-base">
                      <span>{proposal.client.name}</span>
                    </span>
                    <span className="flex space-x-2 my-2 font-normal text-base">
                      <span>{proposal.name}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className=" w-1/3 px-5 py-5">
            <FreelancerScoreCard freelancer={freelancer} />
          </div>
          <div className=" w-2/3 px-5 py-5">
            <ProjectInfoScoreCard
              projects={[
                ...freelancer.externalProjects,
                ...paroProjectsStandardization(freelancer.paroProjects),
              ]}
              proposal={proposal}
              freelancer={freelancer}
            />
          </div>
        </div>
      </div>
    </>
  );
}
