import React from "react";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import { Checkbox } from "@material-ui/core";

export default function ToggleJobBoard(props) {
  const { onJobBoard, setOnJobBoard, disabled } = props;

  // return (
  //   <div
  //     className={`p-2 cursor-pointer`}
  //     onClick={() => setOnJobBoard(!onJobBoard)}
  //   >
  //     {onJobBoard ? <VisibilityIcon /> : <VisibilityOffIcon />}
  //   </div>
  // );

  return (
    <>
      <Checkbox
        id="mm-checkout-postJobBoard"
        onClick={() => setOnJobBoard(!onJobBoard)}
        // defaultChecked={[
        //   OPPORTUNITY_TYPES.NewBusiness,
        //   OPPORTUNITY_TYPES.CrossSell,
        // ].includes(opportunityTypeMM)}
        checked={onJobBoard}
        disabled={disabled}
      />
      Post To Opportunity Board
    </>
  );
}
