import React from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles, TextField, FormLabel } from "@material-ui/core";
import SelectSoftware from "./SelectSoftware";
import SelectService from "./SelectService";
import SelectIndustry from "./SelectIndustry";
import SelectSkills from "./SelectSkills";
import SelectEffort from "./SelectEffort";
import SelectField from "./SelectField";
import TrixTextEditor from "./TrixTextEditor";
import { updateProposal } from "../services/apiService";
import { useSnackbar } from "notistack";
import { Heading, Text } from "@paro.io/base-ui";
import { Button as BaseUiButton } from "@paro.io/base-ui";
import { getKeyByValue, OPPORTUNITY_TYPES } from "../data/appConstants";
import InfoButton from "./InfoButton";
import { useTags } from "../contexts/useTags";
import Gpt3Component from "./Gpt3Component";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  textArea: {
    minWidth: "100%",
  },
}));

const billingValues = {
  HOURLY: "Hourly",
  FIXED: "Fixed",
};

const occurenceValues = {
  ONETIME: "One-Time",
  RECURRING: "Recurring Monthly",
};

export default function UpdateProposalForm({
  defaultValues,
  updateProposalFn,
}) {
  const [notes, setNotes] = useState(defaultValues.notes);
  const { handleSubmit, control, setValue, register } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    effortOptions,
    serviceOptions,
    softwareOptions,
    industryOptions,
    skillOptions,
  } = useTags();

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      billRateType: getKeyByValue(billingValues, data.billRateType),
      frequency: getKeyByValue(
        occurenceValues,
        data.frequency === "Recurring monthly" ? "RECURRING" : data.frequency
      ),
    };

    const industryTags = formData.industries.map((i) => ({
      field: "industries",
      value: i.value || i.inputValue,
      required: i.required,
    }));
    const softwareTags = formData.softwares.map((i) => ({
      field: "softwares",
      value: i.value || i.inputValue,
      required: i.required,
    }));
    const skillTags = formData.skills.map((i) => ({
      field: "skills",
      value: i.value || i.inputValue,
      required: i.required,
    }));
    const tagsData = [...industryTags, ...softwareTags, ...skillTags];

    const requestData = {
      name: formData.name,
      primaryServiceLine: formData.serviceLine,
      description: formData.description,
      notes: formData.notes,
      projectSize: formData.projectSize,
      opportunityTypeMM: formData.opportunityTypeMM,
      pitchNotes: formData.pitchNotes,
      matchmakingQuery: {
        tags: tagsData,
        rateRange: {
          min: 0,
          max: parseInt(formData.clientRate),
        },
      },
      billRateType: formData.billRateType,
      frequency: formData.frequency,
    };

    const { data: responseData } = await updateProposal(
      defaultValues.id,
      requestData
    );

    // error
    if (
      responseData.errors &&
      responseData.errors[0].extensions?.code === "400"
    ) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      return;
    }

    await updateProposalFn();
  };
  const classes = useStyles();

  const opportunityInformation =
    "Opportunity type is an exception criteria and should only be used by authorized users. Authorized users to meet special conditions. All exception conditions will be reported to management.";
  return (
    <div className="flex flex-row p-8 rounded-md">
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
        className="w-3/4"
      >
        {
          <>
            <div className="grid grid-cols-1">
              <Heading size="h4">{defaultValues.client?.name}</Heading>
            </div>
            <div className="grid grid-cols-1">
              <Heading size="h5">PROPOSAL ATTRIBUTES</Heading>
            </div>
            <div className="grid grid-cols-3 grid-flow-col gap-y-2 gap-x-2 my-3">
              <Controller
                fullWidth
                name="opportunityTypeMM"
                rules={{ required: "Opportunity Type required" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <FormLabel className="my-2" component="legend">
                      <div className="flex">
                        <Text size="md">Opportunity Type</Text>
                        <InfoButton information={opportunityInformation} />
                      </div>
                    </FormLabel>
                    <span id="mm-editProposal-opportunityType">
                      <SelectField
                        {...field}
                        options={Object.values(OPPORTUNITY_TYPES)}
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    </span>
                  </>
                )}
                control={control}
              />
            </div>
            <div className="grid grid-cols-1">
              <Heading size="h5">GENERAL INFORMATION</Heading>
            </div>
            <div className="pt-2">
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <FormLabel className="my-2" component="legend">
                      Proposal Title*
                    </FormLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  </>
                )}
                rules={{ required: "Porposal Title required" }}
              />
            </div>
            <div className="pt-2">
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <FormLabel className="my-2" component="legend">
                      Proposal Description*
                    </FormLabel>
                    <TextField
                      multiline
                      className={classes.textArea}
                      variant="outlined"
                      placeholder="Provide a Porposal Description"
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  </>
                )}
                rules={{ required: "Porposal Description required" }}
              />
            </div>
            <div className="grid grid-cols-3 grid-flow-col gap-y-2 gap-x-2 my-3">
              <div>
                <Controller
                  name="clientRate"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <FormLabel className="my-2" component="legend">
                        Client Rate ($)*
                      </FormLabel>
                      <TextField
                        fullWidth
                        id="clientRate"
                        type="number"
                        variant="outlined"
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </>
                  )}
                  rules={{ required: "Client Rate required" }}
                />
              </div>
              <div>
                <Controller
                  name="serviceLine"
                  rules={{ required: "Service required" }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <FormLabel className="my-2" component="legend">
                        Service Line*
                      </FormLabel>
                      <SelectService
                        fullWidth
                        {...field}
                        options={serviceOptions}
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    </>
                  )}
                  control={control}
                />
              </div>
            </div>
            <div className="grid grid-cols-3 grid-flow-col gap-y-2 gap-x-2 my-6">
              <div>
                <Controller
                  fullWidth
                  name="projectSize"
                  rules={{ required: "Effort required" }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <FormLabel className="my-2" component="legend">
                        Effort*
                      </FormLabel>
                      <SelectEffort
                        {...field}
                        options={effortOptions}
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    </>
                  )}
                  control={control}
                />
              </div>
              <div>
                <Controller
                  fullWidth
                  name="billRateType"
                  rules={{ required: "Hours Billed required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <FormLabel className="my-2" component="legend">
                        Hours*
                      </FormLabel>
                      <SelectField
                        value={billingValues[value]}
                        onChange={onChange}
                        error={error}
                        options={Object.values(billingValues)}
                      />
                    </>
                  )}
                  control={control}
                />
              </div>
              <div>
                <Controller
                  fullWidth
                  name="frequency"
                  rules={{ required: "Occurence required" }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <FormLabel className="my-2" component="legend">
                        Occurence*
                      </FormLabel>
                      <SelectField
                        {...field}
                        error={error}
                        options={Object.values(occurenceValues)}
                      />
                    </>
                  )}
                  control={control}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 ">
              <Heading size="h5">PROPOSAL TAGS</Heading>
            </div>
            <div className="grid grid-cols-1 ">
              <Controller
                name="industries"
                fullWidth
                rules={{ required: "Industry required" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <FormLabel className="my-2" component="legend">
                      Industry*
                    </FormLabel>
                    <span id="mm-editProposal-industry">
                      <SelectIndustry
                        {...field}
                        options={industryOptions}
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    </span>
                  </>
                )}
                control={control}
              />
            </div>
            <div className="grid grid-cols-1 my-4">
              <Controller
                name="softwares"
                fullWidth
                rules={{ required: "Software required" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <FormLabel className="my-2" component="legend">
                      Software*
                    </FormLabel>
                    <span id="mm-editProposal-software">
                      <SelectSoftware
                        {...field}
                        options={softwareOptions}
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    </span>
                  </>
                )}
                control={control}
              />
            </div>
            <div className="grid grid-cols-1 my-4">
              <Controller
                name="skills"
                fullWidth
                render={({ field }) => (
                  <>
                    <FormLabel className="my-2" component="legend">
                      Skills
                    </FormLabel>
                    <span id="mm-editProposal-skill">
                      <SelectSkills {...field} options={skillOptions} />
                    </span>
                  </>
                )}
                control={control}
              />
            </div>
            <div className="grid grid-cols-1">
              <Heading size="h5">NOTES FROM CALL</Heading>
            </div>
            <div className="grid grid-cols-1">
              <Controller
                control={control}
                name="notes"
                value={notes}
                render={({ field: { name, value }, fieldState: { error } }) => {
                  return (
                    <span id="mm-editProposal-notes">
                      <TrixTextEditor
                        value={value}
                        label="Notes"
                        name={name}
                        {...register("notes")}
                        onChange={(text, html) => {
                          setNotes(html);
                          setValue(text, html);
                        }}
                        error={error ? error.message : null}
                        placeholder="Add a Note"
                      />
                    </span>
                  );
                }}
              />
            </div>
            <div className="grid grid-cols-1">
              <Heading size="h5">PITCH NOTES</Heading>
            </div>
            <div className="grid grid-cols-1">
              <Controller
                control={control}
                name="pitchNotes"
                render={({ field: { name, value }, fieldState: { error } }) => {
                  return (
                    <span id="mm-editProposal-pitch-notes">
                      <TrixTextEditor
                        value={value}
                        label="Pitch Notes"
                        name={name}
                        onChange={setValue}
                        error={error ? error.message : null}
                        placeholder="Add recommendations that can help experts when writing their pitch"
                      />
                    </span>
                  );
                }}
              />
            </div>
            <div className="grid grid-cols-4 my-3">
              <span id="mm-editProposal-save">
                <BaseUiButton label={`Save`} type="submit" />
              </span>
            </div>
          </>
        }
      </form>
      <div className="w-1/4 pl-8">
        <Gpt3Component
          defaultValues={defaultValues}
          notes={notes}
          setValue={setValue}
        />
      </div>
    </div>
  );
}
