import { Checkbox, makeStyles } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dragHandle: {
    cursor: "move",
    height: "100%",
    paddingTop: 9,
  },
}));
export default function AccordionHeader(props) {
  const classes = useStyles();
  const { dragIndicator, checkbox, widget, handleChange, title } = props;

  return (
    <>
      {dragIndicator && (
        <DragIndicator
          onClick={(event) => event.stopPropagation()}
          className={`handle ${classes.dragHandle}`}
        />
      )}
      {checkbox && (
        <Checkbox
          color="primary"
          checked={widget.checked}
          onClick={(event) => {
            event.stopPropagation();
            handleChange(event, widget);
          }}
          onFocus={(event) => event.stopPropagation()}
        />
      )}
      <h4 className="mt-2">{title}</h4>
    </>
  );
}
