import sortBy from "lodash/sortBy";

import { PROJECT_FREQUENCY_RECURRING } from "./../MinimalProjectInformation";

export const currencyConverter = (number /* : number */) /* : string */ => {
  return "$" + number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const paroIncomeStatusEnumToDisplayMap /* : { [key: string]: string } */ =
  {
    PRIMARY: "Primary source of income",
    SECONDARY_ADDITIONAL: "Secondary source of income",
    PART_TIME: "Part time",
  };

export const projectTypeEnumToDisplayMap /* : { [key: string]: string } */ = {
  ONE_TIME: "One time",
  RECURRING: "Recurring",
  BOTH: "Both one-time and recurring",
};

export const convertBooleanToString = (
  boolean /* : boolean | undefined */
) /* : string */ => {
  return boolean ? "Yes" : "No";
};

const replaceUnderscoresWithSpaces = (
  enumValue /* : string */
) /* : string */ => {
  return enumValue.replace(/_/g, " ");
};
const replaceToWithDash = (enumValue /* : string */) /* : string */ => {
  return enumValue.replace(" to ", "-");
};
const capitalizeFirstCharacter = (enumValue /* : string */) /* : string */ => {
  return enumValue.charAt(0).toUpperCase() + enumValue.slice(1);
};

export const convertDateStringToDate = (
  dateString /* ?: string | null */
) /* : Date | string */ => {
  return dateString ? new Date(dateString) : "";
};

export const convertEnumToDisplayFormat = (
  enumValue /* ?: string | null */,
  shouldCapitalizeFirstCharacter /* ?: boolean */
) /* : string | null */ => {
  const specialCaseEnums /* : { [key: string]: string }  */ = {
    saas: "saaS",
    e_commerce: "e-commerce",
    c_corp: "c-corp",
    s_corp: "s-corp",
    non_profit: "non-profit",
    non_profits_government_funded: "non-profits/government_funded",
    utilities_natural_resources: "utilities/natural_resources",
  };
  if (enumValue === undefined || enumValue === null) {
    return null;
  }
  enumValue = enumValue.toLowerCase();

  if (enumValue in specialCaseEnums) {
    enumValue = specialCaseEnums[enumValue];
  }
  enumValue = replaceUnderscoresWithSpaces(enumValue);

  if (shouldCapitalizeFirstCharacter || enumValue === "unknown") {
    enumValue = capitalizeFirstCharacter(enumValue);
  } else {
    enumValue = replaceToWithDash(enumValue);
  }
  return enumValue;
};

const paroProjectOnsiteMap = {
  1: "LOCAL",
  2: "TRAVELED",
  3: "REMOTE",
};
const paroProjectSizeToExternalProjectSizeMap = {
  "Small one-time (<10hrs)": "UNDER_10_HOURS",
  "Med one-time (10-40hrs)": "10_TO_35_HOURS",
  "Large one-time (40+ hrs)": "OVER_35_HOURS",
  "Small recurring (<10hrs  monthly)": "UNDER_10_HOURS",
  "Med recurring (10-35hrs monthly)": "10_TO_40_HOURS",
  "Large recurring (35+ hrs monthly)": "OVER_40_HOURS",
};

const convertProjectSizeToHours = (projectSize) /* : string | null */ => {
  if (!projectSize) {
    return projectSize;
  }
  return paroProjectSizeToExternalProjectSizeMap[projectSize];
};

/* export type sortableEntities =  Partial<Project> | Partial<WorkExperience> | Partial<Education> |
{id?: string | number | undefined
  companyRevenueModel?: CompanyRevenue | string | undefined
    companyType?: CompanyType | string | null | undefined
  } &  Omit<Partial<ExternalProject>, 'id' | 'companyRevenueModel' | 'companyType'> */
/**
 * Sort the projects by their endDate DESC, and if their endDates are the same
 * or both null, by the startDate. Null dates are always considered first
 */
const sortDatesDesc = (
  dateOne /* : string | null */,
  dateTwo /* : string | null */
) /* : number */ => {
  if (dateOne === null) {
    return 1;
  } else if (dateTwo === null) {
    return -1;
  }
  const bothEndDatesAreTheSame =
    new Date(dateTwo || "").toDateString() ===
    new Date(dateOne || "").toDateString();
  if (bothEndDatesAreTheSame) {
    return 1;
  }
  return new Date(dateTwo) < new Date(dateOne) ? 1 : -1;
};

export const sortByEndAndStartDates = (
  paroProjects /* : sortableEntities[] | [] */
) /* : sortableEntities[] | [] */ => {
  paroProjects = sortBy(paroProjects, ["id"]);
  return paroProjects.sort(
    (
      projectOne /* : sortableEntities */,
      projectTwo /* : sortableEntities */
    ) => {
      const projectOneEndDateIsNull = projectOne.endDate === null;
      const projectOneStartDateIsNull = projectOne.startDate === null;
      const projectTwoEndDateIsNull = projectTwo.endDate === null;
      const projectTwoStartDateIsNull = projectTwo.startDate === null;
      const bothEndDatesAreNull =
        projectOneEndDateIsNull && projectTwoEndDateIsNull;
      const bothEndDatesAreTheSame =
        new Date(projectOne.endDate || "").toDateString() ===
        new Date(projectTwo.endDate || "").toDateString();
      const allProjectDatesAreNull =
        projectOneStartDateIsNull &&
        projectOneEndDateIsNull &&
        projectTwoStartDateIsNull &&
        projectTwoEndDateIsNull;

      if (allProjectDatesAreNull) {
        return -1;
      }
      if (bothEndDatesAreNull || bothEndDatesAreTheSame) {
        return sortDatesDesc(projectTwo.startDate, projectOne.startDate);
      }
      return sortDatesDesc(projectTwo.endDate, projectOne.endDate);
    }
  );
};

/* export type StandardizedParoProject = {
  companyRevenueModel: string | undefined
  companyType: string | null
  id: number | undefined
} & Omit<ExternalProject, 'companyRevenueModel' | 'projectSize' | 'companyType' | 'id'>
 */

const getTagsValues = (tags /* : Project['financialTags'] */) => {
  const tagValues = tags?.map((tag) => {
    return tag.value;
  });
  return tagValues?.filter(Boolean) || [];
};
export const paroProjectsStandardization = (
  paroProjects /* : Partial<Project>[] */
) /* : StandardizedParoProject[] */ => {
  const sortedParoProjects = sortByEndAndStartDates(paroProjects);
  /*as [] here for this bug:
   https://github.com/microsoft/TypeScript/issues/36390 */
  return sortedParoProjects /* as [] */
    .map(
      (
        {
          endDate,
          description,
          startDate,
          financialTags,
          softwareTags,
          frequency,
          name,
          onsite,
          size,
          client,
          id,
        } /* : Partial<Project> */
      ) => {
        const industryTags = client?.industryTags
          ? getTagsValues([client?.industryTags])
          : [];
        return {
          endDate: endDate,
          description: description || "",
          startDate: startDate,
          fundingSources: client?.fundingSources || [],
          financialTags: financialTags ? getTagsValues(financialTags) : [],
          softwareTags: softwareTags ? getTagsValues(softwareTags) : [],
          industryTags: industryTags,
          hoursMonthly:
            frequency === PROJECT_FREQUENCY_RECURRING
              ? PROJECT_FREQUENCY_RECURRING
              : null,
          hoursTotal:
            frequency === PROJECT_FREQUENCY_RECURRING
              ? null
              : convertProjectSizeToHours(size?.value),
          isInternational: !!client?.international,
          companyRevenueModel: client?.businessModel,
          isRecurring: frequency === PROJECT_FREQUENCY_RECURRING,
          title: name || "",
          annualCompanyRevenueRange: client?.annualRevenueRange
            ? client?.annualRevenueRange.value
            : null,
          companySize: client?.companySize ? client?.companySize.value : null,
          companyType: client?.entityType ? client?.entityType.value : null,
          onsite: onsite ? paroProjectOnsiteMap[onsite] : null,
          id: id,
        };
      }
    );
};
export const getSingleIndustryTag = (
  industryTags /* ?: string[] */,
  thisIsAParoProject /* ?: boolean */
) /* : string */ => {
  return industryTags && industryTags?.length > 0
    ? capitalizeFirstCharacter(industryTags[0])
    : thisIsAParoProject
    ? ""
    : "Missing industry tag";
};
