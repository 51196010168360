import AboutFreelancerCard from "./AboutFreelancerCard";
import CertificatesCard from "./CertificatesCard";
import EducationCard from "./EducationCard";
import EmploymentCard from "./EmploymentCard";
import ProjectsTemplateCard from "./ProjectsTemplateCard";
import WorkLoadPreferenceCard from "./WorkLoadPreferenceCard";

export default function FreelancerFullProfileReadOnly(props) {
  const { data } = props;

  return (
    <div className="p-1">
      <AboutFreelancerCard freelancer={data} />

      <WorkLoadPreferenceCard freelancer={data} />

      <ProjectsTemplateCard
        externalProjects={data.externalProjects}
        paroProjects={data.paroProjects}
      />

      <EmploymentCard workExperiences={data.workExperiences} />

      <EducationCard education={data.education} />

      <CertificatesCard certificates={data.certificates} />
    </div>
  );
}
