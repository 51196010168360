import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useAuth0 } from "../contexts/Auth0Context";
import { sectionRecommendation } from "../services/apiService";

const useStyles = makeStyles((theme) => ({
  boldFont: {
    fontWeight: 500,
  },
}));

export default function WidgetSuggestion() {
  const classes = useStyles();
  const { user } = useAuth0();

  const { register, handleSubmit, reset } = useForm();
  const onSendSuggestion = async (data) => {
    reset();
    const section = {
      userId: user.auth0UserId,
      userName: user.nickname,
      ...data,
    };
    sectionRecommendation(section);
  };
  return (
    <div>
      <Typography className={`mb-2 ${classes.boldFont}`}>
        Can't find a section with the information you are looking for? Suggest
        adding a section below:
      </Typography>
      <Paper>
        <form
          onSubmit={handleSubmit(onSendSuggestion)}
          noValidate
          autoComplete="off"
        >
          <Grid container className="p-4 mb-2">
            <Grid item xs={12} className="mb-4">
              <Typography>How would you name the new section?</Typography>
              <TextField
                name="sectionName"
                fullWidth
                placeholder="Enter proposed section name"
                size="small"
                variant="outlined"
                {...register("sectionName")}
              ></TextField>
            </Grid>
            <Grid item xs={12} className="mb-4">
              <Typography>
                What information should the new section show?
              </Typography>
              <TextField
                name="description"
                fullWidth
                placeholder="Describe section content"
                size="small"
                multiline
                rows={3}
                variant="outlined"
                {...register("description")}
              ></TextField>
            </Grid>
            <Grid item xs={12} className="mb-4">
              <Typography>
                How would the new section help you achieve your goals?
              </Typography>
              <TextField
                name="sectionGoals"
                fullWidth
                placeholder="Describe your goals"
                size="small"
                multiline
                rows={3}
                variant="outlined"
                {...register("sectionGoals")}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" type="submit">
                SEND SUGGESTION
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
}
