import React, { createContext, useState } from "react";

export const CartContext = createContext();

export const CartProvider = (props) => {
  const [cart, setCart] = useState([]);
  const [proposal, setProposal] = useState([]);
  const [matchId, setMatchId] = useState(null);
  const [freelancers, setFreelancers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [iterations, setIterations] = useState(0);
  const [matchIds, setMatchIds] = useState([]);
  const [compareFls, setCompareFls] = useState([]);

  return (
    <CartContext.Provider
      value={{
        cartContext: [cart, setCart],
        proposalContext: [proposal, setProposal],
        matchContext: [matchId, setMatchId],
        freelancerContext: [freelancers, setFreelancers],
        flViewContext: [templates, setTemplates],
        iterationsContext: [iterations, setIterations],
        globalMatchCartContext: [matchIds, setMatchIds],
        compareFlsContext: [compareFls, setCompareFls],
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};
