import React, { useContext, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Paper,
  Tooltip,
  Card,
} from "@material-ui/core";
import PropTypes from "prop-types";

import NotesCard from "./NotesCard";
import { formatDateAsMDY } from "../utils/dateService";
import ProfileCard from "./ProfileCard";
import { grey } from "@material-ui/core/colors";
import FreelancerFullProfileReadOnly from "./FreelancerFullProfileReadOnly";

import { Auth0Context } from "../contexts/Auth0Context";
import Availability from "./legacy/Availability";

const useStyles = makeStyles((theme) => ({
  profileCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  notesPaper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  addDiv: { position: "relative", display: "flex" },
  addedIndicator: {
    position: "absolute",
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    color: grey[900],
    backgroundColor: "#6eedbf",
    padding: theme.spacing(0.5, 1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FreelancerCardById({
  data,
  //   proposalNotes,
  //   proposalId,
  //   tabIndex,
}) {
  const classes = useStyles();

  const { user } = useContext(Auth0Context);
  console.log(user.auth0UserId);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const noteWithStage = data.Vetting_Notes.filter((note) => {
    return !!note.stageId;
  });
  const notesExistButNoneWithStage =
    data.Vetting_Notes.length > 1 && noteWithStage.length === 0;
  return (
    <>
      <Box className="mx-5">
        <Grid container>
          <Grid item xs={12}>
            <Card className={classes.profileCard}>
              <Grid container>
                <Grid item xs={9}>
                  <ProfileCard
                    name={data.FL_Name}
                    imageUrl={data.imageUrl}
                    title={data.FL_Service}
                    tagline={data.FL_Tagline}
                    location={data.address?.city + ", " + data.address?.region}
                    // rating={4}
                    // joined={data.workExperiences[0].startDate}
                    defaultHourlyRate={data.legacyMetadata.defaultHourlyRate}
                    clientHourlyRate={data.legacyMetadata.hourlyRate}
                    goalHours={data.legacyMetadata.monthlyHoursAvailable}
                    availableHours={data.availability.availableHours}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                className="bg-white"
                onChange={handleChange}
                aria-label="Freelancer detailed information"
              >
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Availability" {...a11yProps(1)} />
                <Tab label="Notes" {...a11yProps(2)} />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
              <FreelancerFullProfileReadOnly data={data} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Availability
                // legacyFreelancerId={data.legacyFreelancerId}
                monthlyHoursAvailable={
                  data.legacyMetadata.monthlyHoursAvailable
                }
                availabilityCalculations={data.availability}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Paper className={classes.notesPaper}>
                <Grid container justify="flex-end">
                  <Tooltip
                    title={`In order to "Add a new note" open the Paro FL Profile notes view`}
                    aria-label="add new note"
                  >
                    <Typography
                      color="primary"
                      className="cursor-pointer"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_PARO_APP_URL}/internal/expert-profile/${data.flID}`,
                          "_blank"
                        )
                      }
                    >
                      + Add Notes in Platform
                    </Typography>
                  </Tooltip>
                </Grid>
                {notesExistButNoneWithStage && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      Stage: Legacy
                    </Grid>
                  </Grid>
                )}
                {data.Vetting_Notes.map((noteObj) => (
                  <NotesCard
                    time={`${formatDateAsMDY(noteObj.timestamp)}`}
                    amName={noteObj.accountManager.name}
                    notes={noteObj.note}
                    key={noteObj.id}
                    stageId={noteObj.stageId}
                    noteWithStage={noteWithStage}
                  />
                ))}
              </Paper>
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
