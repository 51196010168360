import { Button } from "@material-ui/core";
import styled from "styled-components";

import colors from "../shared/constants/colors";

export const HeaderButtons /* : typeof Button */ = styled(Button)`
  margin-left: 0px !important;
  padding-left: 0px !important;
  span {
    color: ${colors.buttonText} !important;
  }
`;

export const HeaderButtonUnderline /* : typeof Button */ = styled(
  HeaderButtons
)`
  text-decoration: underline;
`;
export const StyledAddButton /* : typeof Button */ = styled(Button)`
  margin-right: 0px !important;
  padding-right: 0px !important;
  span {
    color: ${colors.buttonText} !important;
  }
`;

export const StyledButton /* : typeof Button */ = styled(Button)`
  margin: 0px !important;
  padding: 26px 0 18px 0 !important;
  span {
    color: ${colors.buttonText} !important;
  }
`;
