import React from "react";
import SelectField from "./SelectField";

export default function SelectEffort({ options, ...field }) {
  return (
    <SelectField
      {...field}
      options={[
        "Unknown",
        ...options.filter((o) => o !== "51+"),
        "51-75hrs",
        "76-100hrs",
        "101-150hrs",
        "151-160hrs",
        "161+hrs",
      ]}
    />
  );
}
