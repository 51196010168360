import { useState, useEffect } from "react";
import { getProposalEfforts, getProposalTags } from "../services/apiService";

export const useTags = () => {
  const [effortOptions, setEffortOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [softwareOptions, setSoftwareOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  useEffect(() => {
    const promiseOptions = async () => {
      const responses = await Promise.all([
        getProposalEfforts(),
        getProposalTags(),
      ]);
      setServiceOptions([...responses[0].data.data.getServiceLines]);
      setEffortOptions([...responses[0].data.data.getProjectSizes]);
      setSoftwareOptions([
        ...new Set([...responses[1].data.data.getTags.software.values]),
      ]);
      setIndustryOptions([
        ...new Set([...responses[1].data.data.getTags.industries.values]),
      ]);
      setSkillOptions([
        ...new Set([...responses[1].data.data.getTags.skills.values]),
      ]);
    };
    promiseOptions();
    // reset(defaultValues);
  }, []);

  return {
    effortOptions,
    serviceOptions,
    softwareOptions,
    industryOptions,
    skillOptions,
  };
};
