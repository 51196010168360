import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import AccordionHeader from "./AccordionHeader";
import ProjectsSection from "./legacy/ProjectsSection";
import { paroProjectsStandardization } from "./legacy/shared/displayConversions";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    padding: theme.spacing(1),
  },
  accordionDetailsRoot: {
    "&.MuiAccordionDetails-root": {
      display: "block",
    },
  },
}));

export default function ProjectsTemplateCard({
  externalProjects = [],
  paroProjects = [],
  widget,
  handleChange,
  dragIndicator,
  checkbox,
}) {
  const classes = useStyles();

  return (
    <>
      <Accordion
        className={`mt-3 mb-2 ${classes.accordionRoot}`}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMore />} aria-label="Expand">
          <AccordionHeader
            title="PROJECTS"
            widget={widget}
            handleChange={handleChange}
            dragIndicator={dragIndicator}
            checkbox={checkbox}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsRoot}>
          <ProjectsSection
            projects={[
              ...externalProjects,
              ...paroProjectsStandardization(paroProjects),
            ]}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
