import React from "react";
import { Modal } from "react-bootstrap";
import { Heading, Card, Button as BaseUiButton } from "@paro.io/base-ui";

import CloseIcon from "@material-ui/icons/Close";

export default function ConfirmationModal({
  show,
  setShow,
  title,
  body,
  okButtonId = "",
  cancelButtonId = "",
  okText = "OK",
  cancelText,
  onOk = () => {},
  onCancel = () => {},
}) {
  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Card className="py-4 pl-8 pr-4">
        <div className="flex justify-between">
          <Heading size="h4">{title}</Heading>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => setShow(false)}
          />
        </div>
        {body}
        <div className="flex">
          <div className="mt-4 mb-2 mr-2">
            <div id={okButtonId}>
              <BaseUiButton label={okText} onClick={onOk} color="primary" />
            </div>
          </div>
          {cancelText && (
            <div className="mt-4 mb-2">
              <div id={cancelButtonId}>
                <BaseUiButton
                  label={cancelText}
                  onClick={onCancel}
                  color="primary"
                />
              </div>
            </div>
          )}
        </div>
      </Card>
    </Modal>
  );
}
