import React from "react";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import HelpIcon from "@material-ui/icons/Help";

import { ProfileInformation } from "./styles/ProfileInformation";
import { StyledIcon } from "./styles/SpanIcon";
/* import { AvailabilityType } from "../../TabNavigationBar"; */
import CalculationSection, { convertAnyToInt } from "./CalculationSection";
/* import { ProjectsTable } from "./ProjectsTable"; */

/* type SectionContents = {
  monthlyHoursAvailable: ExpertLegacyMetadata['monthlyHoursAvailable']
  availabilityCalculations: AvailabilityType
  legacyFreelancerId: number | undefined
} */
const Availability = (
  {
    // legacyFreelancerId,
    monthlyHoursAvailable,
    availabilityCalculations,
  } /* : SectionContents */
) /* : JSX.Element */ => {
  const {
    availableHours,
    currentMonthClientCount,
    currentMonthHoursCount,
    currentMonthProjectCount,
    estimatedPotentialHours,
    previousMonthClientCount,
    previousMonthHoursCount,
    previousMonthProjectCount,
    projectWinRate,
    totalProposalClients,
    totalProposals,
  } = availabilityCalculations || {}; //!
  const proposalsFieldsAndValues = [
    {
      field: "Number of clients",
      value: totalProposalClients,
    },
    {
      field: "Number of proposals",
      value: totalProposals,
    },
    {
      field: "Number of hours estimated",
      value: estimatedPotentialHours,
    },
  ];
  const currentMonthFieldsAndValues = [
    {
      field: "Number of clients",
      value: currentMonthClientCount,
    },
    {
      field: "Number of projects",
      value: currentMonthProjectCount,
    },
    {
      field: "Number of hours projected",
      value: currentMonthHoursCount,
    },
  ];
  const lastMonthFieldsAndValues = [
    {
      field: "Number of clients",
      value: previousMonthClientCount,
    },
    {
      field: "Number of projects",
      value: previousMonthProjectCount,
    },
    {
      field: "Number of hours billed",
      value: previousMonthHoursCount,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction="row"
        >
          <Box pt={2} pb={2}>
            <Grid item>
              <ProfileInformation>
                <StyledIcon>
                  <CheckIcon fontSize={"small"} />
                </StyledIcon>
                Goal: {convertAnyToInt(monthlyHoursAvailable)}
                <span>&nbsp; &nbsp;</span>
                <StyledIcon>
                  <HelpIcon fontSize={"small"} />
                </StyledIcon>
                Availability: {convertAnyToInt(availableHours)}
              </ProfileInformation>
            </Grid>
          </Box>
          <Grid
            container
            direction="row"
            spacing={10}
            alignItems="stretch"
            justify="center"
          >
            <Grid item xs={12} md={4}>
              <CalculationSection
                fieldsAndValues={lastMonthFieldsAndValues}
                title={"Last Month"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CalculationSection
                fieldsAndValues={currentMonthFieldsAndValues}
                title={"Current Month"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CalculationSection
                fieldsAndValues={proposalsFieldsAndValues}
                title={"Proposals"}
                titleValue={projectWinRate}
              />
            </Grid>
          </Grid>
          {/*  <Grid container item>
            <Grid item xs={12}>
              <Box pt={4} pb={2}>
                <Typography>Projects</Typography>
              </Box>
              <ProjectsTable legacyFreelancerId={legacyFreelancerId} />
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
};

export default Availability;
