import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

const Name /* : typeof Typography */ = styled((props) => {
  return <Typography variant="h6" {...props} />;
})`
    font-family: Roboto-Bold !important;
  }
}`;

export default Name;
