import React from "react";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green, grey, red } from "@material-ui/core/colors";
import { useContext } from "react";
import { CartContext } from "./CartContext";
import { submitFreelancerFeedback } from "../services/apiService";

const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    "&> button": {
      padding: theme.spacing(0.75),
      margin: theme.spacing(0.5, 0.25),
      minWidth: theme.spacing(5),
    },
  },
  rounded: {
    "&> button": {
      borderRadius: 25,
    },
  },
  thumbsUp: {
    ", &:hover": {
      backgroundColor: green[300],
    },
  },
  thumbsDown: {
    ", &:hover": {
      backgroundColor: red[300],
    },
  },
  roundedBg: {
    ", &:hover": {
      backgroundColor: "#fff",
    },
  },
  default: {
    ", &:hover": {
      backgroundColor: grey[300],
    },
  },
}));

export default function FreelancerVote({ freelancerId, proposalId, rounded }) {
  const { cartContext } = useContext(CartContext);
  const [cart, setCart] = cartContext;

  const submitFeedbackFn = (voteValue) => {
    const updatedFreelancers = [...cart];
    const objIndex = updatedFreelancers.findIndex(
      (obj) => obj.id === freelancerId
    );
    updatedFreelancers[objIndex].feedback = voteValue;
    setCart(updatedFreelancers);

    submitFreelancerFeedback({
      freelancerId,
      matchId: updatedFreelancers[objIndex].matchId || 0,
      proposalId,
      feedback: voteValue,
    });
  };

  const classes = useStyles();

  const feedbackScore = cart.find((obj) => obj.id === freelancerId)?.feedback;

  return (
    <div className={`${classes.buttonDiv} ${rounded ? classes.rounded : ""}`}>
      <Button
        variant="contained"
        className={
          feedbackScore === 1
            ? classes.thumbsUp
            : rounded
            ? classes.roundedBg
            : classes.default
        }
        aria-label="freelancer liked"
        id="mm-freelancer-thumbsUp"
        onClick={() => submitFeedbackFn(feedbackScore === 1 ? 0 : 1)}
      >
        <ThumbUpOutlinedIcon fontSize="small" />
      </Button>
      <Button
        variant="contained"
        id="mm-freelancer-thumbsDown"
        className={`ml-2 ${
          feedbackScore === -1
            ? classes.thumbsDown
            : rounded
            ? classes.roundedBg
            : classes.default
        }`}
        aria-label="freelancer disliked"
        onClick={() => submitFeedbackFn(feedbackScore === -1 ? 0 : -1)}
      >
        <ThumbDownOutlinedIcon fontSize="small" />
      </Button>
    </div>
  );
}
