import React from 'react'
import { Typography } from '@material-ui/core'
import omit from 'lodash/omit'
import styled from 'styled-components'

export const ProfileInformation = styled((props) => {
  return <Typography {...omit(props, ProfileInformation.OmitProps)} />
})`
  display: flex;
  padding: 2.5px 0 2.5px 0;
  ${({ rr }) => {
    return rr && 'flex-direction: row-reverse;'
  }}
`

ProfileInformation.OmitProps = [
  'rr'
]
