import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

export default function PaperCard({ title, paragraph }) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      {title && <h3>{title}</h3>}
      <p dangerouslySetInnerHTML={{ __html: paragraph }} />
    </Paper>
  );
}
