import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { Auth0Context } from "../contexts/Auth0Context";
import { CartContext } from "./CartContext";
import { MAX_REMATCHES, SECONDARY_SERVICE_LINES } from "../data/appConstants";

import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Button as BaseUIButton, Text } from "@paro.io/base-ui";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

import {
  runMatch,
  updateProposal,
  getReferenceFreelancersPool,
} from "../services/apiService";

import SelectSoftware from "./SelectSoftware";
import SelectService from "./SelectService";
import SelectTimeZone from "./SelectTimeZone";
import SelectIndustry from "./SelectIndustry";
import SelectSkills from "./SelectSkills";
import SelectEffort from "./SelectEffort";

import { useSnackbar } from "notistack";
import InfoButton from "./InfoButton";
import { Modal } from "react-bootstrap";

import UpdateProposalForm from "./UpdateProposalForm";
import RematchFeedbackForm from "./RematchFeedbackForm";
import { useTags } from "../contexts/useTags";

const useStyles = makeStyles({
  modal: {
    "& .modal-dialog": {
      minWidth: "900px",
    },
  },
});

const formatSoftTags = (data = []) => {
  return (
    "" +
    data.reduce((acc, i) => {
      if (!i.required) {
        return [...acc, i.value || i.inputValue];
      }
      return acc;
    }, [])
  );
};

const formatHardTags = (data = []) => {
  return (
    "" +
    data.reduce((acc, i) => {
      if (i.required) {
        return [...acc, i.value || i.inputValue];
      }
      return acc;
    }, [])
  );
};

export default function MatchForm({
  // currentProposalObj,
  // setCurrentProposalObj,
  defaultValues,
  loadMatchData,
  setInitialLoad,
  setLoadingData0,
  loadingMatches,
  refreshCart,
  refetchProposal,
}) {
  const { user } = useContext(Auth0Context);

  const { handleSubmit, control, formState, register, getValues } = useForm({
    defaultValues: {
      ...defaultValues,
      timezones: "",
      secondaryServiceLine: "",
    },
    mode: "onChange",
  });
  const { enqueueSnackbar } = useSnackbar();

  const [showDetails, setShowDetails] = useState(false);
  const [iterations] = useContext(CartContext).iterationsContext;
  const [showRematchForm, setShowRematchForm] = useState(0);
  const [secondaryServiceOptions, setSecondaryServiceOptions] = useState(
    SECONDARY_SERVICE_LINES[defaultValues.serviceLine]
  );
  const [freelancerPool, setFreelancerPool] = useState();
  const [loadingPool, setLoadingPool] = useState(false);
  const timezoneOptions = [
    "Eastern Standard Time (EST)",
    "Central Standard Time (CST)",
    "Mountain Standard Time (MST)",
    "Pacific Standard Time (PST)",
  ];

  const handleCloseFeedbackForm = () => {
    setShowRematchForm(0);
  };

  const {
    effortOptions,
    serviceOptions,
    softwareOptions,
    industryOptions,
    skillOptions,
  } = useTags();

  const classes = useStyles();

  const buildRequest = (data) => {
    const requestData = {
      id: defaultValues.id,
      projectSize: data.projectSize,
      clientRate: parseInt(data.clientRate),
      industry: formatSoftTags(data.industries),
      requiredIndustry: formatHardTags(data.industries),
      software: formatSoftTags(data.softwares),
      requiredSoftware: formatHardTags(data.softwares),
      skill: formatSoftTags(data.skills),
      requiredSkill: formatHardTags(data.skills),
      description: defaultValues.description,
      clientName: defaultValues.client.name,
      notes: defaultValues.notes,
      serviceLine: data.serviceLine,
      secondaryServiceLine: data.secondaryServiceLine,
      timezones: data.timezones,
      engagedFreelancers: defaultValues.proposalFreelancers,
      userId: user.auth0UserId,
      billRateType: data.billRateType,
      frequency: data.frequency,
    };
    return requestData;
  };
  const checkFreelancerPool = async () => {
    const data = getValues();

    const reqData = buildRequest(data);
    setLoadingPool(true);
    const { data: freelancerPoolResponse } = await getReferenceFreelancersPool(
      reqData
    );
    const flPool =
      freelancerPoolResponse &&
      freelancerPoolResponse.data.getReferenceFreelancersPool.totalFreelancers;
    setFreelancerPool("" + (flPool || 0));
    setLoadingPool(false);
  };

  const runMatchFn = async (data) => {
    handleCloseFeedbackForm();
    refreshCart();
    setInitialLoad(false);
    const requestData = buildRequest(data);
    try {
      const { data: responseData } = await runMatch(requestData);
      const industryTags = [
        ...requestData.industry
          .split(",")
          .filter((v) => v !== "")
          .map((i) => ({
            field: "industries",
            value: i,
            required: false,
          })),
        ...requestData.requiredIndustry
          .split(",")
          .filter((v) => v !== "")
          .map((i) => ({
            field: "industries",
            value: i,
            required: true,
          })),
      ];

      const softwareTags = [
        ...requestData.software
          .split(",")
          .filter((v) => v !== "")
          .map((i) => ({
            field: "softwares",
            value: i,
            required: false,
          })),
        ...requestData.requiredSoftware
          .split(",")
          .filter((v) => v !== "")
          .map((i) => ({
            field: "softwares",
            value: i,
            required: true,
          })),
      ];

      const skillTags = [
        ...requestData.skill
          .split(",")
          .filter((v) => v !== "")
          .map((i) => ({
            field: "skills",
            value: i,
            required: false,
          })),
        ...requestData.requiredSkill
          .split(",")
          .filter((v) => v !== "")
          .map((i) => ({
            field: "skills",
            value: i,
            required: true,
          })),
      ];
      const tagsData = [...industryTags, ...softwareTags, ...skillTags];

      const proposalRequestData = {
        primaryServiceLine: requestData.serviceLine,
        secondaryServiceLine: requestData.secondaryServiceLine,
        projectSize: requestData.projectSize,
        matchmakingQuery: {
          tags: tagsData,
          rateRange: {
            min: 0,
            max: parseInt(requestData.clientRate),
          },
        },
      };
      await updateProposal(defaultValues.id, proposalRequestData);

      if (
        responseData.errors &&
        responseData.errors[0].extensions?.code === "400"
      ) {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
        loadMatchData({ matchId: null, recommendedFreeLancers: [] });
        return;
      }

      loadMatchData(responseData.data.updateRecommendations);
      window.sessionStorage.setItem(
        "matchContext",
        JSON.stringify({
          mId: responseData.data.updateRecommendations.matchId,
          pId: defaultValues.id,
        })
      );
      updateProposalFn();
    } catch (err) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      loadMatchData({ matchId: null, recommendedFreeLancers: [] });
      return;
    }
  };

  const onSubmit = (data) => {
    if (iterations > 0) {
      return setShowRematchForm(1);
    }
    runMatchFn(data);
  };

  const information =
    "Configuration Ribbon: Search fields are pre-filled from your proposal. Input search details in required fields (*). You can search for new tags as free text which will be added to the tag bank. If your match fails, open up your search parameters (i.e. increase rate, set effort to unknown, etc.)";
  const informationEdit =
    "Allows user to edit / update the global proposal form";

  const updateProposalFn = () => {
    setShowDetails(false);
    // setInitialLoad(true);
    setLoadingData0(true);
    refetchProposal(defaultValues.id);
    // window.location.href = "/proposal/" + defaultValues.id;
  };

  return (
    <Box bgcolor="white" className="mx-5 px-4 py-3">
      <Grid container alignItems="center" justify="flex-end" className="px-2">
        <div>
          <InfoButton information={informationEdit} />
        </div>
        <span id="mm-matchForm-editProposal">
          <Button
            variant="contained"
            className="mr-2"
            onClick={() => setShowDetails(true)}
          >
            <EditIcon /> <span className="ml-2">EDIT PROPOSAL</span>
          </Button>
        </span>
      </Grid>
      {/* <span onClick={() => setShowDetails(true)}>edit</span> */}
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} container justify="center">
            <Grid item xs={12} container justify="center" alignItems="center">
              <Typography variant="h5" component="h2">
                {defaultValues.client?.name}
              </Typography>
              <InfoButton information={information} />
            </Grid>
            <Grid item xs={12} container justify="center">
              <Typography variant="body2" component="p">
                {defaultValues.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} container item spacing={1}>
            <Grid item xs={2}>
              <Controller
                name="clientRate"
                control={control}
                /*  rules={{ required: "Client Rate required" }} */
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    id="mm-matchForm-clientRate"
                    label="Client Rate ($) *"
                    type="number"
                    variant="outlined"
                    {...register("clientRate", {
                      required: true,
                      validate: (val) => val && val > 0,
                    })}
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    // defaultValue={value}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={
                      error ? "Client Rate $ Must be more than zero" : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="projectSize"
                rules={{ required: "Effort required" }}
                // defaultValue={effortOptions}
                render={({ field, fieldState: { error } }) => (
                  <SelectEffort
                    {...field}
                    id="mm-matchForm-effort"
                    label="Effort *"
                    fullWidth
                    options={effortOptions}
                    error={formState.isSubmitted && error}
                    helperText={
                      formState.isSubmitted && error ? error.message : null
                    }
                  />
                )}
                control={control}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="serviceLine"
                rules={{ required: "Service required" }}
                // defaultValue={serviceOptions}
                render={({ field, field: { onChange }, fieldState: { error } }) => (
                  <span id="mm-matchForm-serviceLine">
                    <SelectService
                      {...field}
                      onChange={(newValue) => {
                        setSecondaryServiceOptions(
                          SECONDARY_SERVICE_LINES[newValue]
                        );
                        onChange(newValue);
                      }}
                      label="Service *"
                      fullWidth
                      options={serviceOptions}
                      error={formState.isSubmitted && error}
                      helperText={
                        formState.isSubmitted && error ? error.message : null
                      }
                    />
                  </span>
                )}
                control={control}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="secondaryServiceLine"
                render={({ field }) => (
                  <span id="mm-matchForm-secondaryServiceLine">
                    <TextField
                      {...field}
                      select
                      label="Secondary Service Line"
                      variant="outlined"
                      fullWidth
                    >
                      {secondaryServiceOptions &&
                        secondaryServiceOptions.map((option) => (
                          <MenuItem
                            key={option}
                            value={option}
                            className="menu-item-ssl"
                          >
                            {option}
                          </MenuItem>
                        ))}
                    </TextField>
                  </span>
                )}
                control={control}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="timezones"
                defaultValue={""}
                render={({ field }) => (
                  <span id="mm-matchForm-timezone">
                    <SelectTimeZone
                      {...field}
                      label="Time Zone"
                      fullWidth
                      options={timezoneOptions}
                    />
                  </span>
                )}
                control={control}
              />
            </Grid>
          </Grid>
          <Grid xs={12} container item spacing={1}>
            <Grid item xs={4}>
              <Controller
                name="industries"
                rules={{ required: "Industry required" }}
                // defaultValue={industryOptions}
                render={({ field, fieldState: { error } }) => (
                  <span id="mm-matchForm-industry">
                    <SelectIndustry
                      {...field}
                      label="Industry *"
                      options={industryOptions}
                      error={formState.isSubmitted && error}
                      helperText={
                        formState.isSubmitted && error ? error.message : null
                      }
                    />
                  </span>
                )}
                control={control}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="softwares"
                rules={{ required: "Software required" }}
                // defaultValue={softwareOptions}
                render={({ field, fieldState: { error } }) => (
                  <span id="mm-matchForm-software">
                    <SelectSoftware
                      {...field}
                      label="Software *"
                      options={softwareOptions}
                      error={formState.isSubmitted && error}
                      helperText={
                        formState.isSubmitted && error ? error.message : null
                      }
                    />
                  </span>
                )}
                control={control}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="skills"
                render={({ field }) => (
                  <span id="mm-matchForm-skill">
                    <SelectSkills
                      {...field}
                      label="Skills"
                      options={skillOptions}
                    />
                  </span>
                )}
                control={control}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            spacing={2}
            container
            flex
            alignItems="center"
            justifyContent="between"
          >
            <Grid item xs={4}>
              <span id="check-fl-pool">
                <BaseUIButton
                  label="Check Freelancer Pool?"
                  className="mr-2"
                  type="button"
                  onClick={() => {
                    checkFreelancerPool();
                  }}
                ></BaseUIButton>
              </span>
              {loadingPool ? (
                <Text className={"text-green-info"}>{`Fetching...`}</Text>
              ) : !!freelancerPool ? (
                <Text
                  className={
                    freelancerPool >= 100
                      ? "text-green-info"
                      : freelancerPool >= 50 && freelancerPool <= 99
                        ? "text-orange-info"
                        : "text-red-info"
                  }
                >{`${freelancerPool} Freelancers available`}</Text>
              ) : (
                <Text>&nbsp;</Text>
              )}
            </Grid>
            <Grid item xs={4} align="center">
              {iterations > 0 &&
                iterations <= MAX_REMATCHES &&
                !formState.touchedFields.clientRate &&
                !formState.dirtyFields.projectSize &&
                !formState.dirtyFields.serviceLine &&
                !formState.dirtyFields.secondaryServiceLine &&
                !formState.dirtyFields.timezones &&
                !formState.dirtyFields.softwares &&
                !formState.dirtyFields.industries &&
                !formState.dirtyFields.skills ? (
                <BaseUIButton
                  color="primary"
                  type="submit"
                  disabled
                  label="Change Values to Rematch"
                ></BaseUIButton>
              ) : (
                <BaseUIButton
                  id={`mm-matchForm-rematch-${iterations}`}
                  color="primary"
                  type="submit"
                  disabled={iterations > MAX_REMATCHES || loadingMatches}
                  label={
                    loadingMatches
                      ? `Loading`
                      : iterations > 0 && iterations <= MAX_REMATCHES
                        ? `Rematch`
                        : iterations > MAX_REMATCHES
                          ? `You've run out of matches`
                          : `Match`
                  }
                ></BaseUIButton>
              )}
              <Text>
                {`${MAX_REMATCHES + 1 - iterations} match${MAX_REMATCHES + 1 - iterations === 1 ? "" : "es"
                  } remaining`}
              </Text>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Grid>
      </form>
      {!!showDetails && (
        <Modal
          show={!!showDetails}
          onHide={() => {
            setShowDetails(false);
            // history.push("/proposal" + defaultValues.id);
          }}
          className={classes.modal}
          centered
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>
              <Typography variant="h5" component="h2">
                Proposal Information for {defaultValues.client?.name}
              </Typography>
            </Modal.Title>
          </Modal.Header> */}
          <UpdateProposalForm
            defaultValues={defaultValues}
            updateProposalFn={updateProposalFn}
          />
        </Modal>
      )}

      {/* {!!showDetails && (
        <Dialog
          show={!!showDetails}
          onHide={() => {
            setShowDetails(false);
            // history.push("/proposal" + defaultValues.id);
          }}
          open={isOpen}
          className="py-4 text-left px-6 overflow-y-auto"
        >
          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-end min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
              <Dialog.Overlay className="fixed inset-0" />
              <UpdateProposalForm
                defaultValues={defaultValues}
                updateProposalFn={updateProposalFn}
              />
            </div>
          </div>
        </Dialog>
      )} */}

      <Modal
        show={!!showRematchForm}
        onHide={handleCloseFeedbackForm}
        className={classes.modal}
      // centered
      >
        <Modal.Header closeButton />
        <RematchFeedbackForm
          showRematchForm={showRematchForm}
          runMatchFn={() => runMatchFn(getValues())}
        />
      </Modal>
    </Box>
  );
}
