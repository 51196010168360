import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Avatar, Grid } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  fullFreelancerCards: {
    height: "100%",
    cursor: "pointer",
  },

  textName: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: 1.5,
    color: "#1C1F2B",
  },
  textTitle: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: 1.6,
    color: "#1C1F2B",
  },
  textLocation: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.2,
    color: "#8F9BB3",
  },
  textDescription: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: 1.5,
    color: "#1C1F2B",
  },
  avatar: {
    backgroundColor: grey[500],
    margin: "1em auto",
    width: theme.spacing(10),
    height: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  label: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: 1.5,
    color: "#1C1F2B",
  },
  value: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.2,
    color: "#1C1F2B",
  },
}));
// const StyledRating = withStyles({
//   iconFilled: {
//     color: "green",
//   },
// })(Rating);

export default function ProfileCard({
  name,
  imageUrl,
  title,
  tagline,
  location,
  defaultHourlyRate,
  clientHourlyRate,
  availableHours,
  goalHours,
}) {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={2}>
          <Avatar aria-label="profile letter" className={classes.avatar}>
            <img src={imageUrl} alt="" width="100%" />
          </Avatar>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body1"
            className={classes.textName}
            component="p"
          >
            {name}
          </Typography>
          <Typography
            variant="body1"
            className={classes.textTitle}
            component="p"
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            className={classes.textLocation}
            component="p"
          >
            {location}
          </Typography>
          <Typography
            variant="body1"
            className={classes.textDescription}
            component="p"
            dangerouslySetInnerHTML={{ __html: tagline }}
          ></Typography>
          <Grid container spacing={1} className="py-4" alignItems="center">
            <Grid item xs={3}>
              <Typography
                variant="body2"
                component="p"
                className={classes.label}
              >
                Freelancer/Client Hourly Rate
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                component="p"
                className={classes.label}
              >
                $ {defaultHourlyRate} / {clientHourlyRate}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                component="p"
                className={classes.label}
              >
                Goal (in Hours)
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                component="p"
                className={classes.label}
              >
                {goalHours}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                component="p"
                className={classes.label}
              >
                Availability (in Hours)
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                component="p"
                className={classes.label}
              >
                {availableHours}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
