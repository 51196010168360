import React /* , { Dispatch, SetStateAction } */ from "react";
import { Box, Grid } from "@material-ui/core";
import styled from "styled-components";

// import { StandardizedParoProject } from "../shared/displayConversions";
import { HeaderButtonUnderline } from "../styles/Buttons";
import { SectionTitle } from "../styles/SectionTitle";
import MissingItem from "./MissingItem";

/* type SectionContents = {
  incrementShowCountBy: number
  objects: Partial<WorkExperience>[] | Partial<ExternalProject | StandardizedParoProject>[][]
  sections: React.ReactNode[]
  setShowCount: Dispatch<SetStateAction<number>>
  showCount: number
  title: string
} */
const LoadMoreSectionTitle = styled(SectionTitle)`
  padding: 11.5px 0px 15.5px 0px;
`;

const LoadMoreSection = (
  {
    title,
    objects,
    sections,
    showCount,
    setShowCount,
    incrementShowCountBy,
  } /* : SectionContents */
) /* : JSX.Element */ => {
  const viewMoreTitle = title === "Work experience" ? `${title}s` : title;
  return (
    <>
      {objects?.length > 0 ? (
        sections
      ) : (
        <>
          <Grid item>
            <LoadMoreSectionTitle>{title}</LoadMoreSectionTitle>
          </Grid>
          <MissingItem title={title} />
        </>
      )}
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Box py={2}>
          <Grid item>
            {showCount < objects.length && (
              <HeaderButtonUnderline
                onClick={() => {
                  setShowCount(showCount + incrementShowCountBy);
                }}
              >
                View three more {viewMoreTitle}
              </HeaderButtonUnderline>
            )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default LoadMoreSection;
