import React from "react";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

import FieldAndValue from "./_global/FieldAndValue";
import { ProfileInformation } from "./styles/ProfileInformation";
import { SectionBody } from "./styles/SectionBody";

/* type SectionContents = {
  fieldsAndValues: { [key: string]: number | string }[]
  title: string
  titleValue?: number | string
} */

const CalculationField = styled(ProfileInformation)`
  width: fit-content;
`;

const CalculationTitleValue /* : typeof Grid  */ = styled(Grid)`
  margin-right: -10px;
`;
const CalculationTitle /* : typeof Typography */ = styled(Typography)`
  padding-bottom: 16px;
`;
export const convertAnyToInt = (
  value /* : string | number | undefined | null */
) /* : number */ => {
  const nonNumericCharacterRegex = /[^0-9.]/g;
  return parseInt(
    (value || "").toString().replace(nonNumericCharacterRegex, "") || "0"
  );
};
const convertAnyToPercent = (
  value /* : string | number | undefined | null */
) /* : string */ => {
  value = parseFloat(value ? value + "" : "0");
  return (value * 100).toFixed(0) + "%";
};
const CalculationSection = (
  { fieldsAndValues, title, titleValue } /* : SectionContents */
) /* : JSX.Element */ => {
  return (
    <SectionBody>
      <Grid container item justify="space-between" xs={12}>
        <Grid item>
          <CalculationTitle variant="h6">{title}</CalculationTitle>
        </Grid>
        {titleValue && (
          <CalculationTitleValue item>
            <ProfileInformation>
              {convertAnyToPercent(titleValue)}
            </ProfileInformation>
          </CalculationTitleValue>
        )}
      </Grid>
      <Grid container item justify="space-between" xs={12}>
        {fieldsAndValues.map(({ field, value }, index) => {
          const valueAsInt = convertAnyToInt(value);
          return (
            <FieldAndValue key={index}>
              <CalculationField display="inline" noWrap variant="body2">
                {field}
              </CalculationField>
              <ProfileInformation rr>{valueAsInt}</ProfileInformation>
            </FieldAndValue>
          );
        })}
      </Grid>
    </SectionBody>
  );
};

export default CalculationSection;
