import React, { useContext, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MatchCard from "../components/MatchCard";
import { CartContext } from "./CartContext";
import { useSnackbar } from "notistack";
import CustomSlider from "./CustomSlider";
//import { Modal } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Button as BaseUiButton } from "@paro.io/base-ui";

import FeedbackFormComponent from "./FeedbackFormComponent";
import {
  addSingleFlToCartFn,
  removeFreelancer,
  removeFromCartFn,
} from "../services/apiService";
import { Auth0Context } from "../contexts/Auth0Context";
import { MAX_COMPARE, REMOVE_REASONS } from "../data/appConstants";

const slickArrowStyleOverrides = {
  color: "#27316b",
  fontSize: 40,
};

const useStyles = makeStyles((theme) => ({
  carouselDiv: {
    padding: theme.spacing(6),
    "& .slick-track": {
      display: "flex !important",
    },
    "& .slick-slide": {
      height: "inherit !important",
      "&> div": {
        height: "100% !important",
      },
    },
    "& .slick-prev:before": {
      position: "relative",
      left: -20,
      ...slickArrowStyleOverrides,
    },
    "& .slick-next:before": slickArrowStyleOverrides,
    "& .slick-list": {
      overflowY: "inherit",
      overflowX: "clip",
    },
    "& .slick-dots": {
      position: "unset",
    },
  },
  carouselItem: {
    padding: theme.spacing(3, 2, 4, 2),
    height: "100%",
  },

  modal: {
    "& .modal-dialog": {
      minWidth: "75vw",
    },
  },
}));

export default function ProposalFreelancersSection({
  proposal,
  freelancers,
  showFullProfile,
  setShowScorecard,
  setShowCompare,
}) {
  const [cart, setCart] = useContext(CartContext).cartContext;
  const [matchId] = useContext(CartContext).matchContext;
  const [compareFls] = useContext(CartContext).compareFlsContext;
  const [showDetails, setShowDetails] = useState(null);

  const { user } = useContext(Auth0Context);
  const { firstName, lastName } =
    user[`${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`];
  const userName = firstName + " " + lastName;

  //const [showDetails, setShowDetails] = useState(false);

  //const channel = useMemo(() => new BroadcastChannel("freelancer_profile"), [])

  const { enqueueSnackbar } = useSnackbar();
  const addToCart = async (f) => {
    const data = {
      proposalId: proposal.id,
      userId: user.auth0UserId,
      matchId,
      recommendedFreeLancers: [
        {
          flID: f.flID,
          matchPercent: f.matchPercent,
          category: f.category,
        },
      ],
    };
    await addSingleFlToCartFn(data);

    enqueueSnackbar(`Added ${f.FL_Name} to Cart!`, {
      variant: "success",
    });

    setCart((currentState) => [...currentState, f]);
  };

  const removeFromCart = async (f) => {
    const data = {
      proposalId: proposal.id,
      userId: user.auth0UserId,
      matchId,
      freelancerId: f.flID,
    };
    await removeFromCartFn(data);

    enqueueSnackbar(`Removed ${f.FL_Name} from Cart!`, {
      variant: "warning",
    });
    setCart((currentState) => currentState.filter((c) => c.flID !== f.flID));
  };

  const handleShow = (f) => {
    setShowDetails(f);
  };

  const handleClose = () => {
    setShowDetails(null);
  };

  const onSubmitFn = (data, other = false) => {
    removeFreelancer({
      userId: user.auth0UserId,
      userName,
      freelancerId: showDetails.flID,
      matchId,
      proposalId: proposal.id,
      reason:
        "" +
        (other
          ? [...data.predefinedReasons, data.otherReason]
          : data.predefinedReasons),
      comments: data.comments,
    })
      .then(() => {
        removeFromCart(showDetails);
        handleClose();
      })
      .catch(() => {
        enqueueSnackbar(`Unable to remove Freelancer ${showDetails.flID}!`, {
          variant: "error",
        });
        handleClose();
      });
  };

  // useEffect(() => {
  //   channel.onmessage = msg => {
  //     console.log(msg);
  //     if(msg.data.text === "added to cart"){
  //       addToCart(msg.data.value)
  //     }else if(msg.data.text === "removed from cart"){
  //       removeFromCart(msg.data.value)
  //     }
  //   }
  //   return () => {
  //     channel.close();
  //   };
  // }, [channel, addToCart, removeFromCart]);

  const classes = useStyles();

  return (
    <Box m={2} p={2}>
      <Grid container spacing={7} justify="center">
        <Grid
          item
          xs={12}
          container
          spacing={2}
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} container justifyContent="flex-end">
            <span className="p-4 font-bold">{`${compareFls.length}/${MAX_COMPARE} Selected`}</span>
            <span id="mm-compare" className="right">
              <BaseUiButton
                onClick={() => {
                  if (compareFls.length <= 0 || compareFls.length > MAX_COMPARE)
                    return enqueueSnackbar(
                      `Select upto ${MAX_COMPARE} Freelancers to Compare`,
                      {
                        variant: "warning",
                      }
                    );
                  setShowCompare(true);
                }}
                className="mr-2 my-3"
                color="primary"
                label="COMPARE"
              />
            </span>
          </Grid>
          <Grid item xs={12} className={classes.carouselDiv}>
            <CustomSlider className="h-100">
              {freelancers
                .sort((a, b) => b.matchPercent - a.matchPercent)
                .map((f) => (
                  <div key={f.flID} className={classes.carouselItem}>
                    {!!f.FL_Name ? (
                      <MatchCard
                        f={f}
                        showFullProfile={showFullProfile}
                        setShowScorecard={setShowScorecard}
                        cart={cart}
                        proposalId={proposal.id}
                        addToCart={addToCart}
                        handleShow={handleShow}
                      />
                    ) : (
                      <span>{`Cannot fetch freelancer ${f.flID} details`}</span>
                    )}
                  </div>
                ))}
            </CustomSlider>
          </Grid>
        </Grid>
      </Grid>
      <Modal show={!!showDetails} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Remove Freelancer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FeedbackFormComponent
            onSubmitFn={onSubmitFn}
            question="What are the reasons to remove this Freelancer?"
            options={REMOVE_REASONS}
            newReasonPlaceholder="i.e. Lack of experience, etc."
            submitButtonText="REMOVE"
            commentsHeading="Briefly explain your experience working with this freelancer"
            submittingText="Removing from cart"
          />
        </Modal.Body>
      </Modal>
      {/* {!!showDetails && (

        <Modal
          show={!!showDetails}
          onHide={() => setShowDetails(false)}
          className={classes.modal}
          // centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Freelancer Details
              <InfoButton information={information2} />
            </Modal.Title>
          </Modal.Header>

          <FreelancerCards
            data={showDetails}
            proposalNotes={proposal.notes}
            proposalId={proposal.id}
          />
        </Modal>
      )} */}
    </Box>
  );
}
