import React, { useState } from "react";
// import { Box, Grid } from "@material-ui/core";
import omit from "lodash/omit";
import styled from "styled-components";

// import { StandardizedParoProject } from '../shared/displayConversions'
import { SectionBody } from "./styles/SectionBody";
import MinimalProjectInformation from "./MinimalProjectInformation";
import MoreProjectInformation from "./MoreProjectInformation";

/* type SectionContents = {
  project: Partial<ExternalProject | StandardizedParoProject>
  isFirstListedProject?: boolean
} */
// const ProjectCardGrid /* : typeof Grid */ = styled(Grid)`
// min-width: 330px;
// `;
const ProjectCardBody = styled((props) => {
  return <SectionBody {...omit(props, ProjectCardBody.OmitProps)} />;
})`
  height: 100%;
  ${({ isMoreInfoOpen }) => {
    return (
      !isMoreInfoOpen &&
      `
        padding-bottom: 10;
      `
    );
  }}
`;
ProjectCardBody.OmitProps = ["isMoreInfoOpen"];
const ProjectCard = (
  { isFirstListedProject = false, project } /* : SectionContents */
) /* : JSX.Element */ => {
  const [isMoreInfoOpen, setMoreOpenInfo] = useState(false);
  const handleOpenInfo = () => {
    return setMoreOpenInfo(true);
  };
  const handleCloseInfo = () => {
    return setMoreOpenInfo(false);
  };

  return (
    <>
      {/* {isFirstListedProject ? (
        <ProjectCardGrid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <LoadMoreCardTitle>Projects</LoadMoreCardTitle>
          </Grid>
        </ProjectCardGrid>
      ) : (
        <Box pt={6} />
      )} */}

      <ProjectCardBody isMoreInfoOpen={isMoreInfoOpen}>
        <MinimalProjectInformation
          isMoreInfoOpen={isMoreInfoOpen}
          project={project}
          handleOpenOrClose={handleOpenInfo}
        />
        <MoreProjectInformation
          project={project}
          open={isMoreInfoOpen}
          handleClose={handleCloseInfo}
        />
      </ProjectCardBody>
    </>
  );
};

export default ProjectCard;
