import React from "react";
import Chip from "@material-ui/core/Chip";

import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import AccordionHeader from "./AccordionHeader";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    padding: theme.spacing(1),
  },
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));
export default function CertificatesCard({
  certificates = [],
  widget,
  handleChange,
  dragIndicator,
  checkbox,
}) {
  const classes = useStyles();
  return (
    <Accordion className={`mt-3 mb-2 ${classes.accordionRoot}`} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />} aria-label="Expand">
        <AccordionHeader
          title="ACCOMPLISHMENTS"
          widget={widget}
          handleChange={handleChange}
          dragIndicator={dragIndicator}
          checkbox={checkbox}
        ></AccordionHeader>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={6}>
          <Grid item>
            <h6>Certifications</h6>
            {certificates.length > 0 &&
              certificates.map((c, i) => (
                <Chip key={i} size="small" label={c} className={classes.chip} />
              ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
