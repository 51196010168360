import { createStyles, makeStyles } from '@material-ui/core/styles'

export const centerContentOnMobile = makeStyles((theme) => {
  return createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        width: 'auto'
      }
    }
  })
}
)
