import React from "react";
import { Card, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Avatar, Grid, Link, Tooltip } from "@material-ui/core";
import TruncateText from "./TruncateText";
import { DeleteForeverOutlined, LocationOn } from "@material-ui/icons";
import { IconScale } from "@paro.io/base-icons";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import MatchCardMenu from "./MatchCardMenu";
import { grey, yellow } from "@material-ui/core/colors";
import FreelancerVote from "./FreelancerVote";
import { Button as BaseUiButton } from "@paro.io/base-ui";
import { useContext } from "react";
import { CartContext } from "./CartContext";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexFlow: "wrap",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
    overflow: "visible",
  },
  matchCard: { height: "100%", alignContent: "flex-start" },
  exploreBtn: {
    float: "right",
    background: yellow[200],
    color: grey[700],
    fontSize: 10,
    marginRight: theme.spacing(3),
    height: "20px !important",
    boxShadow: "none",
  },
  innerDiv: {
    padding: theme.spacing(1, 3),
    flexGrow: 1,
    height: "70%",
    alignContent: "space-between",
  },
  topDiv: {
    minHeight: 20,
    position: "relative",
  },
  addDiv: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  compareDiv: {
    position: "absolute",
    top: 5,
    left: 100,
  },
  infoDiv: {
    position: "absolute",
    top: 5,
    right: 0,
  },
  addedIndicator: {
    display: "inline-block",
    color: grey[900],
    backgroundColor: "#6eedbf",
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    // borderBottomRightRadius: 15,
    // borderTopRightRadius: 15,
    padding: theme.spacing(0.5, 1),
  },
  bio: {
    padding: theme.spacing(2, 0),
  },
  primaryDetails: {
    minHeight: 120,
  },
  avatar: {
    backgroundColor: grey[500],
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: "auto",
  },
  label: {
    fontWeight: "bold",
    paddingTop: theme.spacing(2),
    fontSize: 16,
  },
  lastDiv: { marginTop: "auto" },
  percentage: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#059669",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
    cursor: "pointer",
  },
  link: {
    "&.MuiLink-underlineNone": {
      color: grey[500],
      textDecoration: "none",
    },
  },
  buttonSize: {
    fontSize: "12px",
    // textTransform: "uppercase",
    margin: theme.spacing(1, 0.25),
  },
  voteButtons: {
    fontSize: "8px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "absolute",
    bottom: theme.spacing(-2),
    right: theme.spacing(2),
  },
}));

export default function MatchCard({
  f,
  showFullProfile,
  setShowScorecard,
  cart,
  proposalId,
  handleShow,
  addToCart,
}) {
  const [compareFls, setCompareFls] = useContext(CartContext).compareFlsContext;

  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <Grid container className={classes.matchCard}>
          <Grid item xs={12} container className="bg-gray-100 relative">
            <Grid container spacing={2} className={classes.topDiv}>
              <Grid item xs={6} className={classes.addDiv}>
                {cart.some((cartItem) => cartItem.flID === f.flID) && (
                  <div className={classes.addedIndicator}>
                    <ShoppingCartOutlinedIcon fontSize="small" /> Added
                  </div>
                )}
              </Grid>
              <Grid item xs={6} className={classes.compareDiv}>
                {compareFls.some((fl) => fl.flID === f.flID) && <IconScale />}
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <div
                className={classes.percentage}
                onClick={() => setShowScorecard(f)}
              >
                {Math.round(f.matchPercent)}% Match
              </div>
            </Grid>
            <Grid item xs={12} container className="py-4">
              <Grid item xs={3}>
                <Avatar className={classes.avatar}>
                  <img src={f.imageUrl} alt="" width="100%" />
                </Avatar>
              </Grid>
              <Grid item xs={6} className={classes.primaryDetails}>
                {/*  <br />
        {f.category === "Explore" ? (
          <Tooltip
            title={
              "New freelancer to Paro with great potential"
            }
          >
            <Fab
              variant={"extended"}
              size="small"
              className={classes.exploreBtn}
            >
              EXPLORE
            </Fab>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              "Elite Freelancers with excellent relationship with Paro"
            }
          >
            <Fab
              variant={"extended"}
              size="small"
              className={classes.exploreBtn}
            >
              EXPLOIT
            </Fab>
          </Tooltip>
        )} */}

                <h5 className="font-bold">{f.FL_Name}</h5>
                <h6>{f.FL_Service}</h6>

                <div className={"mt-1 mb-1"}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                  >
                    <LocationOn className="mb-1" />
                    {f.address?.city + ", " + f.address?.region}
                  </Typography>
                  <div>
                    <Tooltip
                      title={
                        <>
                          <Typography
                            variant="caption"
                            color="textPrimary"
                            display="block"
                          >{`${
                            f.availability.previousMonthClientCount || 0
                          } - Clients last month`}</Typography>
                          <Typography
                            variant="caption"
                            color="textPrimary"
                            display="block"
                          >{`${
                            f.availability.currentMonthClientCount || 0
                          } - Clients current month`}</Typography>
                          <Typography
                            variant="caption"
                            color="textPrimary"
                            display="block"
                          >{`${
                            f.availability.totalProposalClients || 0
                          } - Clients proposed`}</Typography>
                        </>
                      }
                    >
                      <Link
                        underline="none"
                        className={classes.link}
                        onClick={() => showFullProfile(f, 1)}
                      >
                        {`${f.availability.previousMonthClientCount || 0} | ${
                          f.availability.currentMonthClientCount || 0
                        } | ${f.availability.totalProposalClients || 0}`}
                      </Link>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <MatchCardMenu
                  freelancer={f}
                  proposalId={proposalId}
                  showFullProfile={showFullProfile}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container className={`${classes.voteButtons}`}>
              <FreelancerVote
                freelancerId={f.flID}
                proposalId={proposalId}
                rounded
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.innerDiv}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  component="p"
                  className={classes.label}
                >
                  Why I Freelance:
                </Typography>
                <Typography variant="body2" color="textPrimary" component="p">
                  <TruncateText
                    text={f.FL_Tagline || "Missing Data"}
                    limit={100}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  component="p"
                  className={classes.label}
                >
                  About Me:
                </Typography>
                <Typography variant="body2" color="textPrimary" component="p">
                  <TruncateText
                    text={f.Short_Bio || "Missing Data"}
                    limit={100}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  component="p"
                  className={classes.label}
                >
                  Recent Experience:
                </Typography>
                <Typography variant="body2" color="textPrimary" component="p">
                  <TruncateText
                    text={f.Recent_Experience || "Missing Data"}
                    limit={200}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              spacing={2}
              justifyContent="space-between"
              alignItems="space-between"
              className={classes.lastDiv}
            >
              <Grid item xs={5}>
                {cart.some((cartItem) => cartItem.flID === f.flID) ? (
                  <span id="mm-matchFreelancer-remove">
                    <BaseUiButton
                      color="primary"
                      size="sm"
                      label="Remove"
                      onClick={() => {
                        handleShow(f);
                        // channel.postMessage({text: "removed from cart", value:f});
                      }}
                      iconLeft={<DeleteForeverOutlined />}
                    />
                  </span>
                ) : (
                  <span id="mm-matchFreelancer-add">
                    <BaseUiButton
                      color="primary"
                      size="sm"
                      label="Add"
                      onClick={() => {
                        addToCart(f);
                        // channel.postMessage({text: "added to cart", value:f});
                      }}
                      iconLeft={<ShoppingCartOutlinedIcon />}
                    />
                  </span>
                )}
              </Grid>
              <Grid item xs={7} className="py-2">
                <Checkbox
                  iconStyle={{ fill: "black" }}
                  id="mm-toggle-compare"
                  onClick={() => {
                    compareFls.some((c) => c.flID === f.flID)
                      ? setCompareFls((currentState) =>
                          currentState.filter((c) => c.flID !== f.flID)
                        )
                      : setCompareFls((currentState) =>
                          [...currentState, f].sort(
                            (a, b) => b.matchPercent - a.matchPercent
                          )
                        );
                  }}
                  checked={compareFls.some((c) => c.flID === f.flID)}
                />
                Add to Compare
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
