import React from "react";
import { Grid } from "@material-ui/core";

import Workload from "../legacy/Workload";
import WorkPreferences from "../legacy/WorkPreferences";

/* type SectionContents = {
  hasCurrentClients?: Expert['hasCurrentClients']
  monthlyHoursAvailable?: ExpertLegacyMetadata['monthlyHoursAvailable']
  paroIncomeStatus?: Expert['paroIncomeStatus']
  workPreferences: Partial<WorkPreference>
} */
/* 
export type WorkloadState = {
  hasCurrentClients?: Expert['hasCurrentClients']
  monthlyHoursAvailable?: ExpertLegacyMetadata['monthlyHoursAvailable']
  paroIncomeStatus?: Expert['paroIncomeStatus']
  workPreferences: Partial<WorkPreference>
}
 */
export const WorkloadAndPreferencesSection = (
  {
    hasCurrentClients,
    monthlyHoursAvailable,
    paroIncomeStatus,
    workPreferences,
  } /* : SectionContents */
) /* : JSX.Element */ => {
  return (
    <Grid
      container
      direction="row"
      spacing={10}
      alignItems="start"
      justify="center"
    >
      <Grid item xs={12} md={6}>
        <Workload
          workload={{
            workPreferences,
            hasCurrentClients,
            monthlyHoursAvailable,
            paroIncomeStatus,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <WorkPreferences workPreferences={workPreferences || {}} />
      </Grid>
    </Grid>
  );
};
