import { ArrowUpward, ArrowDownward } from "@material-ui/icons";

const Header = ({ children, onClick, selected, mode }) => {
  return (
    <th
      scope="col"
      className={`p-2 text-left text-xs font-medium tracking-wider ${
        selected ? "text-info" : "text-gray-500"
      }`}
    >
      <span className="flex">
        {children}
        <div onClick={onClick} className="cursor-pointer">
          {
            <div className="flex">
              <ArrowUpward
                fontSize="small"
                className={`${
                  selected && mode === "asc" ? "text-info" : "text-gray-500"
                }`}
              />
              <ArrowDownward
                fontSize="small"
                className={`${
                  selected && mode === "desc" ? "text-info" : "text-gray-500"
                }`}
              />
            </div>
          }
        </div>
      </span>
    </th>
  );
};
export default Header;
