import {
  getAvailability,
  getFreelancerDetails,
  getFreelancerUserInfo,
  getParoProjects,
  getVettingNotes,
  // sendEmails,
  sendNewEmails,
} from "../services/apiService";

export const getFreelancerDetailsFn = async (data) => {
  const { matchId, recommendedFreeLancers = [] } = data;
  const flIDList = recommendedFreeLancers.map((d) => d.flID);
  const responseArray = await Promise.all(
    flIDList.map((flID) => {
      return Promise.all([
        getFreelancerDetails(flID),
        getFreelancerUserInfo(flID),
        getVettingNotes(flID),
        getParoProjects(flID),
        getAvailability(flID),
      ]);
    })
  );

  const flData = responseArray.reduce((acc, cur) => {
    if (
      cur[0].data.data.getExpertByLegacyFreelancerId &&
      cur[1].data.data.getUserInfo &&
      cur[2].data.data.getFreelancerCandidateNotes &&
      cur[3].data.data.getParoProjects &&
      cur[4].data.data.getFreelancerProfile
    ) {
      return [
        ...acc,
        {
          FL_Name:
            cur[1].data.data.getUserInfo.firstName +
            " " +
            cur[1].data.data.getUserInfo.lastName,
          FL_Service:
            cur[0].data.data.getExpertByLegacyFreelancerId.legacyMetadata
              ?.primaryServiceLine,
          FL_Tagline:
            cur[0].data.data.getExpertByLegacyFreelancerId.personalSummary
              ?.whyFreelanceWork,
          Recent_Experience:
            cur[0].data.data.getExpertByLegacyFreelancerId.workExperiences[0]
              ?.description,
          Short_Bio:
            cur[0].data.data.getExpertByLegacyFreelancerId.personalSummary
              ?.aboutYourself,
          Vetting_Notes: cur[2].data.data.getFreelancerCandidateNotes,
          paroProjects: cur[3].data.data.getParoProjects,
          availability: cur[4].data.data.getFreelancerProfile,
          ...cur[0].data.data.getExpertByLegacyFreelancerId,
          ...cur[1].data.data.getUserInfo,
          feedback: 0,
          matchId: matchId,
        },
      ];
    } else {
      return [...acc];
    }
  }, []);

  const propFlData = flData.map((f) => {
    const rf = recommendedFreeLancers.find((d) => d.flID === f.id);
    return {
      ...rf,
      ...f
    };
  });
  // console.log(propFlData);
  return propFlData;
};

export const getFreelancerDetailsById = async (flID) => {
  const response = await Promise.all([
    getFreelancerDetails(flID),
    getFreelancerUserInfo(flID),
    getVettingNotes(flID),
    getParoProjects(flID),
    getAvailability(flID),
  ]);

  return {
    FL_Name:
      response[1].data.data.getUserInfo.firstName +
      " " +
      response[1].data.data.getUserInfo.lastName,
    FL_Service:
      response[0].data.data.getExpertByLegacyFreelancerId.legacyMetadata
        ?.primaryServiceLine,
    FL_Tagline:
      response[0].data.data.getExpertByLegacyFreelancerId.personalSummary
        ?.whyFreelanceWork,
    Recent_Experience:
      response[0].data.data.getExpertByLegacyFreelancerId.workExperiences[0]
        ?.description,
    Short_Bio:
      response[0].data.data.getExpertByLegacyFreelancerId.personalSummary
        ?.aboutYourself,
    Vetting_Notes: response[2].data.data.getFreelancerCandidateNotes,
    paroProjects: response[3].data.data.getParoProjects,
    availability: response[4].data.data.getFreelancerProfile,
    ...response[0].data.data.getExpertByLegacyFreelancerId,
    ...response[1].data.data.getUserInfo,
    feedback: 0,
    matchId: null,
  };
};

export const sendPlatformEmails = async (
  proposalId,
  freelancerIds,
  overrideFromMM = false,
  source = "Matching Application"
) => {
  let emailRequest = {
    proposalId: parseInt(proposalId),
    stage: "Proposed",
    sendFlInvite: true,
    overrideFromMM,
    // TODO COMM HUB CHANGES
    source
  };

  return await Promise.all(
    freelancerIds.map((flID) => {
      emailRequest = { ...emailRequest, freelancerId: parseInt(flID) };
      // TODO COMM HUB CHANGES
      return sendNewEmails(emailRequest);
      // return sendEmails(emailRequest);
    })
  );
};
