import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    minWidth: "15vw",
  },
}));

export default function SelectField({
  onChange,
  value = "",
  label,
  options,
  creatable,
  ...field
}) {
  const classes = useStyles();

  const filter = createFilterOptions(
    options.map((o) => ({ inputValue: o, title: o }))
  );
  const [selectedValue, setSelectedValue] = useState({
    inputValue: value,
    title: value,
  });

  useEffect(() => {
    if (typeof selectedValue === "string") {
      onChange(selectedValue);
    } else if (selectedValue && selectedValue.title) {
      onChange(selectedValue.title);
    } else {
      onChange("");
    }
  }, [onChange, selectedValue]);

  return (
    <Autocomplete
      {...field}
      value={selectedValue}
      options={options.map((o) => ({ inputValue: o, title: o }))}
      onChange={(_, newValue) => {
        if (typeof newValue === "string") {
          setSelectedValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setSelectedValue({
            title: newValue.inputValue,
          });
        } else {
          setSelectedValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "" && creatable) {
          filtered.push({
            inputValue: params.inputValue,
            title: `${params.inputValue} (new)`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={label}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option) => option.title}
      freeSolo
      className={classes.container}
      renderInput={(params) => (
        <TextField {...params} {...field} label={label} variant="outlined" />
      )}
    />
  );
}
