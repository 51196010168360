import React from "react";
import { Grid, Typography } from "@material-ui/core";

import { SectionBody } from "../styles/SectionBody";

/* type SectionContents = {
  title: string
} */
const MissingItem = ({ title } /* : SectionContents */) /* : JSX.Element */ => {
  return (
    <SectionBody data-testid="sectionBody">
      <Grid item>
        <Typography>Missing {title}</Typography>
      </Grid>
    </SectionBody>
  );
};

export default MissingItem;
