import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Tooltip } from "@material-ui/core";
import { submitReactivateFeedback } from "../services/apiService";
import { useSnackbar } from "notistack";
import {
  updateProposalMatchCount,
  updateCartCheckout,
} from "../services/apiService";
import { Auth0Context } from "../contexts/Auth0Context";
import { CartContext } from "./CartContext";
import { REACTIVATION_OPTIONS } from "../data/appConstants";
import FeedbackFormWithSelect from "./FeedbackFormWithSelect";
const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    "&> button": {
      padding: theme.spacing(0.75),
      minWidth: theme.spacing(5),
    },
  },
}));

export default function ReactivateFeedbackForm({
  proposalId,
  setProposalDisabled,
}) {
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { cartContext, globalMatchCartContext } = useContext(CartContext);
  const [, setCart] = cartContext;
  const [, setMatchIds] = globalMatchCartContext;

  const handleClose = () => {
    setShow(false);
  };
  const { user } = useContext(Auth0Context);

  const handleShow = () => setShow(true);

  const onSubmitFn = (data, other = false) => {
    submitReactivateFeedback({
      proposalId,
      userId: user.auth0UserId,
      reason:
        "" +
        (other
          ? [...data.predefinedReasons, data.otherReason]
          : data.predefinedReasons),
      comments: data.comments,
    })
      .then(() => {
        enqueueSnackbar(`Proposal ${proposalId} has been reactivated!`, {
          variant: "success",
        });

        updateProposalMatchCount(proposalId, user.auth0UserId, "reset");
        updateCartCheckout(proposalId, user.auth0UserId, true);
        setCart([]);
        setMatchIds([]);
        setProposalDisabled(false);
        handleClose();
      })
      .catch(() => {
        enqueueSnackbar(`Unable to reactivate Proposal ${proposalId}!`, {
          variant: "error",
        });
        handleClose();
      });
  };

  const classes = useStyles();

  return (
    <div className={classes.buttonDiv}>
      <Tooltip title="Reactivate Proposal Card" aria-label="reactivate">
        <Button
          onClick={handleShow}
          id="mm-proposal-reactivate"
          variant="contained"
          color="primary"
          size="small"
        >
          Re-Activate
        </Button>
      </Tooltip>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Reactivate Proposal Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FeedbackFormWithSelect
            onSubmitFn={onSubmitFn}
            options={REACTIVATION_OPTIONS}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
