import "./App.css";
import { Switch, Redirect } from "react-router-dom";
import ProposalsList from "./pages/ProposalsList";
import ProposalViewer from "./pages/ProposalViewer";
import Checkout from "./pages/Checkout";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { IS_PROD_ENVIRONMENT } from "./data/appConstants";
import { useEffect } from "react";
import "./tailwind.css";
import FindFreelancers from "./pages/FindFreelancers";
import FreelancerView from "./pages/FreelancerView";

function App() {
  useEffect(() => {
    if (!IS_PROD_ENVIRONMENT) {
      console.log("This is not a prod environment");
    }
  }, []);

  return (
    <div className="App">
      <Switch>
        <AuthenticatedRoute exact path="/" component={ProposalsList} />
        <AuthenticatedRoute
          exact
          path="/proposal/:proposalId"
          component={ProposalViewer}
        />
        <AuthenticatedRoute
          exact
          path="/find-a-freelancer/:proposalId"
          component={FindFreelancers}
        />
        <AuthenticatedRoute
          exact
          path="/proposal/:proposalId/checkout"
          component={Checkout}
        />
        <AuthenticatedRoute
          exact
          path="/freelancer-view/:freelancerId"
          component={FreelancerView}
        />
        {/* Route Not Found */}
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
}

export default App;
