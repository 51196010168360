import React, { useState } from "react";
import { getGpt3Suggestion } from "../services/apiService";
import { Button, Heading, Select, Text } from "@paro.io/base-ui";
import { COMPANY_SIZE_LIST, INDUSTRY_LIST } from "../data/appConstants";

export default function Gpt3Component({ defaultValues, notes, setValue }) {
  return (
    <>
      <Heading size="h5">Optimizations</Heading>
      <div className="mt-1">
        <Gpt3TitleComponent
          proposalId={defaultValues.proposalId}
          notes={notes}
          numberOfEmployees={
            defaultValues.client?.firmographics?.numberOfEmployees
          }
          industry={defaultValues.client?.firmographics?.industry}
          setTitleValue={(v) => setValue("name", v)}
          originalTitle={defaultValues.name}
        />
        <Gpt3DescComponent
          proposalId={defaultValues.proposalId}
          notes={notes}
          setDescValue={(v) => setValue("description", v)}
          originalDescription={defaultValues.description}
        />
      </div>
    </>
  );
}

function Gpt3TitleComponent({
  proposalId,
  notes,
  numberOfEmployees,
  industry,
  setTitleValue,
  originalTitle,
}) {
  const companySizeOptions = COMPANY_SIZE_LIST.map((c) => ({
    label: c,
    value: c,
  }));

  const industryOptionsFirmographics = INDUSTRY_LIST.map((i) => ({
    label: i,
    value: i,
  }));

  const [companySizeValue, setCompanySizeValue] = useState(numberOfEmployees);
  const [industryValue, setIndustryValue] = useState(industry);

  const [generatingTitle, setGeneratingTitle] = useState(false);

  const [optimizeTitle, setOptimizeTitle] = useState(false);

  const [titleError, setTitleError] = useState(false);
  const [titleGenerated, setTitleGenerated] = useState(null);
  const [useTitle, setUseTitle] = useState(false);
  const getTitleSuggestion = async (data) => {
    const res = await getGpt3Suggestion(data);
    const suggestion = res.data.data.getGpt3Suggestion;

    if (!suggestion) {
      return setTitleError("Failed to fetch optimized title");
    }
    if (
      suggestion.Output ===
      "Insufficient Information to generate Proposal Card Title"
    ) {
      return setTitleError(
        "Insufficient Information to generate Proposal Card Title. Please update Call Notes."
      );
    }
    setTitleGenerated(suggestion?.Output);
  };

  return (
    <>
      <Text size="lg">Proposal Title</Text>

      {!optimizeTitle && (
        <div>
          <Button
            label="Optimize Title"
            color="success"
            onClick={() => setOptimizeTitle(true)}
          ></Button>
        </div>
      )}

      {optimizeTitle && !titleGenerated && (
        <div className="mb-3">
          <Select
            label="Company Size*"
            options={companySizeOptions}
            value={companySizeValue}
            onChange={(event) => setCompanySizeValue(event.target.value)}
            className="mb-3"
          ></Select>
          <Select
            label="Main Industry*"
            options={industryOptionsFirmographics}
            value={industryValue}
            onChange={(event) => setIndustryValue(event.target.value)}
            className="mb-3"
          ></Select>

          {titleError && !generatingTitle && (
            <>
              <Text className="bg-gray-100 p-2 mb-3 rounded border-2">
                {titleError}
              </Text>
            </>
          )}
          {companySizeValue && industryValue ? (
            <Button
              label="Generate"
              color="success"
              isLoading={generatingTitle}
              onClick={async () => {
                setGeneratingTitle(true);
                await getTitleSuggestion({
                  Indicator: "TITLE_GENERATION",
                  Segment: companySizeValue,
                  Industry: industryValue,
                  Notes: notes,
                  proposalId: proposalId,
                  title: originalTitle
                });
                setGeneratingTitle(false);
              }}
            ></Button>
          ) : (
            <Button label="Generate" color="success" disabled></Button>
          )}
        </div>
      )}

      {titleGenerated && !useTitle && (
        <>
          <Text className="bg-primary-lighter p-2 mb-3">{titleGenerated}</Text>
          <Button
            label="Use Title"
            color="success"
            onClick={() => {
              setTitleValue(titleGenerated);
              setUseTitle(true);
            }}
          ></Button>
        </>
      )}

      {titleGenerated && useTitle && (
        <div>
          <Button
            label="Reset to Original"
            onClick={() => {
              setTitleValue(originalTitle);
              setUseTitle(false);
            }}
          ></Button>
        </div>
      )}
    </>
  );
}

function Gpt3DescComponent({proposalId, notes, setDescValue, originalDescription }) {
  const [generatingDescription, setGeneratingDescription] = useState(false);
  const [descError, setDescError] = useState(false);

  const [descGenerated, setDescGenerated] = useState(null);
  const [useDesc, setUseDesc] = useState(false);

  const getDescSuggestion = async (data) => {
    const res = await getGpt3Suggestion(data);
    const suggestion = res.data.data.getGpt3Suggestion;

    if (!suggestion) {
      return setDescError("Failed to fetch optimized description");
    }
    if (
      suggestion.Output === "Insufficient Information to generate Proposal Card"
    ) {
      return setDescError(
        "Insufficient Information to generate Proposal Card Description. Please update Call Notes."
      );
    }
    setDescGenerated(suggestion?.Output);
  };

  return (
    <>
      <Text size="lg">Proposal Description</Text>

      {descError && !generatingDescription && (
        <>
          <Text className="bg-gray-100 p-2 mb-3 rounded border-2">
            {descError}
          </Text>
        </>
      )}
      {!descGenerated && (
        <div>
          <Button
            label="Optimize Description"
            color="success"
            isLoading={generatingDescription}
            onClick={async () => {
              setGeneratingDescription(true);
              await getDescSuggestion({
                proposalId: proposalId,
                Indicator: "PROPOSAL_GENERATION",
                Segment: "",
                Notes: notes,
                Industry: "",
                description: originalDescription
              });
              setGeneratingDescription(false);
              // setOptimizeDesc(true);
            }}
          ></Button>
        </div>
      )}

      {descGenerated && !useDesc && (
        <>
          <Text className="bg-primary-lighter p-2 mb-3">{descGenerated}</Text>
          <Button
            label="Use Description"
            color="success"
            onClick={() => {
              setDescValue(descGenerated);
              setUseDesc(true);
            }}
          ></Button>
        </>
      )}

      {descGenerated && useDesc && (
        <div>
          <Button
            label="Reset to Original"
            onClick={() => {
              setDescValue(originalDescription);
              setUseDesc(false);
            }}
          ></Button>
        </div>
      )}
    </>
  );
}
