import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { Auth0Context } from "../contexts/Auth0Context";
import { CartContext } from "./CartContext";
import { saveProposalRematchReason } from "../services/apiService";
import FeedbackFormComponent from "./FeedbackFormComponent";
import { REMATCH_REASONS } from "../data/appConstants";

/* const useStyles = makeStyles((theme) => ({
  textArea: {
    minWidth: "100%",
    padding: theme.spacing(1),
  },

  selected: {
    backgroundColor: "#000 !important",
    color: "#fff",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
})); */

export default function RematchFeedbackForm({ showRematchForm, runMatchFn }) {
  const {
    // setValue,
    control,
    formState,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: "onChange",
  });
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);

  const { matchContext } = useContext(CartContext);
  const [matchId] = matchContext;
  const { proposalId } = useParams();
  const { user } = useContext(Auth0Context);
  const { firstName, lastName } =
    user[`${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`];
  const userName = firstName + " " + lastName;

  const onSubmitFn = (data, other = false) => {
    // feedback API goes here

    saveProposalRematchReason({
      reason:
        showRematchForm === 1
          ? "" +
            (other
              ? [...data.predefinedReasons, data.otherReason]
              : data.predefinedReasons)
          : "Other",
      comments: data.comments,
      matchId,
      proposalId: parseInt(proposalId),
      userId: user.auth0UserId,
      userName,
    });
    setSuccessfullySubmitted(true);
    runMatchFn();
  };

  /* const classes = useStyles(); */

  return (
    <Box p={4} m={2}>
      {showRematchForm === 1 && (
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <Grid container justifyContent="center" spacing={3}>
              {/*   <Grid item xs={12}>
                <Typography variant="h5" className="font-weight-bold ml-2">
                  Please provide a reason for the rematch
                </Typography>
              </Grid> */}
              <FeedbackFormComponent
                onSubmitFn={onSubmitFn}
                question="Please provide a reason for the rematch"
                options={REMATCH_REASONS}
                newReasonPlaceholder="i.e. Lack of experience, etc."
                submitButtonText={"SEND & REMATCH"}
                commentsHeading="What other reason prompted you to rematch"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {/*   <Grid item xs={12} container>
                <Grid item>
                  <Controller
                    name="reason"
                    control={control}
                    render={({ field }) => (
                      <ChipArray
                        chips={options}
                        onSelectChips={(data) => setValue("reason", "" + data)}
                        {...register("reason", {
                          required: !other,
                          validate: (val) => other || val.length > 0,
                        })}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    className={`${classes.chip} ${
                      other ? classes.selected : ""
                    }`}
                    label="Other"
                    onClick={() => setOther(!other)}
                  />
                </Grid>
                <Grid item xs={12} className="mx-3">
                  {errors.reason && (
                    <FormHelperText error>Select a Reason</FormHelperText>
                  )}
                </Grid>
              </Grid>
             {other && (
                  <> */}

      {/* <Grid item xs={12}>
                <Typography variant="body1">
                  What other reason prompted you to rematch*
                </Typography>
              </Grid> */}
      {/* <Grid item xs={12}>
                <Controller
                        name="comments"
                        control={control}
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            label="Comments *"
                            multiline
                            className={classes.textArea}
                            variant="outlined"
                            placeholder="Provide comments"
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                        rules={{ required: "Comments required" }}
                      /> 

                <Controller
                  name="comments"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      name="comments"
                      fullWidth
                      label="Reason"
                      variant="outlined"
                      placeholder="Comments"
                      {...register("comments", {
                        required: true,
                        validate: (val) => val && val.length >= 20,
                      })}
                      error={formState.isSubmitted && errors.comments}
                      helperText={
                        formState.isSubmitted && errors.comments
                          ? "Minimum 20 characters required"
                          : ""
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>*/}

      {/*   <Grid item xs={12} container direction="column">
            {!formState.isSubmitting && !successfullySubmitted && (
              <div>
                <Button type="submit" color="primary" variant="contained">
                  {"SEND & REMATCH"}
                </Button>
              </div>
            )}
          </Grid> */}

      {showRematchForm === 2 && (
        <form onSubmit={handleSubmit(onSubmitFn)} noValidate>
          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item>
              <Grid container justify="center" spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" className="font-bold ml-2">
                    Still can't find a good fit for this proposal?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className="ml-2">
                    Please explain below why the results previously provided
                    were not good matches for this proposal.
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} container>
                      <Grid item>
                        <Controller
                          name="reason"
                          control={control}
                          render={({ field }) => (
                            <ChipArray
                              chips={chipsData}
                              onSelectChips={(data) =>
                                setValue("reason", "" + data)
                              }
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          className={`${classes.chip} ${
                            other ? classes.selected : ""
                          }`}
                          label="Other"
                          onClick={() => setOther(!other)}
                        />
                      </Grid>
                    </Grid> */}
                {/*  {other && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            In a few words please specify the new reason
                          </Typography>
                        </Grid> */}
                <Grid item xs={12}>
                  <Controller
                    name="comments"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Comments *"
                        variant="outlined"
                        placeholder="Comments"
                        {...register("comments", {
                          required: true,
                          validate: (val) => val.length >= 20,
                        })}
                        error={formState.isSubmitted && errors.comments}
                        helperText={
                          formState.isSubmitted && errors.comments
                            ? "Minimum 20 characters required"
                            : ""
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} container direction="column">
              {!formState.isSubmitting && !successfullySubmitted && (
                <Button
                  id="mm-matchForm-submitRematch"
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {"SEND & REMATCH"}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
}
