import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import CustomSpinner from "../components/CustomSpinner";
import FreelancerCardById from "../components/FreelancerCardById";
import TopSection from "../components/TopSection";
import { getFreelancerDetailsById } from "../utils/platformService";

export default function FreelancerView() {
  const { freelancerId } = useParams();
  const history = useHistory();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  // const data = JSON.parse(localStorage.getItem("freelancerFullView"));
  // const proposalId = JSON.parse(localStorage.getItem("proposalId"));
  //   JSON.parse(localStorage.getItem("templates"));

  //   localStorage.removeItem("freelancerFullView");
  //   localStorage.removeItem("proposalId");
  //   localStorage.removeItem("templates");

  useEffect(() => {
    const getFreelancerFn = async () => {
      const response = await getFreelancerDetailsById(+freelancerId);
      setData(response);
      setLoading(false);
    };
    getFreelancerFn();
  }, [freelancerId]);

  if (!freelancerId) {
    return history.pushState("/");
  }

  if (loading) {
    return (
      <>
        <TopSection />
        <CustomSpinner>Fetching Freelancer Details</CustomSpinner>
      </>
    );
  }

  if (!data) {
    return (
      <>
        <TopSection />
        No data
      </>
    );
  }

  return (
    <>
      <TopSection />
      <FreelancerCardById data={data} />
    </>
  );
}
