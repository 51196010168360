import React, { useEffect, useState } from "react";
import { getProposalEfforts, getProposalTags } from "../services/apiService";
import MultiSelectFaFField from "./MultiSelectFaFField";

const formatOptions = (label, values) => {
  return [...new Set([...values])].map((s) => ({
    groupName: label,
    title: s,
  }));
};

export default function SelectFaFTags({ ...field }) {
  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    const promiseOptions = async () => {
      const responses = await Promise.all([
        getProposalEfforts(),
        getProposalTags(),
      ]);

      const serviceOptions = responses[0].data.data.getServiceLines.map(
        (s) => ({ groupName: "Service Lines", title: s })
      );
      setTagOptions((t) => [...t, ...serviceOptions]);

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "Softwares",
          responses[1].data.data.getTags.software.values
        ),
      ]);

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "Industries",
          responses[1].data.data.getTags.industries.values
        ),
      ]);

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "Skills",
          responses[1].data.data.getTags.skills.values
        ),
      ]);

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "Financial Tasks",
          responses[1].data.data.getTags.financialTasks.values
        ),
      ]);

      setTagOptions((t) => [
        ...t,
        ...formatOptions(
          "States",
          responses[1].data.data.getTags.states.values
        ),
      ]);
    };
    promiseOptions();
  }, []);

  return <MultiSelectFaFField {...field} creatable options={tagOptions} />;
}
