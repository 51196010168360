import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { Badge } from "@material-ui/core";
import { ShoppingCartOutlined } from "@material-ui/icons";
import { CartContext } from "./CartContext";
import { useHistory } from "react-router-dom";

// const useStyles = makeStyles({
//   drawer: {
//     width: 350,
//   },
//   modal: {
//     "& .modal-dialog": {
//       minWidth: "900px",
//     },
//   },
// });

export default function CartDrawer({ proposalId }) {
  const [cart] = useContext(CartContext).cartContext;

  const history = useHistory();

  return (
    <div>
      <Badge badgeContent={cart.length} color="error">
        <Button
          id="mm-cart-open"
          variant="contained"
          onClick={() => history.push(`/proposal/${proposalId}/checkout`)}
        >
          <ShoppingCartOutlined />
        </Button>
      </Badge>
    </div>
  );
}
