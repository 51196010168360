import React, { useEffect, useState, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import axios from "axios";
import { hotjar } from "react-hotjar";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const USER_METADATA_URI = `${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`;

export const Auth0Context = React.createContext({});

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({ ...initOptions });
      setAuth0(auth0FromHook);
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }
      const isAuthenticated = await auth0FromHook.isAuthenticated();
      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        const token = await auth0FromHook
          .getIdTokenClaims()
          .then((claims) => claims.__raw);
        const auth0UserId = user[USER_METADATA_URI].userId;
        // TODO for axios, move into ApolloClientProvider for graphQL
        axios.defaults.headers.common["auth"] = token ? `Bearer ${token}` : "";

        setUser({ ...user, auth0UserId, token });
        
        const { firstName, lastName } = user[USER_METADATA_URI];
        const name = firstName + " " + lastName;
        const HJID = Number(2855544);
        const HJSV = Number(6)
        hotjar.initialize(HJID, HJSV);
        const userId = user?.email || null;
        window.hj('identify', userId, {
            'name': name,
            'salesRepId': auth0UserId
        });
      }
      setIsAuthenticated(isAuthenticated);
      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);
  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };
  const handleRedirectCallback = async (url) => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    const token = await auth0Client
      .getIdTokenClaims()
      .then((claims) => claims.__raw);
    const auth0UserId = user[USER_METADATA_URI].userId;
    setLoading(false);
    setUser({ ...user, auth0UserId, token });
    setIsAuthenticated(true);
  };
  return React.createElement(
    Auth0Context.Provider,
    {
      value: {
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) =>
          auth0Client === null || auth0Client === void 0
            ? void 0
            : auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) =>
          auth0Client === null || auth0Client === void 0
            ? void 0
            : auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) =>
          auth0Client === null || auth0Client === void 0
            ? void 0
            : auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) =>
          auth0Client === null || auth0Client === void 0
            ? void 0
            : auth0Client.getTokenWithPopup(...p),
        logout: (...p) =>
          auth0Client === null || auth0Client === void 0
            ? void 0
            : auth0Client.logout({ ...p, returnTo: window.location.origin }),
      },
    },
    children
  );
};
