/* import { ApolloError } from '@apollo/client/errors/ApolloError'
import { ErrorResponse } from '@apollo/client/link/error'

import { Auth0User } from "../contexts/Auth0Context"; */
import * as Sentry from "@sentry/react";
import isEqual from "lodash/isEqual";

export const isObjectEmpty = (
  obj /* : Record<string, unknown> */
) /* : boolean */ => {
  return JSON.stringify(obj) === "{}";
};

const isNullOrUndefined = (
  item /* : string | null | number | undefined | boolean */
) /* : boolean */ => {
  if (typeof item === "boolean") {
    item = true;
  }
  if (item === 0) {
    return true;
  }
  return !item;
};

export const areAllItemsNull = (
  array /* : Array<string | null | number | undefined | boolean> */
) /* : boolean */ => {
  return array.every(isNullOrUndefined);
};
export const submitForm = async (
  submittedFormInput /* : Record<string, unknown> */,
  originalFormInput /* : Record<string, unknown> */,
  /*   // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
  mutation,
  expertId /* : string */,
  closeModal = (submittedFormInput /* ? */) => {
    return submittedFormInput;
  }
) /* : Promise<ApolloError | undefined | void> */ => {
  const inputChanged = !isEqual(submittedFormInput, originalFormInput);
  if (inputChanged) {
    if (!submittedFormInput.id) {
      delete submittedFormInput.id;
    }
    return mutation({
      variables: {
        expertId,
        input: submittedFormInput,
      },
    })
      .then(() => {
        const updatedInput = submittedFormInput?.certificates
          ? submittedFormInput.certificates
          : submittedFormInput?.languages
          ? submittedFormInput.languages
          : submittedFormInput;
        return closeModal(updatedInput);
      })
      .catch((error) => {
        return error;
      });
  } else {
    return closeModal();
  }
};

export const manuallyCaptureSentryError = (
  type /* : string */,
  message /* : string */,
  context /* : Record<string, unknown> */,
  user /* : Auth0User */
) /* : void */ => {
  Sentry.withScope(function (scope) {
    scope.setUser(user);
    Sentry.captureEvent({
      message: message,
      exception: {
        values: [{ type: type, value: message }],
      },
      contexts: { context },
    });
  });
};
const acceptableErrors = ["ProfileReview Not Found"];
export const handleErrors = (
  { graphQLErrors, networkError, response, operation } /* : ErrorResponse */,
  user /* : Auth0User */
) /* : void */ => {
  const context = {
    variables: JSON.stringify(operation?.variables),
    operationName: operation?.operationName,
  };
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (!acceptableErrors.includes(message)) {
        manuallyCaptureSentryError("GraphqlError", message, context, user);
      }
    });
  }
  if (networkError) {
    manuallyCaptureSentryError(
      "NetworkError",
      JSON.stringify(networkError),
      context,
      user
    );
  }

  if (response) {
    response.errors = undefined;
  }
};

export const WHITE_LABEL_TOGGLE_ACCESS = [6, 29633, 32908];
