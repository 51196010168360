import React from "react";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

import { Tags } from "../styles/Tags";
/* 
type SectionContents = {
  tags: string[]
  multiLine: boolean
  tagsExpected: boolean
  isTopTags?: boolean
}
 */
const ProfileTags = styled(Tags)`
  margin: 0 5px 8px 0;
`;

const TagsSection = (
  { multiLine, tags = [], tagsExpected, isTopTags } /* : SectionContents */
) /* : JSX.Element */ => {
  const missingTagMessage = isTopTags
    ? "Expert has not added top tags. Ask them to update their profile!"
    : "Missing tags";
  if (tags?.length < 1) {
    return (
      <Grid container item>
        <Typography variant="body2">
          {tagsExpected ? missingTagMessage : ""}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      {tags?.map((tag, index) => {
        return multiLine ? (
          <Grid container item key={index}>
            <ProfileTags key={index} label={tag} size="small" />
          </Grid>
        ) : (
          <ProfileTags key={index} label={tag} size="small" />
        );
      })}
    </>
  );
};
export default TagsSection;
