import React from 'react'
import omit from 'lodash/omit'
import styled from 'styled-components'

import colors from '../shared/constants/colors'

export const SectionTitle = styled((props) => {
  return <div {...omit(props, SectionTitle.OmitProps)} />
})`
  font-family: Roboto-Bold;
  font-size: 20px;
  color: ${colors.paroBlack};
  padding: 23px 0px 15.5px 0px;
  ${({ noBottomPadding }) => {
    return noBottomPadding && 'padding-bottom: 0 !important;'
  }}
`

SectionTitle.OmitProps = [
  'noBottomPadding'
]
