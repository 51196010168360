import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import AccordionHeader from "./AccordionHeader";
import Overview from "./legacy/Overview";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    padding: theme.spacing(1),
  },
}));

export default function AboutFreelancerCard(props) {
  const classes = useStyles();
  const { freelancer, dragIndicator, checkbox, widget, index, handleChange } =
    props;

  return (
    <Accordion className={`mt-3 mb-2 ${classes.accordionRoot}`} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-label="Expand"
        id={index}
      >
        <AccordionHeader
          title="OVERVIEW"
          widget={widget}
          handleChange={handleChange}
          dragIndicator={dragIndicator}
          checkbox={checkbox}
        />
      </AccordionSummary>
      <AccordionDetails className="pt-0">
        <Overview
          firstName={freelancer?.firstName}
          personalSummary={freelancer.personalSummary || {}}
        />
      </AccordionDetails>
    </Accordion>
  );
}
