import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";

import { Text, Heading, Button } from "@paro.io/base-ui";
import { IconChevronUp } from "@paro.io/base-icons";

import InfoButton from "./InfoButton";
import { ProjectLogo } from "./ProjectLogo";

import { formatDateAsMDY } from "../utils/dateService";

import {
  convertEnumToDisplayFormat,
  convertBooleanToString,
  getSingleIndustryTag,
} from "./legacy/shared/displayConversions";
import { isParoProject } from "./legacy/MinimalProjectInformation";
import { LineBreakTypography } from "./legacy/styles/LineBreakTypography";
import { TagsSectionTruncate } from "./TagsSectionTruncate";
import dayjs from "dayjs";

const DisplayParoProjectValueOrFormattedEmptySpace = (
  { paroProjectValue } /* : {
  paroProjectValue?: string | null,
} */
) => {
  return !paroProjectValue ? (
    <div></div>
  ) : (
    <Text size="md">{paroProjectValue}</Text>
  );
};

const DisplayFundingSources = (
  { fundingSources } /* : {
  fundingSources: string[],
} */
) => {
  return fundingSources.length === 0 ? (
    <div />
  ) : (
    <>
      {fundingSources.map((fundingSource, index) => {
        return (
          <div key={index}>
            <Text size="md">
              {convertEnumToDisplayFormat(fundingSource, true)}
            </Text>
          </div>
        );
      })}
    </>
  );
};

const ProjectInfoFundingSources = (
  { fundingSources } /* : {
  fundingSources?: string[],
} */
) => {
  const isNull = !fundingSources || fundingSources.length === 0;
  return isNull ? (
    <Text size="md">Missing funding sources</Text>
  ) : (
    <DisplayFundingSources fundingSources={fundingSources || []} />
  );
};

const onsiteEnumDisplayMap = {
  LOCAL: "Yes, locally",
  TRAVELED: "Yes, traveled",
  REMOTE: "No, remote",
};

export default function ProjectInfoScoreCard({
  projects,
  proposal,
  freelancer,
}) {
  const [count, setCount] = useState(Math.min(projects.length, 3));

  const informationProject =
    "Relevant projects are projects specific experiences that are aligned to proposal requirements";
  return (
    <div className="bg-white px-5 py-5">
      <div className="px-2">
        <span className="flex">
          <Heading size="h5">RELEVANT PROJECTS</Heading>
          <InfoButton information={informationProject} />
        </span>

        <div className="w-full max-w p-2 bg-white rounded-2xl">
          {projects
            .sort((a, b) =>
              dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? 1 : -1
            )
            .slice(0, count)
            .map((item, i) => (
              <div className="py-2">
                <ProjectInformationRow project={item} defaultOpen={i <= 0} />
                <hr />
              </div>
            ))}
          <span className="px-4 text-muted">
            Showing {count} of {projects.length}
          </span>
          {count < projects.length && (
            <Button
              className="px-4 text-muted"
              onClick={() => setCount(Math.min(count + 3, projects.length))}
              label="View More"
            />
          )}
        </div>
      </div>
    </div>
  );
}

const ProjectInformationRow = ({
  project: {
    title,
    companyRevenueModel,
    description,
    financialTags,
    industryTags,
    fundingSources,
    isInternational,
    softwareTags,
    startDate,
    endDate,
    annualCompanyRevenueRange,
    companySize,
    companyType,
    onsite,
    __typename,
  },
  defaultOpen,
}) => {
  const thisIsAParoProject = isParoProject(__typename);

  const tasksAccomplished = financialTags || [];

  const isInternationalIsNull = !(
    typeof isInternational === "boolean" ||
    isInternational === "true" ||
    isInternational === "false"
  );

  const onsiteDisplayValue =
    onsite && onsiteEnumDisplayMap[onsite]
      ? onsiteEnumDisplayMap[onsite]
      : null;

  const singleIndustryTag = getSingleIndustryTag(
    industryTags,
    thisIsAParoProject
  );

  const lessThanThreeTagsSpacing =
    tasksAccomplished.length === 1 ? 4 : tasksAccomplished.length === 2 ? 2 : 5;

  const ProjectDescription = (
    { description } /* : { description?: string } */
  ) => {
    return !description ? (
      <Text size="md">Missing description</Text>
    ) : (
      <LineBreakTypography
        dangerouslySetInnerHTML={{ __html: description }}
      ></LineBreakTypography>
    );
  };

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-white-100 rounded-lg">
            <span className=" inline-flex w-1/5">
              <>
                <span className="w-10 h-10">
                  {thisIsAParoProject ? "" : <ProjectLogo />}
                </span>
                <span className="ml-2">{title}</span>
              </>
            </span>
            <span className="w-1/5">
              {convertEnumToDisplayFormat(companySize)}
            </span>
            <span className="w-1/5 flex-start">
              <span>{industryTags}</span>
            </span>
            <span className="w-1/5 flex-start">
              {formatDateAsMDY(startDate)}
            </span>
            <IconChevronUp
              className={`${open ? "transform rotate-180" : ""} w-5 h-5`}
            />
          </Disclosure.Button>

          <Disclosure.Panel className="px-8 py-12 text-sm text-gray-500">
            <div class="w-full max-w flex justify-between gap-4">
              <span className="font-bold text-xl w-1/2">{title}</span>
              <span className="w-1/2 flex justify-end">{`${formatDateAsMDY(
                startDate
              )} - ${formatDateAsMDY(endDate)}`}</span>
            </div>
            <div class="w-full max-w flex justify-between gap-4">
              {thisIsAParoProject ? (
                <LineBreakTypography
                  dangerouslySetInnerHTML={{
                    __html: description || "",
                  }}
                ></LineBreakTypography>
              ) : (
                <ProjectDescription description={description} />
              )}
            </div>
            <div class=" w-full max-w grid grid-cols-2 gap-4">
              <div>
                <Heading size="h4">Tasks accomplished</Heading>
                <div className="py-1" />
                <TagsSectionTruncate
                  tags={tasksAccomplished}
                  tagsExpected={true}
                />
                {tasksAccomplished.length < 3 && (
                  <div py={lessThanThreeTagsSpacing} />
                )}

                <Heading size="h4">Software Used</Heading>
                <div className="py-0.5" />
                <TagsSectionTruncate
                  tags={softwareTags || []}
                  tagsExpected={!thisIsAParoProject}
                />
              </div>

              <div>
                <div className="score-color">
                  <div className="flex justify-between font-bold py-4">
                    {/* <span className="font-bold">{"client.name"}</span> */}
                  </div>
                  <div className="flex justify-between py-2 px-2 border-b-2">
                    <span className="text-base py-2">Annual Revenue</span>
                    <span>
                      {thisIsAParoProject ? (
                        <DisplayParoProjectValueOrFormattedEmptySpace
                          paroProjectValue={convertEnumToDisplayFormat(
                            annualCompanyRevenueRange
                          )}
                        />
                      ) : (
                        <Text size="md">
                          {convertEnumToDisplayFormat(
                            annualCompanyRevenueRange
                          ) || "Missing annual revenue"}
                        </Text>
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between py-2 px-2 border-b-2">
                    <span className="text-base py-2">Employees</span>
                    <span>
                      {thisIsAParoProject ? (
                        <DisplayParoProjectValueOrFormattedEmptySpace
                          paroProjectValue={convertEnumToDisplayFormat(
                            companySize
                          )}
                        />
                      ) : (
                        <Text size="md">
                          {convertEnumToDisplayFormat(companySize) ||
                            "Missing number of employees"}
                        </Text>
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between py-2 px-2 border-b-2">
                    <span className="text-base py-2">Industry</span>
                    <DisplayParoProjectValueOrFormattedEmptySpace
                      paroProjectValue={singleIndustryTag}
                    />
                  </div>
                  <div className="flex justify-between py-2 px-2 border-b-2">
                    <span className="text-base py-2">Entity Type</span>
                    <span>
                      {thisIsAParoProject ? (
                        <DisplayParoProjectValueOrFormattedEmptySpace
                          paroProjectValue={convertEnumToDisplayFormat(
                            companyType,
                            true
                          )}
                        />
                      ) : (
                        <Text size="md">
                          {convertEnumToDisplayFormat(companyType, true) ||
                            "Missing entity type"}
                        </Text>
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between py-2 px-2 border-b-2">
                    <span className="text-base py-2">Funding</span>
                    <span>
                      {thisIsAParoProject ? (
                        <DisplayFundingSources
                          fundingSources={fundingSources || []}
                        />
                      ) : (
                        <ProjectInfoFundingSources
                          fundingSources={fundingSources}
                        />
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between py-2 px-2 border-b-2">
                    <span className="text-base py-2">
                      Business/Revenue Model
                    </span>
                    <span>
                      {thisIsAParoProject ? (
                        <DisplayParoProjectValueOrFormattedEmptySpace
                          paroProjectValue={convertEnumToDisplayFormat(
                            companyRevenueModel
                          )}
                        />
                      ) : (
                        <Text size="md">
                          {convertEnumToDisplayFormat(companyRevenueModel) ||
                            "Missing Business/Revenue Model"}
                        </Text>
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between py-2 px-2 border-b-2">
                    <span className="text-base py-2">
                      International business
                    </span>
                    <span>
                      {thisIsAParoProject ? (
                        <DisplayParoProjectValueOrFormattedEmptySpace
                          paroProjectValue={
                            isInternationalIsNull
                              ? undefined
                              : convertBooleanToString(isInternational)
                          }
                        />
                      ) : (
                        <Text size="md">
                          {!isInternationalIsNull
                            ? convertBooleanToString(isInternational)
                            : "Missing International business"}
                        </Text>
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between py-2 px-2 border-b-2">
                    <span className="text-base py-2">Do you work onsite?</span>
                    <span>
                      {thisIsAParoProject ? (
                        <DisplayParoProjectValueOrFormattedEmptySpace
                          paroProjectValue={onsiteDisplayValue}
                        />
                      ) : (
                        <Text size="md">
                          {onsiteDisplayValue || "Missing onsite"}
                        </Text>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
