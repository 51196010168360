import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    minWidth: "20vw",
  },
}));

export default function MultiSelectFaFField({
  onChange,
  value = [],
  label,
  options = [],
  creatable,
  ...field
}) {
  const classes = useStyles();
  const filter = createFilterOptions(
    options.map((o) => ({
      inputValue: o.title,
      title: o.title,
      groupName: o.groupName,
      required: typeof o.required === "undefined" ? true : o.required,
    }))
  );
  const selectedValues = value.map((v) => ({
    inputValue: v.title,
    title: v.title,
    groupName: v.groupName,
    required: v.required,
  }));
  // useState(
  //   value.map((v) => ({
  //     inputValue: v.value,
  //     title: v.value,
  //     required: v.required,
  //   }))
  // );

  return (
    <Autocomplete
      {...field}
      value={selectedValues}
      renderTags={(options) => options.map(() => null)}
      multiple
      options={options
        .map((o) => ({
          inputValue: o.title,
          title: o.title,
          groupName: o.groupName,
          required: typeof o.required === "undefined" ? true : o.required,
        }))
        .filter(
          (s) =>
            !selectedValues.map((sv) => sv.inputValue).includes(s.inputValue)
        )}
      onChange={(_, newValue) => {
        const formatValues = newValue.map((v) => {
          return typeof v === "string"
            ? {
                title: v,
                inputValue: v,
                groupName: "*",
                required: true,
              }
            : {
                title: v.inputValue,
                inputValue: v.inputValue,
                groupName: v.groupName || "*",
                required: typeof v.required === "undefined" ? true : v.required,
              };
        });
        // setSelectedValues(formatValues);
        onChange(formatValues);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "" && creatable) {
          filtered.unshift({
            inputValue: params.inputValue,
            title: `Search Profiles with "${params.inputValue}"`,
            groupName: `Keyword Search`,
            required: true,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={label}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option) => option.title}
      freeSolo
      filterSelectedOptions
      className={classes.container}
      groupBy={(option) => option.groupName}
      renderInput={(params) => (
        <TextField {...params} {...field} label={label} variant="outlined" />
      )}
    />
  );
}
