import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

import { FullWidthGrid } from "../legacy/_global/ProfileLayout";
import TagsSection from "../legacy/_global/TagsSection";
import { SectionBody } from "../legacy/styles/SectionBody";
/* 
type SectionContents = {
  tags: string[]
  tagTitle: string
}
 */
const TagsBody = styled(SectionBody)`
  height: 100%;
`;
const TopTags = (
  { tags = [], tagTitle } /* : SectionContents */
) /* : JSX.Element */ => {
  return (
    <>
      <FullWidthGrid
        container
        alignItems="flex-start"
        justify="flex-end"
        direction="row"
      >
        <TagsBody>
          <Grid container item>
            <Box pb={2}>
              <Typography variant={"h6"}>
                <Typography>{tagTitle}</Typography>
              </Typography>
            </Box>
            <TagsSection
              tags={tags}
              multiLine={true}
              tagsExpected={true}
              isTopTags={true}
            />
          </Grid>
        </TagsBody>
      </FullWidthGrid>
    </>
  );
};

export default TopTags;
