import React from "react";
import { Heading } from "@paro.io/base-ui";
import InfoButton from "./InfoButton";

export default function FreelancerScoreCard({ freelancer }) {
  const informationScore =
    "This is a calculation based on the specific Matching Algo attributes listed below. The first number indicates candidates score and the second number indicates maximum score";
  return (
    <div className="bg-white px-5 py-5">
      <div className="px-2">
        <Heading size="h4">SCORE BREAKDOWN:</Heading>
        {/*        <Heading size="h5">
          Why did freelancer name get this score?
        </Heading>
        <Text size="md">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </Text> */}
        <span className="flex">
          <Heading size="h5">How points were assigned?</Heading>
          <InfoButton information={informationScore} />
        </span>
        <div className="score-color">
          <div className="flex justify-between font-bold py-4">
            <span>Match</span>
            <span>Points</span>
          </div>
          {freelancer.scores
            .slice(0)
            .reverse()
            .map((s) => (
              <div className="py-2 px-2 border-b-2" key={s.label}>
                <div className="flex justify-between py-4">
                  <span className={s.label === `Total` ? `font-bold` : ``}>
                    {s.label}
                  </span>
                  <span className={s.label === `Total` ? `font-bold` : ``}>
                    {Math.round(s.score)}/{s.maxScore}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
