import { useContext, useState } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { ReactSortable } from "react-sortablejs";

import { useAuth0 } from "../contexts/Auth0Context";
import {
  saveFLProfileSettings,
  updateTemplateSelection,
} from "../services/apiService";
import { CartContext } from "./CartContext";
import WidgetSuggestion from "./WidgetSuggestion";

import AboutFreelancerCard from "./AboutFreelancerCard";
import CertificatesCard from "./CertificatesCard";
import EducationCard from "./EducationCard";
import EmploymentCard from "./EmploymentCard";
import ProjectsTemplateCard from "./ProjectsTemplateCard";
import WorkLoadPreferenceCard from "./WorkLoadPreferenceCard";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 80,
    height: 40,
  },
  checkbox: {},
  dragHandle: {
    cursor: "move",
    height: "100%",
    paddingTop: 9,
  },
  heading: {
    paddingTop: 9,
    fontWeight: 500,
  },
  boldFont: {
    fontWeight: 500,
  },
}));

export default function FreelancerProfileViewCreate(props) {
  const classes = useStyles();
  const { template } = props;
  const { flData } = props;

  const handleClose = () => {
    props.onClose("");
  };

  const [fields, setFields] = useState(template.fields);
  const { user } = useAuth0();
  const [templates, setTemplates] = useContext(CartContext).flViewContext;
  const templateNames = templates.map((t) => t.templateName);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleChange = (event, f) => {
    const updatedFields = [...fields];
    const index = fields.findIndex((field) => field.section === f.section);
    fields[index].checked = event.target.checked;

    setFields(updatedFields);
  };

  const onSave = async (data) => {
    props.onClose("saving");

    let fieldsData = fields.map((f) => ({
      section: f.section,
      checked: f.checked,
    }));

    const newTemplate = {
      ...data,
      aeId: user.auth0UserId,
      fields: fieldsData,
      description: "",
      selected: true,
    };

    const saveTemplate = {
      ...newTemplate,
      fields: JSON.stringify(fieldsData),
    };

    await saveFLProfileSettings(saveTemplate);

    let prevSelected = templates.find((t) => t.selected === true);
    if (prevSelected) {
      prevSelected.selected = false;
      await updateTemplateSelection(
        user.auth0UserId,
        prevSelected.templateName,
        prevSelected.selected
      );
    }

    templates.push(newTemplate);
    setTemplates(templates);

    props.setSelectedTemplate(newTemplate);
    props.onClose("");
  };
  return (
    <Box>
      <form
        onSubmit={handleSubmit(onSave)}
        className={classes.root}
        noValidate
        autoComplete="off"
      >
        <Grid container className="mb-4 mt-2">
          <Grid item xs={5}>
            <Controller
              name="templateName"
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  id="templateName"
                  variant="outlined"
                  placeholder="Enter a name for the new template"
                  size="small"
                  fullWidth
                  value={value}
                  {...register("templateName", {
                    required: true,
                    validate: (val) => !templateNames.includes(val),
                  })}
                  error={errors.templateName}
                  helperText={
                    errors.templateName
                      ? errors.templateName.type === "required"
                        ? "Template Name Required"
                        : "Template Name already exists"
                      : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={7} container justify="flex-end">
            <Button
              variant="contained"
              onClick={handleClose}
              className={`${classes.button}`}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={`ml-2 ${classes.button}`}
              type="submit"
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </form>
      <WidgetSuggestion />

      <div className="mb-5">
        <Typography className={`mb-2 ${classes.boldFont}`}>
          Select a section to be visible, drag a section to arrange them in your
          preffered order
        </Typography>
        <ReactSortable
          list={fields}
          setList={setFields}
          handle=".handle"
          animation={150}
        >
          {fields.map((f, index) => (
            <>
              {f.section.toLowerCase() === "about" && (
                <AboutFreelancerCard
                  freelancer={flData}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
              {/*   {f.section.toLowerCase() === "overview" && (
                <OverviewFreelancerCard
                  freelancer={flData}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )} */}
              {/* {f.section.toLowerCase() === "specialities" && (
                <SpecialitiesCard
                  skills={flData.personalSummary?.topFinancialSkills}
                  industries={flData.personalSummary?.topIndustries}
                  software={flData.personalSummary?.topSoftwareSkills}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )} */}
              {f.section.toLowerCase() === "work experience" && (
                <EmploymentCard
                  workExperiences={flData.workExperiences}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
              {f.section.toLowerCase() === "work and preferences" && (
                <WorkLoadPreferenceCard
                  freelancer={flData}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
              {f.section.toLowerCase() === "education" && (
                <EducationCard
                  education={flData.education}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
              {f.section.toLowerCase() === "accomplishments" && (
                <CertificatesCard
                  certificates={flData.certificates}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
              {f.section.toLowerCase() === "projects" && (
                <ProjectsTemplateCard
                  externalProjects={flData.externalProjects}
                  paroProjects={flData.paroProjects}
                  widget={f}
                  index={index}
                  handleChange={handleChange}
                  dragIndicator
                  checkbox
                />
              )}
            </>
          ))}
        </ReactSortable>
      </div>
    </Box>
  );
}
