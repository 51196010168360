import { Box, makeStyles, Typography } from "@material-ui/core";
import { SentimentDissatisfiedOutlined } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  notFound: {
    color: "#b71c1c",
    margin: theme.spacing(6),
    background: "white",
  },
}));
export default function DataNotFound({ text }) {
  const classes = useStyles();
  return (
    <Box m={5} p={5} className={classes.notFound}>
      <SentimentDissatisfiedOutlined className="mx-2" />
      <Typography variant="p" component="span">
        {text}
      </Typography>
    </Box>
  );
}
