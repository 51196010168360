import React from "react";
import { Box } from "@material-ui/core";

import LoadMoreSection from "./_global/LoadMoreSection";
import ThreeColumnLayout from "./_global/ThreeColumnLayout";
import {
  sortByEndAndStartDates,
  // StandardizedParoProject,
} from "./shared/displayConversions";
import ProjectCard from "./ProjectCard";

/* const createGroupsOfThreeProjects = (projects: (Partial<StandardizedParoProject> | Partial<ExternalProject> )[]): (Partial<StandardizedParoProject> | Partial<ExternalProject>)[][] => {
    projects = sortByEndAndStartDates(projects) as Partial<StandardizedParoProject>[] | Partial<ExternalProject>[]
    const groupsOfThreeProjects: (Partial<StandardizedParoProject> | Partial<ExternalProject> )[][] = []
  
    for (let index = 0; index < projects?.length; (index += 3)) {
      groupsOfThreeProjects.push(projects.slice(index, index + 3))
    }
  
    return groupsOfThreeProjects
  } */
const createGroupsOfThreeProjects = (projects) => {
  projects = sortByEndAndStartDates(projects);
  const groupsOfThreeProjects = [];

  for (let index = 0; index < projects?.length; index += 3) {
    groupsOfThreeProjects.push(projects.slice(index, index + 3));
  }

  return groupsOfThreeProjects;
};

/* type SectionContents = {
  projects: (Partial<ExternalProject> | StandardizedParoProject)[],
}; */

const ProjectsSection = (
  { projects } /* : SectionContents */
) /* : JSX.Element */ => {
  const [showProjectsGroupCount, setShowProjectsGroupCount] = React.useState(1);

  const groupsOfThreeProjects /* : (
    | Partial<StandardizedParoProject>
    | Partial<ExternalProject>
  )[][] */ = createGroupsOfThreeProjects(projects);

  const groupsOfThreeProjectCards /* : React.ReactNode[] */ = [];
  for (
    let groupIndex = 0;
    groupIndex < showProjectsGroupCount && groupsOfThreeProjects?.length > 0;
    groupIndex++
  ) {
    const firstProject =
      groupsOfThreeProjects[groupIndex].length > 0
        ? groupsOfThreeProjects[groupIndex][0]
        : null;
    const secondProject =
      groupsOfThreeProjects[groupIndex].length > 1
        ? groupsOfThreeProjects[groupIndex][1]
        : null;
    const thirdProject =
      groupsOfThreeProjects[groupIndex].length > 2
        ? groupsOfThreeProjects[groupIndex][2]
        : null;
    const isLastProjectSection = groupIndex === showProjectsGroupCount - 1;
    groupsOfThreeProjectCards.push(
      <Box
        data-testid="project_section"
        pb={isLastProjectSection ? 8 : 4}
        key={groupIndex}
      >
        <ThreeColumnLayout>
          {firstProject && (
            <ProjectCard
              project={firstProject}
              isFirstListedProject={groupIndex === 0}
            />
          )}
          {secondProject && <ProjectCard project={secondProject} />}
          {thirdProject && <ProjectCard project={thirdProject} />}
        </ThreeColumnLayout>
      </Box>
    );
  }

  return (
    <>
      <LoadMoreSection
        key={groupsOfThreeProjectCards.length}
        incrementShowCountBy={1}
        objects={groupsOfThreeProjects}
        sections={groupsOfThreeProjectCards}
        setShowCount={setShowProjectsGroupCount}
        showCount={showProjectsGroupCount}
        title={"Projects"}
      />
    </>
  );
};

export default ProjectsSection;
