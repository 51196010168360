import React, { useEffect, useRef } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";

import "trix";
import "trix/dist/trix.css";

const generateId = () => {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return "T" + uuid;
};

export default function TrixTextEditor({
  error,
  value,
  onChange,
  name,
  placeholder,
}) {
  const trixEditor = useRef();

  useEffect(() => {
    const element = trixEditor.current;

    element?.addEventListener("trix-change", () => {
      onChange(name, element.value, { shouldValidate: true });
    });
    return () => {
      const noop = () => {};

      element?.removeEventListener("trix-change", noop);
    };
  }, [name, onChange, trixEditor]);

  const id = generateId();

  return (
    <>
      <input type="hidden" id={`editor-${id}`} name={name} value={value} />
      <div id="blank-toolbar" hidden />
      {React.createElement("trix-editor", {
        placeholder,
        input: `editor-${id}`,
        contenteditable: true,
        role: "textbox",
        ref: trixEditor,
      })}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
}
