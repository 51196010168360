import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { NEW_WIDGETS } from "../data/appConstants";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: grey[100],
    padding: theme.spacing(1),
  },
}));

export default function NewWidgetGrid(props) {
  const classes = useStyles();

  return (
    <Paper className="p-4">
      <h4>NEW WIDGETS RELEASED</h4>
      <Grid container spacing={3}>
        {NEW_WIDGETS.map((item) => (
          <Grid key={item} item xs={4}>
            <Typography className={classes.content} align="center">
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
