import React from "react";
import styled from "styled-components";

import colors from "../shared/constants/colors";

export const SectionBody = styled((props) => {
  return <div {...props} />;
})`
  background-color: ${colors.white};
  border-radius: 2px;
  border: 1px Solid ${colors.lightGray};
  color: ${colors.paroBlack};
  font-family: Roboto;
  padding: 32.5px 42.5px 32.5px 42.5px;
  width: 100%;
}`;
