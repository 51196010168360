import React, { useContext, useState } from "react";

import PaperCard from "./PaperCard";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Paper,
  Tooltip,
  Card,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import PropTypes from "prop-types";
import { CartContext } from "./CartContext";

import { useSnackbar } from "notistack";
import NotesCard from "./NotesCard";
import { formatDateAsMDY } from "../utils/dateService";
import ProfileCard from "./ProfileCard";
import { grey } from "@material-ui/core/colors";
import FreelancerVote from "./FreelancerVote";
import FreelancerFullProfileView from "./FreelancerFullProfileView";
import {
  addSingleFlToCartFn,
  removeFreelancer,
  removeFromCartFn,
} from "../services/apiService";
import { Modal } from "react-bootstrap";
import FeedbackFormComponent from "./FeedbackFormComponent";
import { Auth0Context } from "../contexts/Auth0Context";
import { REMOVE_REASONS } from "../data/appConstants";
import Availability from "./legacy/Availability";

const useStyles = makeStyles((theme) => ({
  profileCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  notesPaper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  addDiv: { position: "relative", display: "flex" },
  addedIndicator: {
    position: "absolute",
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    color: grey[900],
    backgroundColor: "#6eedbf",
    padding: theme.spacing(0.5, 1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FreelancerCards({
  data,
  proposalNotes,
  proposalId,
  tabIndex,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [cart, setCart] = useContext(CartContext).cartContext;
  const [matchId] = useContext(CartContext).matchContext;
  const [showDetails, setShowDetails] = useState(null);

  const { user } = useContext(Auth0Context);
  const { firstName, lastName } =
    user[`${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`];
  const userName = firstName + " " + lastName;

  //const channel = useMemo(() => new BroadcastChannel("freelancer_profile"), [])
  const addToCart = async (f) => {
    const addPayload = {
      proposalId,
      userId: user.auth0UserId,
      matchId,
      recommendedFreeLancers: [
        { flID: f.flID, matchPercent: f.matchPercent, category: f.category },
      ],
    };
    await addSingleFlToCartFn(addPayload);

    enqueueSnackbar(`Added ${f.FL_Name} to Cart!`, {
      variant: "success",
    });
    setCart((currentState) => [...currentState, f]);
  };
  const removeFromCart = async (f) => {
    const removePayload = {
      proposalId,
      userId: user.auth0UserId,
      matchId,
      freelancerId: f.flID,
    };
    await removeFromCartFn(removePayload);

    enqueueSnackbar(`Removed ${f.FL_Name} from Cart!`, {
      variant: "warning",
    });

    setCart((currentState) => currentState.filter((c) => c.flID !== f.flID));
  };

  const handleShow = (f) => {
    setShowDetails(f);
  };

  const handleClose = () => {
    setShowDetails(null);
  };

  const onSubmitFn = (removeFlData, other = false) => {
    removeFreelancer({
      userId: user.auth0UserId,
      userName,
      freelancerId: data.flID,
      matchId,
      proposalId,
      reason:
        "" +
        (other
          ? [...removeFlData.predefinedReasons, removeFlData.otherReason]
          : removeFlData.predefinedReasons),
      comments: removeFlData.comments,
    })
      .then(() => {
        removeFromCart(data);
        handleClose();
      })
      .catch(() => {
        enqueueSnackbar(`Unable to remove Freelancer ${data.flID}!`, {
          variant: "error",
        });
        handleClose();
      });
  };

  const isAdded = cart.some((cartItem) => cartItem.flID === data.flID);
  const [value, setValue] = useState(tabIndex ? tabIndex : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const noteWithStage = data.Vetting_Notes.filter((note) => {
    return !!note.stageId;
  });
  const notesExistButNoneWithStage =
    data.Vetting_Notes.length > 1 && noteWithStage.length === 0;
  return (
    <>
      <Box className="mx-5">
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={7} className={classes.addDiv}>
              {isAdded && (
                <div className={classes.addedIndicator}>
                  <ShoppingCartOutlinedIcon fontSize="small" /> Added
                </div>
              )}
            </Grid>
            <Card className={classes.profileCard}>
              <Grid container>
                <Grid item xs={9}>
                  <ProfileCard
                    name={data.FL_Name}
                    imageUrl={data.imageUrl}
                    title={data.FL_Service}
                    tagline={data.FL_Tagline}
                    location={data.address?.city + ", " + data.address?.region}
                    // rating={4}
                    // joined={data.workExperiences[0].startDate}
                    defaultHourlyRate={data.legacyMetadata.defaultHourlyRate}
                    clientHourlyRate={data.legacyMetadata.hourlyRate}
                    goalHours={data.legacyMetadata.monthlyHoursAvailable}
                    availableHours={data.availability.availableHours}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={3}
                  alignItems="center"
                  alignContent="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <div className={`text-center`}>
                      {isAdded ? (
                        <Button
                          color="primary"
                          aria-label="remove"
                          variant="contained"
                          onClick={() => {
                            handleShow(data);
                            //channel.postMessage({text: "removed from cart", value: data});
                          }}
                        >
                          REMOVE
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          aria-label="add"
                          variant="contained"
                          onClick={() => {
                            addToCart(data);
                            //channel.postMessage({text: "added to cart", value: data});
                          }}
                        >
                          <ShoppingCartOutlinedIcon fontSize="small" />
                          ADD
                        </Button>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={`text-center`}>
                      <FreelancerVote
                        freelancerId={data.flID}
                        proposalId={proposalId}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                className="bg-white"
                onChange={handleChange}
                aria-label="Freelancer detailed information"
              >
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Availability" {...a11yProps(1)} />
                <Tab label="Notes" {...a11yProps(2)} />
                <Tab label="Proposal Notes" {...a11yProps(3)} />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
              <FreelancerFullProfileView data={data} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Availability
                // legacyFreelancerId={data.legacyFreelancerId}
                monthlyHoursAvailable={
                  data.legacyMetadata.monthlyHoursAvailable
                }
                availabilityCalculations={data.availability}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Paper className={classes.notesPaper}>
                <Grid container justify="flex-end">
                  <Tooltip
                    title={`In order to "Add a new note" open the Paro FL Profile notes view`}
                    aria-label="add new note"
                  >
                    <Typography
                      color="primary"
                      className="cursor-pointer"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_PARO_APP_URL}/internal/expert-profile/${data.flID}`,
                          "_blank"
                        )
                      }
                    >
                      + Add Notes in Platform
                    </Typography>
                  </Tooltip>
                </Grid>
                {notesExistButNoneWithStage && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      Stage: Legacy
                    </Grid>
                  </Grid>
                )}
                {data.Vetting_Notes.map((noteObj) => (
                  <NotesCard
                    time={`${formatDateAsMDY(noteObj.timestamp)}`}
                    amName={noteObj.accountManager.name}
                    notes={noteObj.note}
                    key={noteObj.id}
                    stageId={noteObj.stageId}
                    noteWithStage={noteWithStage}
                  />
                ))}
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <PaperCard paragraph={proposalNotes || "Missing Data"} />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
      <Modal show={!!showDetails} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Remove Freelancer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FeedbackFormComponent
            onSubmitFn={onSubmitFn}
            question="What are the reasons to remove this Freelancer?"
            options={REMOVE_REASONS}
            newReasonPlaceholder="i.e. Lack of experience, etc."
            submitButtonText="REMOVE"
            commentsHeading="Briefly explain why you wish to remove this freelancer"
            submittingText="Removing from cart"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
