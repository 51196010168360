import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  selected: {
    backgroundColor: "#000 !important",
    color: "#fff",
  },
}));

export default function ChipArray({ chips, onSelectChips }) {
  const [selectedChips, setSelectedChips] = useState([]);
  const selectChip = (label, select) => {
    if (select) {
      setSelectedChips((selectedChips) => [...selectedChips, label]);
    } else {
      setSelectedChips((selectedChips) =>
        selectedChips.filter((l) => l !== label)
      );
    }
  };

  useEffect(() => {
    onSelectChips(selectedChips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChips]);

  return (
    <>
      {chips.map((data) => {
        return (
          <React.Fragment key={data.key}>
            <CustomChip label={data.label} selectChip={selectChip} />
          </React.Fragment>
        );
      })}
    </>
  );
}
const CustomChip = ({ label, selectChip }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(false);
  const handleClick = () => {
    setSelected(!selected);
    selectChip(label, !selected);
  };

  return (
    <Chip
      className={`${classes.chip} ${selected ? classes.selected : ""}`}
      label={label}
      onClick={handleClick}
    />
  );
};
