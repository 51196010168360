import { useState } from "react";
import { Menu } from "@headlessui/react";
import { IconDotsVertical, IconUser } from "@paro.io/base-icons";
import FreelancerReportForm from "./FreelancerReportForm";

const MatchCardMenu = ({ freelancer, proposalId, showFullProfile }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
      anchorEl={anchorEl}
      id="match-card-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      <Menu.Button
        onClick={handleClick}
        className="inline-flex py-2 px-4 bg-gray-100 hover:bg-gray-200 text-primary font-bold text-center rounded cursor-pointer align-middle focus:outline-none focus:ring-offset-2 focus:ring-2 ring-gray-400"
      >
        <IconDotsVertical />
      </Menu.Button>
      <Menu.Items className="origin-top-left menu-dropdown absolute right-0 mt-2 min-w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
        <div className="py-1">
          <Menu.Item>
            <FreelancerReportForm
              freelancerId={freelancer.flID}
              proposalId={proposalId}
            />
          </Menu.Item>
          <Menu.Item>
            <button
              className={
                "text-gray-700 flex text-left px-4 py-2 text-sm w-full"
              }
              onClick={() => showFullProfile(freelancer, 0)}
              id="mm-matchFreelancer-fullProfile"
            >
              <IconUser /> <span className="mx-2">View Full Profile</span>
            </button>
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};
export default MatchCardMenu;
