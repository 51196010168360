import React, { useContext, Fragment, useState } from "react";

import {
  IconCheck,
  IconChevronUp,
  IconCog,
  IconEye,
  IconEyeOff,
  IconMinus,
  IconQuestionMarkCircle,
  IconX,
} from "@paro.io/base-icons";
import { Avatar, Button as BaseUiButton, Heading } from "@paro.io/base-ui";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import { CartContext } from "../components/CartContext";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import CompareCardMenu from "../components/CompareCardMenu";
import { paroProjectsStandardization } from "../components/legacy/shared/displayConversions";
import flatten from "lodash/flatten";
import { useForm } from "react-hook-form";

// import { useHistory } from "react-router-dom";

export default function FreelancerComparePage({ matchFormData }) {
  // const history = useHistory();
  const [compareFls, setCompareFls] = useContext(CartContext).compareFlsContext;
  const [searchList, setSearchList] = useState([]);
  const [fields, setFields] = useState(MANAGE_FIELDS);

  const { register, handleSubmit, reset } = useForm();

  const renderValue = (val) => {
    if (val === 1) {
      return (
        <>
          <Tooltip title="Perfect Match" arrow>
            <div className="w-5">
              <IconCheck className="text-green-info" />
            </div>
          </Tooltip>
        </>
      );
    }
    if (val === 0) {
      return (
        <>
          <Tooltip title="Partial Match" arrow>
            <div className="w-5">
              <IconMinus className="text-yellow-info" />
            </div>
          </Tooltip>
        </>
      );
    }
    if (val === -1) {
      return (
        <>
          <Tooltip title="No Match" arrow>
            <div className="w-5">
              <IconX className="text-red-info" />
            </div>
          </Tooltip>
        </>
      );
    }
    return <IconQuestionMarkCircle />;
  };

  const renderCompareValue = (freelancer, compareField) => {
    const val = freelancer.relevantFields.find(
      (d) => d.type === compareField
    )?.value;
    return renderValue(val);
  };

  const renderCompareArray = (freelancer, compareField, compareData) => {
    const val = freelancer.relevantFields.find(
      (d) => d.type === compareField && d.data === compareData
    )?.value;
    return renderValue(val);
  };

  const getSearchResult = (freelancer, searchTerm) => {
    const val = [
      ...freelancer?.softwareTags,
      ...freelancer?.industryTags,
      ...freelancer?.financialTags,
      ...flatten(
        freelancer?.workExperiences?.map((we) => [
          ...we?.softwareTags,
          ...we?.industryTags,
          ...we?.financialTags,
        ])
      ),
      ...flatten(
        [
          ...freelancer.externalProjects,
          ...paroProjectsStandardization(freelancer.paroProjects),
        ]?.map((project) => [
          ...project?.softwareTags,
          ...project?.industryTags,
          ...project?.financialTags,
        ])
      ),
      ...freelancer?.languages,
    ].some((el) => el.toLowerCase() === searchTerm.toLowerCase())
      ? 1
      : -1;
    return renderValue(val);
  };

  const toggleField = (name, shown) => {
    setFields((fields) =>
      fields.map((f) => (f.name === name ? { ...f, shown: !shown } : f))
    );
  };

  const onSubmitSearchTerm = ({ searchString }) => {
    setSearchList((list) => [...list, searchString]);
    reset();
  };

  return (
    <>
      <div className="px-8 md:px-16 lg:px-24 pb-250">
        <Heading size="h3">Freelancer Comparison</Heading>
        {compareFls.length > 0 ? (
          <div className="compare-table">
            <table className="bg-white table-auto w-full">
              <tbody id="summary">
                <tr>
                  <td className="p-2 h-20">
                    <div>&nbsp;</div>
                  </td>
                  <td className="p-2 h-20">&nbsp;</td>
                  {compareFls.map((f) => (
                    <td className="p-2 h-20">
                      <div className="flex justify-between">
                        <span>
                          <Avatar
                            name={f.FL_Name}
                            size="sm"
                            width="100%"
                            src={f.imageUrl}
                          />
                        </span>
                        <span>
                          <CompareCardMenu
                            freelancer={f}
                            proposalId={matchFormData.id}
                          />
                        </span>
                      </div>
                    </td>
                  ))}
                </tr>

                <tr>
                  <td colSpan="2" className="border-b p-2 h-20 font-bold">
                    <div>&nbsp;</div>
                    Requirements
                  </td>
                  <td className="p-2 border-b h-20">
                    <div className="font-bold">&nbsp;</div>
                    &nbsp;
                  </td>
                  {compareFls.map((f) => (
                    <td className="p-2 border-b h-20">
                      <div
                        className="font-bold cursor-pointer"
                        onClick={() => {
                          window.open(
                            window.location.origin +
                              "/freelancer-view/" +
                              f.flID,
                            "_blank"
                          );
                          // history.push("/freelancer-view/" + f.flID);
                        }}
                      >
                        {f.FL_Name}
                      </div>
                      {f.address?.city + ", " + f.address?.region}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="p-2 border-b">
                    <span className="text-dark font-weight-semibold jus">
                      % Match
                    </span>
                  </td>
                  <td className="p-2 border-b">
                    <span>-</span>
                  </td>
                  {compareFls.map((f) => (
                    <td className="p-2 border-b">
                      <span className="text-green-info font-bold">
                        {Math.round(f.matchPercent)}%
                      </span>
                    </td>
                  ))}
                </tr>

                {fields
                  .filter(
                    (field) => field.name === "Client Rate" && field.shown
                  )
                  .map((field) => (
                    <>
                      <tr>
                        <td className="p-2 border-b">
                          <span className="text-dark font-weight-semibold">
                            {field.name}
                          </span>
                        </td>
                        <td className="p-2 border-b">
                          <span className="text-dark font-weight-semibold">
                            {`$${matchFormData[field.matchKey]}`}
                          </span>
                        </td>
                        {compareFls.map((f) => (
                          <td className="p-2 border-b">
                            <span className="text-dark font-weight-semibold">
                              {`$${f.legacyMetadata.hourlyRate}`}
                            </span>
                          </td>
                        ))}
                      </tr>
                    </>
                  ))}

                {fields
                  .filter(
                    (field) =>
                      ["Service Line", "Effort"].includes(field.name) &&
                      field.shown
                  )
                  .map((field) => (
                    <>
                      <tr>
                        <td className="p-2 border-b">
                          <span className="text-dark font-weight-semibold">
                            {field.name}
                          </span>
                        </td>
                        <td className="p-2 border-b">
                          <span className="text-dark font-weight-semibold">
                            {`${matchFormData[field.matchKey]}`}
                          </span>
                        </td>
                        {compareFls.map((f) => (
                          <td className="p-2 border-b">
                            <span className="text-dark font-weight-semibold">
                              {renderCompareValue(f, field.name)}
                            </span>
                          </td>
                        ))}
                      </tr>
                    </>
                  ))}

                {fields
                  .filter(
                    (field) =>
                      ["Industry", "Software", "Skills"].includes(field.name) &&
                      field.shown
                  )
                  .map((field) => (
                    <>
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full py-2 text-left bg-white-100 rounded-lg">
                              <tr>
                                <td className={`p-2 ${open ? `` : `border-b`}`}>
                                  <span className="text-dark font-weight-semibold">
                                    {field.name}
                                  </span>
                                </td>
                                <td className={`p-2 ${open ? `` : `border-b`}`}>
                                  <span className="text-dark font-weight-semibold">
                                    <IconChevronUp
                                      className={`${
                                        open ? "transform rotate-180" : ""
                                      } w-5 h-5`}
                                    />
                                  </span>
                                </td>
                                {compareFls.map((f) => (
                                  <td
                                    className={`p-2 ${open ? `` : `border-b`}`}
                                  >
                                    <span className="text-dark font-weight-semibold">
                                      {renderCompareArray(
                                        f,
                                        "Category",
                                        field.name
                                      )}
                                    </span>
                                  </td>
                                ))}
                              </tr>
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-sm text-gray-500">
                              {compareFls[0].relevantFields
                                .filter((rf) => rf.type === field.name)
                                .map((item) => (
                                  <tr>
                                    <td className="px-2 py-0 border-b">
                                      &nbsp;
                                    </td>

                                    <td className="px-2 py-0 border-b">
                                      <Chip
                                        className="compare-chip"
                                        size="small"
                                        label={
                                          <span className="text-dark font-weight-semibold">
                                            {item.data}
                                          </span>
                                        }
                                      />
                                    </td>
                                    {compareFls.map((f) => (
                                      <td className="px-2 py-0 border-b">
                                        <span className="text-dark font-weight-semibold">
                                          {renderCompareArray(
                                            f,
                                            field.name,
                                            item.data
                                          )}
                                        </span>
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </>
                  ))}

                {searchList.map((item) => (
                  <tr>
                    <td className="p-2 border-b">&nbsp;</td>

                    <td className="p-2 border-b">
                      <Chip
                        className="compare-search-chip"
                        label={
                          <span className="flex justify-center items-center text-dark font-weight-semibold">
                            {item}
                            <div
                              onClick={() => {
                                setSearchList((list) =>
                                  list.filter((l) => l !== item)
                                );
                              }}
                              className="pointer ml-1"
                            >
                              <IconX size="sm" />
                            </div>
                          </span>
                        }
                      />
                    </td>
                    {compareFls.map((f) => (
                      <td className="p-2 border-b">
                        <span className="text-dark font-weight-semibold">
                          {getSearchResult(f, item)}
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}

                {fields
                  .filter(
                    (field) => "Global Search" === field.name && field.shown
                  )
                  .map((field) => (
                    <tr>
                      <td className="p-2 border-b">
                        <span className="text-dark font-weight-semibold">
                          {field.name}
                        </span>
                      </td>
                      <td className="p-2 border-b">
                        <span className="text-dark font-weight-semibold">
                          <form onSubmit={handleSubmit(onSubmitSearchTerm)}>
                            <input
                              className="border-2 p-4 rounded"
                              type="text"
                              defaultValue=""
                              placeholder="Enter Search Term"
                              {...register("searchString", { required: true })}
                            />
                          </form>
                        </span>
                      </td>
                      {compareFls.map((f) => (
                        <td className="p-2 border-b">&nbsp;</td>
                      ))}
                    </tr>
                  ))}

                <tr>
                  <td className="p-2 h-20">
                    <div className="w-full">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        {({ open }) => (
                          <>
                            <div className="menu-dropdown">
                              <Menu.Button className="inline-flex py-2 font-medium text-white rounded-md bg-opacity-20 hover:bg-opacity-30">
                                <BaseUiButton
                                  label="Manage Fields"
                                  iconLeft={<IconCog size="sm" />}
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              >
                                <div className="px-1 py-1 ">
                                  {fields.map((i) => (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <div
                                          className="flex justify-between pointer"
                                          onClick={() =>
                                            toggleField(i.name, i.shown)
                                          }
                                        >
                                          <span>
                                            <button
                                              className={
                                                "text-gray-700 block px-4 py-2 text-md"
                                              }
                                            >
                                              {i.name}
                                            </button>
                                          </span>
                                          <span
                                            className={
                                              "text-gray-700 block px-4 py-2 text-sm"
                                            }
                                          >
                                            {i.shown ? (
                                              <IconEye />
                                            ) : (
                                              <IconEyeOff />
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                  </td>
                  <td className="p-2 h-20">&nbsp;</td>
                  {compareFls.map((f) => (
                    <td className="p-2 h-20">
                      <BaseUiButton
                        onClick={() =>
                          setCompareFls((currentState) =>
                            currentState.filter((c) => c.flID !== f.flID)
                          )
                        }
                        label="Remove from Compare"
                        color="primary"
                        // iconLeft={<DeleteForeverOutlined fontSize="small" />}
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <Heading size="h5">No Freelancers added to Compare</Heading>
        )}
      </div>
    </>
  );
}

const MANAGE_FIELDS = [
  {
    name: "Client Rate",
    matchKey: "clientRate",
    shown: true,
  },
  {
    name: "Service Line",
    matchKey: "serviceLine",
    shown: true,
  },
  {
    name: "Effort",
    matchKey: "projectSize",
    shown: true,
  },
  {
    name: "Industry",
    shown: true,
  },
  {
    name: "Software",
    shown: true,
  },
  {
    name: "Skills",
    shown: true,
  },
  {
    name: "Global Search",
    shown: true,
  },
  // {
  //   name: "Years of Experience",
  //   shown: true,
  // },
  // {
  //   name: "Education",
  //   shown: true,
  // },
  // {
  //   name: "Certification",
  //   shown: true,
  // },
  // {
  //   name: "Client Services",
  //   shown: true,
  // },
  // {
  //   name: "Full Profile",
  //   shown: true,
  // },
  // {
  //   name: "Time Zone",
  //   shown: true,
  // },
];
