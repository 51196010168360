import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import AccordionHeader from "./AccordionHeader";
import { WorkloadAndPreferencesSection } from "./legacy/WorkloadAndPreferencesSection";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 2, 0),
    width: "100%",
  },
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default function WorkLoadPreferenceCard({
  widget,
  freelancer,
  handleChange,
  dragIndicator,
  checkbox,
}) {
  const classes = useStyles();
  return (
    <Accordion className={`mt-3 mb-2 ${classes.accordionRoot}`} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />} aria-label="Expand">
        <AccordionHeader
          title="WORKLOAD AND PREFERENCES"
          widget={widget}
          handleChange={handleChange}
          dragIndicator={dragIndicator}
          checkbox={checkbox}
        />
      </AccordionSummary>
      <AccordionDetails>
        <WorkloadAndPreferencesSection
          hasCurrentClients={freelancer.hasCurrentClients}
          monthlyHoursAvailable={
            freelancer.legacyMetadata?.monthlyHoursAvailable
          }
          paroIncomeStatus={freelancer.paroIncomeStatus}
          workPreferences={freelancer.workPreference || {}}
        />
        {/*  {JSON.stringify(workPreference)} */}
      </AccordionDetails>
    </Accordion>
  );
}
