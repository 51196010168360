import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import FieldAndValue from "../legacy/_global/FieldAndValue";
import MissingItem from "../legacy/_global/MissingItem";
import {
  convertBooleanToString,
  convertEnumToDisplayFormat,
  currencyConverter,
  paroIncomeStatusEnumToDisplayMap,
} from "../legacy/shared/displayConversions";
import { areAllItemsNull } from "../legacy/shared/utils";
import { ProfileInformation } from "../legacy/styles/ProfileInformation";
import { SectionBody } from "../legacy/styles/SectionBody";
/* import { WorkloadState } from "./WorkloadAndPreferencesSection"; */

/* type SectionContents = {
  workload: WorkloadState
}
 */
const weeklyHoursToMonthlyMap = {
  UNDER_10_HOURS: 40,
  "11_TO_20_HOURS": 60,
  "21_TO_30_HOURS": 100,
  "31_TO_40_HOURS": 140,
  OVER_40_HOURS: 160,
};

const convertToMonthlyHours = (
  weeklyWorkloadHours /* : WorkPreference['weeklyWorkloadHours'] */,
  monthlyHoursAvailable /* ?: ExpertLegacyMetadata['monthlyHoursAvailable'] */
) /* : string | number */ => {
  let monthlyHoursAvailableConverted = monthlyHoursAvailable + " hrs.";

  if (!monthlyHoursAvailable) {
    // if an expert has yet to set their monthly hours, estimate based on their registration answer to weekly hours
    monthlyHoursAvailableConverted = weeklyWorkloadHours
      ? weeklyHoursToMonthlyMap[weeklyWorkloadHours] + " hrs."
      : "Missing data";
  }
  return monthlyHoursAvailableConverted;
};
const Workload = ({ workload } /* : SectionContents */) /* : JSX.Element */ => {
  const {
    workPreferences: {
      weeklyWorkloadHours,
      intendedMonthlyIncome,
      totalFreelanceExperience,
    },
    monthlyHoursAvailable,
    paroIncomeStatus,
    hasCurrentClients,
  } = workload;

  const allItemsAreNull = areAllItemsNull([
    monthlyHoursAvailable,
    hasCurrentClients,
    weeklyWorkloadHours,
    intendedMonthlyIncome,
    totalFreelanceExperience,
    paroIncomeStatus,
  ]);

  return allItemsAreNull ? (
    <MissingItem title="workload" />
  ) : (
    <SectionBody>
      <Box pb={2}>
        <Typography variant="h6">Workload</Typography>
      </Box>
      <Grid container item justify="space-between" xs={12}>
        <FieldAndValue>
          <ProfileInformation variant="body2">
            Goal workload per month
          </ProfileInformation>
          <ProfileInformation rr>
            {convertToMonthlyHours(weeklyWorkloadHours, monthlyHoursAvailable)}
          </ProfileInformation>
        </FieldAndValue>
        <FieldAndValue>
          <ProfileInformation variant="body2">
            Monthly income goal
          </ProfileInformation>
          <ProfileInformation rr>
            {intendedMonthlyIncome
              ? currencyConverter(intendedMonthlyIncome)
              : "Missing data"}
          </ProfileInformation>
        </FieldAndValue>
        <FieldAndValue>
          <ProfileInformation variant="body2">
            Freelance situation
          </ProfileInformation>
          <ProfileInformation rr>
            {paroIncomeStatus
              ? paroIncomeStatusEnumToDisplayMap[paroIncomeStatus]
              : "Missing data"}
          </ProfileInformation>
        </FieldAndValue>
        <FieldAndValue>
          <ProfileInformation variant="body2">
            How long have you freelanced?
          </ProfileInformation>
          <ProfileInformation rr>
            {totalFreelanceExperience
              ? convertEnumToDisplayFormat(totalFreelanceExperience)
              : "Missing data"}
          </ProfileInformation>
        </FieldAndValue>
        <FieldAndValue>
          <ProfileInformation variant="body2">
            Existing book of business?
          </ProfileInformation>
          <ProfileInformation rr>
            {hasCurrentClients != null
              ? convertBooleanToString(hasCurrentClients)
              : "Missing data"}
          </ProfileInformation>
        </FieldAndValue>
      </Grid>
    </SectionBody>
  );
};

export default Workload;
