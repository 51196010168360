import React from "react";
import Slider from "react-slick";

export default function CustomSlider({ children, ...props }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
  };
  return <Slider {...settings}>{children}</Slider>;
}
