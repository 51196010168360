import React from "react";
import { Grid } from "@material-ui/core";

/* type SectionContents = {
  children: [React.ReactNode, React.ReactNode, React.ReactNode] | React.ReactNode[]
}
 */
const ThreeColumnLayout = (
  { children } /* : SectionContents */
) /* : JSX.Element */ => {
  return (
    <Grid
      alignItems="stretch"
      container
      direction="row"
      justify="center"
      spacing={5}
    >
      {children.map((child, childIndex) => {
        return (
          <Grid item xs={12} md={4} key={`${childIndex}-ThreeColumnLayout`}>
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
};
export default ThreeColumnLayout;
