import React from "react";
import { Box, Container, Fade, Grid } from "@material-ui/core";
import styled from "styled-components";

import { centerContentOnMobile } from "../styles/MediaQueries";

const ChildrenBox /* : typeof Box  */ = styled(Box)`
  position: relative;
  top: 0px;
`;
/* 
type SectionContents = {
  children: React.ReactNode
  isErrorOrLoading?: boolean
} */
export const FullWidthGrid /* : typeof Grid */ = styled(Grid)`
  width: 100%;
`;

const ProfileLayout = (
  { children, isErrorOrLoading } /* : SectionContents */
) /* : JSX.Element */ => {
  const mobileCenterStyles = centerContentOnMobile();
  return (
    <ChildrenBox className={mobileCenterStyles /*  as unknown as string */}>
      <Container maxWidth="xl">
        <Fade in={true} timeout={700}>
          <Box>
            <Grid container>
              {isErrorOrLoading ? (
                children
              ) : (
                <FullWidthGrid item>
                  <Box px={1}>
                    <Grid container direction="row">
                      <FullWidthGrid item>{children}</FullWidthGrid>
                    </Grid>
                  </Box>
                </FullWidthGrid>
              )}
            </Grid>
          </Box>
        </Fade>
      </Container>
    </ChildrenBox>
  );
};

export default ProfileLayout;
