import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import FeedbackFormComponent from "./FeedbackFormComponent";
import { reportFreelancer } from "../services/apiService";
import { useSnackbar } from "notistack";
import { CartContext } from "./CartContext";
import { REPORT_REASONS } from "../data/appConstants";
import { IconUserExclamation } from "@paro.io/base-icons";

export default function FreelancerReportForm({ freelancerId, proposalId }) {
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { matchContext } = useContext(CartContext);
  const [matchId] = matchContext;

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const onSubmitFn = (data, other = false) => {
    reportFreelancer({
      freelancerId,
      matchId,
      proposalId,
      reportReason:
        "" +
        (other
          ? [...data.predefinedReasons, data.otherReason]
          : data.predefinedReasons),
      comments: data.comments,
    })
      .then(() => {
        handleClose();
        enqueueSnackbar(`Freelancer ${freelancerId} has been reported!`, {
          variant: "warning",
        });
      })
      .catch(() =>
        enqueueSnackbar(`Unable to report Freelancer ${freelancerId}!`, {
          variant: "error",
        })
      );
  };

  return (
    <div className="pointer px-4 py-2 text-sm">
      {/* <Tooltip title="Report Freelancer with issues" aria-label="report"> */}
      <div
        onClick={handleShow}
        size="small"
        id="mm-matchFreelancer-report"
        className="flex"
      >
        <IconUserExclamation />{" "}
        <span className="mx-2">Report Freelancer with issues</span>
      </div>
      {/* </Tooltip> */}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Report Freelancer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FeedbackFormComponent
            onSubmitFn={onSubmitFn}
            question="What are the reasons to report this Freelancer?"
            options={REPORT_REASONS}
            newReasonPlaceholder="i.e. Lack of experience, etc."
            submitButtonText="REPORT"
            commentsHeading="Briefly explain your experience working with this freelancer"
            submittingText="Reporting Freelancer"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
