import React from "react";
import RBSpinner from "react-bootstrap/Spinner";

function CustomSpinner({ children }) {
  return (
    <div
      className="flex items-center flex-col py-3"
      style={{ height: "100%", minHeight: 150 }}
    >
      <RBSpinner
        className="justify-self-center m-auto text-info"
        animation="border"
      />
      {children && (
        <div className="font-small pt-2 text-secondary">{children}</div>
      )}
    </div>
  );
}

export default CustomSpinner;
